#mySidebar {
  width: 240px;
  z-index: 1040;
  position: fixed;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  /* padding-top: 2.2rem; */
  padding-top:.5rem;
  padding-bottom: 100px;
}

#mySidebar a {
  padding: 14px 10px 14px 30px;
  text-decoration: none;
  font-size: 14px;
  color: #1c1c1c;
  display: block;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

#mySidebar .submenu {
  padding: 10px 10px 10px 55px;
  text-decoration: none;
  font-size: 1rem;
  color: #1c1c1c;
  display: block;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

#mySidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#fill-sidebar {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1030;
}
