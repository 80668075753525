.white
{
    color: #fff !important;
}

.bg-white
{
    background-color: #fff !important;
}
.bg-white .card-header,
.bg-white .card-footer
{
    background-color: transparent;
}
.bg-white.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

.selectboxit.selectboxit-btn.bg-white
{
    background-color: #fff !important;
}

.alert-white
{
    color: #a6a6a6 !important; 
    border-color: #fff !important;
    background-color: white !important;
}
.alert-white .alert-link
{
    color: #919191 !important;
}

.border-white
{
    border-color: #fff;
}

.overlay-white
{
    background: #fff;
    /* The Fallback */
    background: rgba(255, 255, 255, .8);
}

/* .card-outline-white{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-white
{
    color: #fff; 
    border-color: !important;
    background-color: #fff !important;
}
.btn-white:hover
{
    color: #fff !important; 
    border-color: !important;
    background-color: !important;
}
.btn-white:focus,
.btn-white:active
{
    color: #fff !important; 
    border-color: !important;
    background-color: !important;
}
.btn-white.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

.btn-outline-white
{
    color: #fff; 
    border-color: #fff;
    background-color: transparent;
}
.btn-outline-white:hover
{
    color: #fff !important; 
    background-color: #fff;
}
.btn-outline-white.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

input[type='checkbox'].bg-white + .custom-control-label:before,
input[type='radio'].bg-white + .custom-control-label:before
{
    background-color: #fff !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-white
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff !important;
}

.border-white
{
    border: 1px solid #fff !important;
}

.border-top-white
{
    border-top: 1px solid #fff;
}

.border-bottom-white
{
    border-bottom: 1px solid #fff;
}

.border-left-white
{
    border-left: 1px solid #fff;
}

.border-right-white
{
    border-right: 1px solid #fff;
}

.black
{
    color: #000 !important;
}

.bg-black
{
    background-color: #000 !important;
}
.bg-black .card-header,
.bg-black .card-footer
{
    background-color: transparent;
}
.bg-black.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

.selectboxit.selectboxit-btn.bg-black
{
    background-color: #000 !important;
}

.alert-black
{
    color: black !important; 
    border-color: #000 !important;
    background-color: #242424 !important;
}
.alert-black .alert-link
{
    color: black !important;
}

.border-black
{
    border-color: #000;
}

.overlay-black
{
    background: #000;
    /* The Fallback */
    background: rgba(0, 0, 0, .8);
}

/* .card-outline-black{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-black
{
    color: #fff; 
    border-color: !important;
    background-color: #000 !important;
}
.btn-black:hover
{
    color: #fff !important; 
    border-color: !important;
    background-color: !important;
}
.btn-black:focus,
.btn-black:active
{
    color: #fff !important; 
    border-color: !important;
    background-color: !important;
}
.btn-black.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

.btn-outline-black
{
    color: #000; 
    border-color: #000;
    background-color: transparent;
}
.btn-outline-black:hover
{
    color: #fff !important; 
    background-color: #000;
}
.btn-outline-black.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

input[type='checkbox'].bg-black + .custom-control-label:before,
input[type='radio'].bg-black + .custom-control-label:before
{
    background-color: #000 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-black
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000 !important;
}

.border-black
{
    border: 1px solid #000 !important;
}

.border-top-black
{
    border-top: 1px solid #000;
}

.border-bottom-black
{
    border-bottom: 1px solid #000;
}

.border-left-black
{
    border-left: 1px solid #000;
}

.border-right-black
{
    border-right: 1px solid #000;
}

.primary.lighten-5
{
    color: #edeefc !important;
}

.bg-primary.bg-lighten-5
{
    background-color: #edeefc !important;
}
.bg-primary.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(237, 238, 252, .6);
            box-shadow: 0 1px 20px 1px rgba(237, 238, 252, .6);
}

.btn-primary.btn-lighten-5
{
    border-color: #0065fd !important;
    background-color: #edeefc !important;
}
.btn-primary.btn-lighten-5:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-lighten-5:focus,
.btn-primary.btn-lighten-5:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-lighten-5
{
    color: #edeefc !important; 
    border-color: #edeefc !important;
}
.btn-outline-primary.btn-outline-lighten-5:hover
{
    background-color: #edeefc !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #edeefc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #edeefc !important;
}

.border-primary.border-lighten-5
{
    border: 1px solid #edeefc !important;
}

.border-top-primary.border-top-lighten-5
{
    border-top: 1px solid #edeefc !important;
}

.border-bottom-primary.border-bottom-lighten-5
{
    border-bottom: 1px solid #edeefc !important;
}

.border-left-primary.border-left-lighten-5
{
    border-left: 1px solid #edeefc !important;
}

.border-right-primary.border-right-lighten-5
{
    border-right: 1px solid #edeefc !important;
}

.overlay-primary.overlay-lighten-5
{
    background: #edeefc;
    /* The Fallback */
    background: rgba(237, 238, 252, .8);
}

.primary.lighten-4
{
    color: #d1d4f8 !important;
}

.bg-primary.bg-lighten-4
{
    background-color: #d1d4f8 !important;
}
.bg-primary.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(209, 212, 248, .6);
            box-shadow: 0 1px 20px 1px rgba(209, 212, 248, .6);
}

.btn-primary.btn-lighten-4
{
    border-color: #0065fd !important;
    background-color: #d1d4f8 !important;
}
.btn-primary.btn-lighten-4:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-lighten-4:focus,
.btn-primary.btn-lighten-4:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-lighten-4
{
    color: #d1d4f8 !important; 
    border-color: #d1d4f8 !important;
}
.btn-outline-primary.btn-outline-lighten-4:hover
{
    background-color: #d1d4f8 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d1d4f8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d1d4f8 !important;
}

.border-primary.border-lighten-4
{
    border: 1px solid #d1d4f8 !important;
}

.border-top-primary.border-top-lighten-4
{
    border-top: 1px solid #d1d4f8 !important;
}

.border-bottom-primary.border-bottom-lighten-4
{
    border-bottom: 1px solid #d1d4f8 !important;
}

.border-left-primary.border-left-lighten-4
{
    border-left: 1px solid #d1d4f8 !important;
}

.border-right-primary.border-right-lighten-4
{
    border-right: 1px solid #d1d4f8 !important;
}

.overlay-primary.overlay-lighten-4
{
    background: #d1d4f8;
    /* The Fallback */
    background: rgba(209, 212, 248, .8);
}

.primary.lighten-3
{
    color: #b3b7f4 !important;
}

.bg-primary.bg-lighten-3
{
    background-color: #b3b7f4 !important;
}
.bg-primary.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(179, 183, 244, .6);
            box-shadow: 0 1px 20px 1px rgba(179, 183, 244, .6);
}

.btn-primary.btn-lighten-3
{
    border-color: #0065fd !important;
    background-color: #b3b7f4 !important;
}
.btn-primary.btn-lighten-3:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-lighten-3:focus,
.btn-primary.btn-lighten-3:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-lighten-3
{
    color: #b3b7f4 !important; 
    border-color: #b3b7f4 !important;
}
.btn-outline-primary.btn-outline-lighten-3:hover
{
    background-color: #b3b7f4 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b3b7f4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b3b7f4 !important;
}

.border-primary.border-lighten-3
{
    border: 1px solid #b3b7f4 !important;
}

.border-top-primary.border-top-lighten-3
{
    border-top: 1px solid #b3b7f4 !important;
}

.border-bottom-primary.border-bottom-lighten-3
{
    border-bottom: 1px solid #b3b7f4 !important;
}

.border-left-primary.border-left-lighten-3
{
    border-left: 1px solid #b3b7f4 !important;
}

.border-right-primary.border-right-lighten-3
{
    border-right: 1px solid #b3b7f4 !important;
}

.overlay-primary.overlay-lighten-3
{
    background: #b3b7f4;
    /* The Fallback */
    background: rgba(179, 183, 244, .8);
}

.primary.lighten-2
{
    color: #949aef !important;
}

.bg-primary.bg-lighten-2
{
    background-color: #949aef !important;
}
.bg-primary.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(148, 154, 239, .6);
            box-shadow: 0 1px 20px 1px rgba(148, 154, 239, .6);
}

.btn-primary.btn-lighten-2
{
    border-color: #0065fd !important;
    background-color: #949aef !important;
}
.btn-primary.btn-lighten-2:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-lighten-2:focus,
.btn-primary.btn-lighten-2:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-lighten-2
{
    color: #949aef !important; 
    border-color: #949aef !important;
}
.btn-outline-primary.btn-outline-lighten-2:hover
{
    background-color: #949aef !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #949aef !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #949aef !important;
}

.border-primary.border-lighten-2
{
    border: 1px solid #949aef !important;
}

.border-top-primary.border-top-lighten-2
{
    border-top: 1px solid #949aef !important;
}

.border-bottom-primary.border-bottom-lighten-2
{
    border-bottom: 1px solid #949aef !important;
}

.border-left-primary.border-left-lighten-2
{
    border-left: 1px solid #949aef !important;
}

.border-right-primary.border-right-lighten-2
{
    border-right: 1px solid #949aef !important;
}

.overlay-primary.overlay-lighten-2
{
    background: #949aef;
    /* The Fallback */
    background: rgba(148, 154, 239, .8);
}

.primary.lighten-1
{
    color: #7d84eb !important;
}

.bg-primary.bg-lighten-1
{
    background-color: #7d84eb !important;
}
.bg-primary.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(125, 132, 235, .6);
            box-shadow: 0 1px 20px 1px rgba(125, 132, 235, .6);
}

.btn-primary.btn-lighten-1
{
    border-color: #0065fd !important;
    background-color: #7d84eb !important;
}
.btn-primary.btn-lighten-1:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-lighten-1:focus,
.btn-primary.btn-lighten-1:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-lighten-1
{
    color: #7d84eb !important; 
    border-color: #7d84eb !important;
}
.btn-outline-primary.btn-outline-lighten-1:hover
{
    background-color: #7d84eb !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7d84eb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7d84eb !important;
}

.border-primary.border-lighten-1
{
    border: 1px solid #7d84eb !important;
}

.border-top-primary.border-top-lighten-1
{
    border-top: 1px solid #7d84eb !important;
}

.border-bottom-primary.border-bottom-lighten-1
{
    border-bottom: 1px solid #7d84eb !important;
}

.border-left-primary.border-left-lighten-1
{
    border-left: 1px solid #7d84eb !important;
}

.border-right-primary.border-right-lighten-1
{
    border-right: 1px solid #7d84eb !important;
}

.overlay-primary.overlay-lighten-1
{
    background: #7d84eb;
    /* The Fallback */
    background: rgba(125, 132, 235, .8);
}

.primary
{
    color: #666ee8 !important;
}

.bg-primary
{
    background-color: #666ee8 !important;
}
.bg-primary .card-header,
.bg-primary .card-footer
{
    background-color: transparent;
}
.bg-primary.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(102, 110, 232, .6);
            box-shadow: 0 1px 20px 1px rgba(102, 110, 232, .6);
}

.selectboxit.selectboxit-btn.bg-primary
{
    background-color: #666ee8 !important;
}

.alert-primary
{
    color: #141b87 !important; 
    border-color: #666ee8 !important;
    background-color: #a4a9f1 !important;
}
.alert-primary .alert-link
{
    color: #0f1464 !important;
}

.border-primary
{
    border-color: #666ee8;
}

.overlay-primary
{
    background: #666ee8;
    /* The Fallback */
    background: rgba(102, 110, 232, .8);
}

/* .card-outline-primary{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-primary
{
    color: #fff; 
    border-color: #0065fd !important;
    background-color: #666ee8 !important;
}
.btn-primary:hover
{
    color: #fff !important; 
    border-color: #5e66e5 !important;
    background-color: #7d84eb !important;
}
.btn-primary:focus,
.btn-primary:active
{
    color: #fff !important; 
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(102, 110, 232, .6);
            box-shadow: 0 1px 20px 1px rgba(102, 110, 232, .6);
}

.btn-outline-primary
{
    color: #666ee8; 
    border-color: #666ee8;
    background-color: transparent;
}
.btn-outline-primary:hover
{
    color: #fff !important; 
    background-color: #666ee8;
}
.btn-outline-primary.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(102, 110, 232, .6);
            box-shadow: 0 1px 20px 1px rgba(102, 110, 232, .6);
}

input[type='checkbox'].bg-primary + .custom-control-label:before,
input[type='radio'].bg-primary + .custom-control-label:before
{
    background-color: #666ee8 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #666ee8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #666ee8 !important;
}

.border-primary
{
    border: 1px solid #666ee8 !important;
}

.border-top-primary
{
    border-top: 1px solid #666ee8;
}

.border-bottom-primary
{
    border-bottom: 1px solid #666ee8;
}

.border-left-primary
{
    border-left: 1px solid #666ee8;
}

.border-right-primary
{
    border-right: 1px solid #666ee8;
}

.primary.darken-1
{
    color: #5e66e5 !important;
}

.bg-primary.bg-darken-1
{
    background-color: #5e66e5 !important;
}
.bg-primary.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(94, 102, 229, .6);
            box-shadow: 0 1px 20px 1px rgba(94, 102, 229, .6);
}

.btn-primary.btn-darken-1
{
    border-color: #0065fd !important;
    background-color: #5e66e5 !important;
}
.btn-primary.btn-darken-1:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-darken-1:focus,
.btn-primary.btn-darken-1:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-darken-1
{
    color: #5e66e5 !important; 
    border-color: #5e66e5 !important;
}
.btn-outline-primary.btn-outline-darken-1:hover
{
    background-color: #5e66e5 !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5e66e5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #5e66e5 !important;
}

.border-primary.border-darken-1
{
    border: 1px solid #5e66e5 !important;
}

.border-top-primary.border-top-darken-1
{
    border-top: 1px solid #5e66e5 !important;
}

.border-bottom-primary.border-bottom-darken-1
{
    border-bottom: 1px solid #5e66e5 !important;
}

.border-left-primary.border-left-darken-1
{
    border-left: 1px solid #5e66e5 !important;
}

.border-right-primary.border-right-darken-1
{
    border-right: 1px solid #5e66e5 !important;
}

.overlay-primary.overlay-darken-1
{
    background: #5e66e5;
    /* The Fallback */
    background: rgba(94, 102, 229, .8);
}

.primary.darken-2
{
    color: #0065fd !important;
}

.bg-primary.bg-darken-2
{
    background-color: #0065fd !important;
}
.bg-primary.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(83, 91, 226, .6);
            box-shadow: 0 1px 20px 1px rgba(83, 91, 226, .6);
}

.btn-primary.btn-darken-2
{
    border-color: #0065fd !important;
    background-color: #0065fd !important;
}
.btn-primary.btn-darken-2:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-darken-2:focus,
.btn-primary.btn-darken-2:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-darken-2
{
    color: #0065fd !important; 
    border-color: #0065fd !important;
}
.btn-outline-primary.btn-outline-darken-2:hover
{
    background-color: #0065fd !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0065fd !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0065fd !important;
}

.border-primary.border-darken-2
{
    border: 1px solid #0065fd !important;
}

.border-top-primary.border-top-darken-2
{
    border-top: 1px solid #0065fd !important;
}

.border-bottom-primary.border-bottom-darken-2
{
    border-bottom: 1px solid #0065fd !important;
}

.border-left-primary.border-left-darken-2
{
    border-left: 1px solid #0065fd !important;
}

.border-right-primary.border-right-darken-2
{
    border-right: 1px solid #0065fd !important;
}

.overlay-primary.overlay-darken-2
{
    background: #0065fd;
    /* The Fallback */
    background: rgba(83, 91, 226, .8);
}

.primary.darken-3
{
    color: #4951de !important;
}

.bg-primary.bg-darken-3
{
    background-color: #4951de !important;
}
.bg-primary.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(73, 81, 222, .6);
            box-shadow: 0 1px 20px 1px rgba(73, 81, 222, .6);
}

.btn-primary.btn-darken-3
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-darken-3:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-darken-3:focus,
.btn-primary.btn-darken-3:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-darken-3
{
    color: #4951de !important; 
    border-color: #4951de !important;
}
.btn-outline-primary.btn-outline-darken-3:hover
{
    background-color: #4951de !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4951de !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4951de !important;
}

.border-primary.border-darken-3
{
    border: 1px solid #4951de !important;
}

.border-top-primary.border-top-darken-3
{
    border-top: 1px solid #4951de !important;
}

.border-bottom-primary.border-bottom-darken-3
{
    border-bottom: 1px solid #4951de !important;
}

.border-left-primary.border-left-darken-3
{
    border-left: 1px solid #4951de !important;
}

.border-right-primary.border-right-darken-3
{
    border-right: 1px solid #4951de !important;
}

.overlay-primary.overlay-darken-3
{
    background: #4951de;
    /* The Fallback */
    background: rgba(73, 81, 222, .8);
}

.primary.darken-4
{
    color: #257cff !important;
}

.bg-primary.bg-darken-4
{
    background-color: #257cff !important;
}
.bg-primary.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(56, 63, 216, .6);
            box-shadow: 0 1px 20px 1px rgba(56, 63, 216, .6);
}

.btn-primary.btn-darken-4
{
    border-color: #0065fd !important;
    background-color: #257cff !important;
}
.btn-primary.btn-darken-4:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-darken-4:focus,
.btn-primary.btn-darken-4:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-darken-4
{
    color: #257cff !important; 
    border-color: #257cff !important;
}
.btn-outline-primary.btn-outline-darken-4:hover
{
    background-color: #257cff !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #257cff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #257cff !important;
}

.border-primary.border-darken-4
{
    border: 1px solid #257cff !important;
}

.border-top-primary.border-top-darken-4
{
    border-top: 1px solid #257cff !important;
}

.border-bottom-primary.border-bottom-darken-4
{
    border-bottom: 1px solid #257cff !important;
}

.border-left-primary.border-left-darken-4
{
    border-left: 1px solid #257cff !important;
}

.border-right-primary.border-right-darken-4
{
    border-right: 1px solid #257cff !important;
}

.overlay-primary.overlay-darken-4
{
    background: #257cff;
    /* The Fallback */
    background: rgba(56, 63, 216, .8);
}

.primary.accent-1
{
    color: #efefff !important;
}

.bg-primary.bg-accent-1
{
    background-color: #efefff !important;
}
.bg-primary.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(239, 239, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(239, 239, 255, .6);
}

.btn-primary.btn-accent-1
{
    border-color: #0065fd !important;
    background-color: #efefff !important;
}
.btn-primary.btn-accent-1:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-accent-1:focus,
.btn-primary.btn-accent-1:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-accent-1
{
    color: #efefff !important; 
    border-color: #efefff !important;
}
.btn-outline-primary.btn-outline-accent-1:hover
{
    background-color: #efefff !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #efefff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #efefff !important;
}

.border-primary.border-accent-1
{
    border: 1px solid #efefff !important;
}

.border-top-primary.border-top-accent-1
{
    border-top: 1px solid #efefff !important;
}

.border-bottom-primary.border-bottom-accent-1
{
    border-bottom: 1px solid #efefff !important;
}

.border-left-primary.border-left-accent-1
{
    border-left: 1px solid #efefff !important;
}

.border-right-primary.border-right-accent-1
{
    border-right: 1px solid #efefff !important;
}

.overlay-primary.overlay-accent-1
{
    background: #efefff;
    /* The Fallback */
    background: rgba(239, 239, 255, .8);
}

.primary.accent-2
{
    color: #dbdbff !important;
}

.bg-primary.bg-accent-2
{
    background-color: #dbdbff !important;
}
.bg-primary.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(219, 219, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(219, 219, 255, .6);
}

.btn-primary.btn-accent-2
{
    border-color: #0065fd !important;
    background-color: #dbdbff !important;
}
.btn-primary.btn-accent-2:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-accent-2:focus,
.btn-primary.btn-accent-2:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-accent-2
{
    color: #dbdbff !important; 
    border-color: #dbdbff !important;
}
.btn-outline-primary.btn-outline-accent-2:hover
{
    background-color: #dbdbff !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dbdbff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dbdbff !important;
}

.border-primary.border-accent-2
{
    border: 1px solid #dbdbff !important;
}

.border-top-primary.border-top-accent-2
{
    border-top: 1px solid #dbdbff !important;
}

.border-bottom-primary.border-bottom-accent-2
{
    border-bottom: 1px solid #dbdbff !important;
}

.border-left-primary.border-left-accent-2
{
    border-left: 1px solid #dbdbff !important;
}

.border-right-primary.border-right-accent-2
{
    border-right: 1px solid #dbdbff !important;
}

.overlay-primary.overlay-accent-2
{
    background: #dbdbff;
    /* The Fallback */
    background: rgba(219, 219, 255, .8);
}

.primary.accent-3
{
    color: #bcbfff !important;
}

.bg-primary.bg-accent-3
{
    background-color: #bcbfff !important;
}
.bg-primary.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(188, 191, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(188, 191, 255, .6);
}

.btn-primary.btn-accent-3
{
    border-color: #0065fd !important;
    background-color: #bcbfff !important;
}
.btn-primary.btn-accent-3:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-accent-3:focus,
.btn-primary.btn-accent-3:active
{
    border-color: #4951de !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-accent-3
{
    color: #bcbfff !important; 
    border-color: #bcbfff !important;
}
.btn-outline-primary.btn-outline-accent-3:hover
{
    background-color: #bcbfff !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bcbfff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bcbfff !important;
}

.border-primary.border-accent-3
{
    border: 1px solid #bcbfff !important;
}

.border-top-primary.border-top-accent-3
{
    border-top: 1px solid #bcbfff !important;
}

.border-bottom-primary.border-bottom-accent-3
{
    border-bottom: 1px solid #bcbfff !important;
}

.border-left-primary.border-left-accent-3
{
    border-left: 1px solid #bcbfff !important;
}

.border-right-primary.border-right-accent-3
{
    border-right: 1px solid #bcbfff !important;
}

.overlay-primary.overlay-accent-3
{
    background: #bcbfff;
    /* The Fallback */
    background: rgba(188, 191, 255, .8);
}

.primary.accent-4
{
    color: #a2a6ff !important;
}

.bg-primary.bg-accent-4
{
    background-color: #a2a6ff !important;
}
.bg-primary.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(162, 166, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(162, 166, 255, .6);
}

.btn-primary.btn-accent-4
{
    border-color: #0065fd !important;
    background-color: #a2a6ff !important;
}
.btn-primary.btn-accent-4:hover
{
    border-color: #0065fd !important;
    background-color: #4951de !important;
}
.btn-primary.btn-accent-4:focus,
.btn-primary.btn-accent-4:active
{
    border-color: #0065fd !important;
    background-color: #257cff !important;
}

.btn-outline-primary.btn-outline-accent-4
{
    color: #a2a6ff !important; 
    border-color: #a2a6ff !important;
}
.btn-outline-primary.btn-outline-accent-4:hover
{
    background-color: #a2a6ff !important;
}

input:focus ~ .bg-primary
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a2a6ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a2a6ff !important;
}

.border-primary.border-accent-4
{
    border: 1px solid #a2a6ff !important;
}

.border-top-primary.border-top-accent-4
{
    border-top: 1px solid #a2a6ff !important;
}

.border-bottom-primary.border-bottom-accent-4
{
    border-bottom: 1px solid #a2a6ff !important;
}

.border-left-primary.border-left-accent-4
{
    border-left: 1px solid #a2a6ff !important;
}

.border-right-primary.border-right-accent-4
{
    border-right: 1px solid #a2a6ff !important;
}

.overlay-primary.overlay-accent-4
{
    background: #a2a6ff;
    /* The Fallback */
    background: rgba(162, 166, 255, .8);
}

.success.lighten-5
{
    color: #e5f9f2 !important;
}

.bg-success.bg-lighten-5
{
    background-color: #e5f9f2 !important;
}
.bg-success.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(229, 249, 242, .6);
            box-shadow: 0 1px 20px 1px rgba(229, 249, 242, .6);
}

.btn-success.btn-lighten-5
{
    border-color: #1ec481 !important;
    background-color: #e5f9f2 !important;
}
.btn-success.btn-lighten-5:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-lighten-5:focus,
.btn-success.btn-lighten-5:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-lighten-5
{
    color: #e5f9f2 !important; 
    border-color: #e5f9f2 !important;
}
.btn-outline-success.btn-outline-lighten-5:hover
{
    background-color: #e5f9f2 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e5f9f2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e5f9f2 !important;
}

.border-success.border-lighten-5
{
    border: 1px solid #e5f9f2 !important;
}

.border-top-success.border-top-lighten-5
{
    border-top: 1px solid #e5f9f2 !important;
}

.border-bottom-success.border-bottom-lighten-5
{
    border-bottom: 1px solid #e5f9f2 !important;
}

.border-left-success.border-left-lighten-5
{
    border-left: 1px solid #e5f9f2 !important;
}

.border-right-success.border-right-lighten-5
{
    border-right: 1px solid #e5f9f2 !important;
}

.overlay-success.overlay-lighten-5
{
    background: #e5f9f2;
    /* The Fallback */
    background: rgba(229, 249, 242, .8);
}

.success.lighten-4
{
    color: #bff1df !important;
}

.bg-success.bg-lighten-4
{
    background-color: #bff1df !important;
}
.bg-success.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(191, 241, 223, .6);
            box-shadow: 0 1px 20px 1px rgba(191, 241, 223, .6);
}

.btn-success.btn-lighten-4
{
    border-color: #1ec481 !important;
    background-color: #bff1df !important;
}
.btn-success.btn-lighten-4:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-lighten-4:focus,
.btn-success.btn-lighten-4:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-lighten-4
{
    color: #bff1df !important; 
    border-color: #bff1df !important;
}
.btn-outline-success.btn-outline-lighten-4:hover
{
    background-color: #bff1df !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bff1df !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bff1df !important;
}

.border-success.border-lighten-4
{
    border: 1px solid #bff1df !important;
}

.border-top-success.border-top-lighten-4
{
    border-top: 1px solid #bff1df !important;
}

.border-bottom-success.border-bottom-lighten-4
{
    border-bottom: 1px solid #bff1df !important;
}

.border-left-success.border-left-lighten-4
{
    border-left: 1px solid #bff1df !important;
}

.border-right-success.border-right-lighten-4
{
    border-right: 1px solid #bff1df !important;
}

.overlay-success.overlay-lighten-4
{
    background: #bff1df;
    /* The Fallback */
    background: rgba(191, 241, 223, .8);
}

.success.lighten-3
{
    color: #94e8ca !important;
}

.bg-success.bg-lighten-3
{
    background-color: #94e8ca !important;
}
.bg-success.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(148, 232, 202, .6);
            box-shadow: 0 1px 20px 1px rgba(148, 232, 202, .6);
}

.btn-success.btn-lighten-3
{
    border-color: #1ec481 !important;
    background-color: #94e8ca !important;
}
.btn-success.btn-lighten-3:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-lighten-3:focus,
.btn-success.btn-lighten-3:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-lighten-3
{
    color: #94e8ca !important; 
    border-color: #94e8ca !important;
}
.btn-outline-success.btn-outline-lighten-3:hover
{
    background-color: #94e8ca !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #94e8ca !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #94e8ca !important;
}

.border-success.border-lighten-3
{
    border: 1px solid #94e8ca !important;
}

.border-top-success.border-top-lighten-3
{
    border-top: 1px solid #94e8ca !important;
}

.border-bottom-success.border-bottom-lighten-3
{
    border-bottom: 1px solid #94e8ca !important;
}

.border-left-success.border-left-lighten-3
{
    border-left: 1px solid #94e8ca !important;
}

.border-right-success.border-right-lighten-3
{
    border-right: 1px solid #94e8ca !important;
}

.overlay-success.overlay-lighten-3
{
    background: #94e8ca;
    /* The Fallback */
    background: rgba(148, 232, 202, .8);
}

.success.lighten-2
{
    color: #69deb4 !important;
}

.bg-success.bg-lighten-2
{
    background-color: #69deb4 !important;
}
.bg-success.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(105, 222, 180, .6);
            box-shadow: 0 1px 20px 1px rgba(105, 222, 180, .6);
}

.btn-success.btn-lighten-2
{
    border-color: #1ec481 !important;
    background-color: #69deb4 !important;
}
.btn-success.btn-lighten-2:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-lighten-2:focus,
.btn-success.btn-lighten-2:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-lighten-2
{
    color: #69deb4 !important; 
    border-color: #69deb4 !important;
}
.btn-outline-success.btn-outline-lighten-2:hover
{
    background-color: #69deb4 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #69deb4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #69deb4 !important;
}

.border-success.border-lighten-2
{
    border: 1px solid #69deb4 !important;
}

.border-top-success.border-top-lighten-2
{
    border-top: 1px solid #69deb4 !important;
}

.border-bottom-success.border-bottom-lighten-2
{
    border-bottom: 1px solid #69deb4 !important;
}

.border-left-success.border-left-lighten-2
{
    border-left: 1px solid #69deb4 !important;
}

.border-right-success.border-right-lighten-2
{
    border-right: 1px solid #69deb4 !important;
}

.overlay-success.overlay-lighten-2
{
    background: #69deb4;
    /* The Fallback */
    background: rgba(105, 222, 180, .8);
}

.success.lighten-1
{
    color: #48d7a4 !important;
}

.bg-success.bg-lighten-1
{
    background-color: #48d7a4 !important;
}
.bg-success.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(72, 215, 164, .6);
            box-shadow: 0 1px 20px 1px rgba(72, 215, 164, .6);
}

.btn-success.btn-lighten-1
{
    border-color: #1ec481 !important;
    background-color: #48d7a4 !important;
}
.btn-success.btn-lighten-1:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-lighten-1:focus,
.btn-success.btn-lighten-1:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-lighten-1
{
    color: #48d7a4 !important; 
    border-color: #48d7a4 !important;
}
.btn-outline-success.btn-outline-lighten-1:hover
{
    background-color: #48d7a4 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #48d7a4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #48d7a4 !important;
}

.border-success.border-lighten-1
{
    border: 1px solid #48d7a4 !important;
}

.border-top-success.border-top-lighten-1
{
    border-top: 1px solid #48d7a4 !important;
}

.border-bottom-success.border-bottom-lighten-1
{
    border-bottom: 1px solid #48d7a4 !important;
}

.border-left-success.border-left-lighten-1
{
    border-left: 1px solid #48d7a4 !important;
}

.border-right-success.border-right-lighten-1
{
    border-right: 1px solid #48d7a4 !important;
}

.overlay-success.overlay-lighten-1
{
    background: #48d7a4;
    /* The Fallback */
    background: rgba(72, 215, 164, .8);
}

.success
{
    color: #28d094 !important;
}

.bg-success
{
    background-color: #28d094 !important;
}
.bg-success .card-header,
.bg-success .card-footer
{
    background-color: transparent;
}
.bg-success.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(40, 208, 148, .6);
            box-shadow: 0 1px 20px 1px rgba(40, 208, 148, .6);
}

.selectboxit.selectboxit-btn.bg-success
{
    background-color: #28d094 !important;
}

.alert-success
{
    color: #0b3a29 !important; 
    border-color: #28d094 !important;
    background-color: #5fe0b2 !important;
}
.alert-success .alert-link
{
    color: #051811 !important;
}

.border-success
{
    border-color: #28d094;
}

.overlay-success
{
    background: #28d094;
    /* The Fallback */
    background: rgba(40, 208, 148, .8);
}

/* .card-outline-success{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-success
{
    color: #fff; 
    border-color: #1ec481 !important;
    background-color: #28d094 !important;
}
.btn-success:hover
{
    color: #fff !important; 
    border-color: #24cb8c !important;
    background-color: #48d7a4 !important;
}
.btn-success:focus,
.btn-success:active
{
    color: #fff !important; 
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(40, 208, 148, .6);
            box-shadow: 0 1px 20px 1px rgba(40, 208, 148, .6);
}

.btn-outline-success
{
    color: #28d094; 
    border-color: #28d094;
    background-color: transparent;
}
.btn-outline-success:hover
{
    color: #fff !important; 
    background-color: #28d094;
}
.btn-outline-success.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(40, 208, 148, .6);
            box-shadow: 0 1px 20px 1px rgba(40, 208, 148, .6);
}

input[type='checkbox'].bg-success + .custom-control-label:before,
input[type='radio'].bg-success + .custom-control-label:before
{
    background-color: #28d094 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #28d094 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #28d094 !important;
}

.border-success
{
    border: 1px solid #28d094 !important;
}

.border-top-success
{
    border-top: 1px solid #28d094;
}

.border-bottom-success
{
    border-bottom: 1px solid #28d094;
}

.border-left-success
{
    border-left: 1px solid #28d094;
}

.border-right-success
{
    border-right: 1px solid #28d094;
}

.success.darken-1
{
    color: #24cb8c !important;
}

.bg-success.bg-darken-1
{
    background-color: #24cb8c !important;
}
.bg-success.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(36, 203, 140, .6);
            box-shadow: 0 1px 20px 1px rgba(36, 203, 140, .6);
}

.btn-success.btn-darken-1
{
    border-color: #1ec481 !important;
    background-color: #24cb8c !important;
}
.btn-success.btn-darken-1:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-darken-1:focus,
.btn-success.btn-darken-1:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-darken-1
{
    color: #24cb8c !important; 
    border-color: #24cb8c !important;
}
.btn-outline-success.btn-outline-darken-1:hover
{
    background-color: #24cb8c !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #24cb8c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #24cb8c !important;
}

.border-success.border-darken-1
{
    border: 1px solid #24cb8c !important;
}

.border-top-success.border-top-darken-1
{
    border-top: 1px solid #24cb8c !important;
}

.border-bottom-success.border-bottom-darken-1
{
    border-bottom: 1px solid #24cb8c !important;
}

.border-left-success.border-left-darken-1
{
    border-left: 1px solid #24cb8c !important;
}

.border-right-success.border-right-darken-1
{
    border-right: 1px solid #24cb8c !important;
}

.overlay-success.overlay-darken-1
{
    background: #24cb8c;
    /* The Fallback */
    background: rgba(36, 203, 140, .8);
}

.success.darken-2
{
    color: #1ec481 !important;
}

.bg-success.bg-darken-2
{
    background-color: #1ec481 !important;
}
.bg-success.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(30, 196, 129, .6);
            box-shadow: 0 1px 20px 1px rgba(30, 196, 129, .6);
}

.btn-success.btn-darken-2
{
    border-color: #1ec481 !important;
    background-color: #1ec481 !important;
}
.btn-success.btn-darken-2:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-darken-2:focus,
.btn-success.btn-darken-2:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-darken-2
{
    color: #1ec481 !important; 
    border-color: #1ec481 !important;
}
.btn-outline-success.btn-outline-darken-2:hover
{
    background-color: #1ec481 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1ec481 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1ec481 !important;
}

.border-success.border-darken-2
{
    border: 1px solid #1ec481 !important;
}

.border-top-success.border-top-darken-2
{
    border-top: 1px solid #1ec481 !important;
}

.border-bottom-success.border-bottom-darken-2
{
    border-bottom: 1px solid #1ec481 !important;
}

.border-left-success.border-left-darken-2
{
    border-left: 1px solid #1ec481 !important;
}

.border-right-success.border-right-darken-2
{
    border-right: 1px solid #1ec481 !important;
}

.overlay-success.overlay-darken-2
{
    background: #1ec481;
    /* The Fallback */
    background: rgba(30, 196, 129, .8);
}

.success.darken-3
{
    color: #18be77 !important;
}

.bg-success.bg-darken-3
{
    background-color: #18be77 !important;
}
.bg-success.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(24, 190, 119, .6);
            box-shadow: 0 1px 20px 1px rgba(24, 190, 119, .6);
}

.btn-success.btn-darken-3
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-darken-3:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-darken-3:focus,
.btn-success.btn-darken-3:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-darken-3
{
    color: #18be77 !important; 
    border-color: #18be77 !important;
}
.btn-outline-success.btn-outline-darken-3:hover
{
    background-color: #18be77 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #18be77 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #18be77 !important;
}

.border-success.border-darken-3
{
    border: 1px solid #18be77 !important;
}

.border-top-success.border-top-darken-3
{
    border-top: 1px solid #18be77 !important;
}

.border-bottom-success.border-bottom-darken-3
{
    border-bottom: 1px solid #18be77 !important;
}

.border-left-success.border-left-darken-3
{
    border-left: 1px solid #18be77 !important;
}

.border-right-success.border-right-darken-3
{
    border-right: 1px solid #18be77 !important;
}

.overlay-success.overlay-darken-3
{
    background: #18be77;
    /* The Fallback */
    background: rgba(24, 190, 119, .8);
}

.success.darken-4
{
    color: #0fb365 !important;
}

.bg-success.bg-darken-4
{
    background-color: #0fb365 !important;
}
.bg-success.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(15, 179, 101, .6);
            box-shadow: 0 1px 20px 1px rgba(15, 179, 101, .6);
}

.btn-success.btn-darken-4
{
    border-color: #1ec481 !important;
    background-color: #0fb365 !important;
}
.btn-success.btn-darken-4:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-darken-4:focus,
.btn-success.btn-darken-4:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-darken-4
{
    color: #0fb365 !important; 
    border-color: #0fb365 !important;
}
.btn-outline-success.btn-outline-darken-4:hover
{
    background-color: #0fb365 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0fb365 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0fb365 !important;
}

.border-success.border-darken-4
{
    border: 1px solid #0fb365 !important;
}

.border-top-success.border-top-darken-4
{
    border-top: 1px solid #0fb365 !important;
}

.border-bottom-success.border-bottom-darken-4
{
    border-bottom: 1px solid #0fb365 !important;
}

.border-left-success.border-left-darken-4
{
    border-left: 1px solid #0fb365 !important;
}

.border-right-success.border-right-darken-4
{
    border-right: 1px solid #0fb365 !important;
}

.overlay-success.overlay-darken-4
{
    background: #0fb365;
    /* The Fallback */
    background: rgba(15, 179, 101, .8);
}

.success.accent-1
{
    color: #e1fff0 !important;
}

.bg-success.bg-accent-1
{
    background-color: #e1fff0 !important;
}
.bg-success.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(225, 255, 240, .6);
            box-shadow: 0 1px 20px 1px rgba(225, 255, 240, .6);
}

.btn-success.btn-accent-1
{
    border-color: #1ec481 !important;
    background-color: #e1fff0 !important;
}
.btn-success.btn-accent-1:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-accent-1:focus,
.btn-success.btn-accent-1:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-accent-1
{
    color: #e1fff0 !important; 
    border-color: #e1fff0 !important;
}
.btn-outline-success.btn-outline-accent-1:hover
{
    background-color: #e1fff0 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e1fff0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e1fff0 !important;
}

.border-success.border-accent-1
{
    border: 1px solid #e1fff0 !important;
}

.border-top-success.border-top-accent-1
{
    border-top: 1px solid #e1fff0 !important;
}

.border-bottom-success.border-bottom-accent-1
{
    border-bottom: 1px solid #e1fff0 !important;
}

.border-left-success.border-left-accent-1
{
    border-left: 1px solid #e1fff0 !important;
}

.border-right-success.border-right-accent-1
{
    border-right: 1px solid #e1fff0 !important;
}

.overlay-success.overlay-accent-1
{
    background: #e1fff0;
    /* The Fallback */
    background: rgba(225, 255, 240, .8);
}

.success.accent-2
{
    color: #aeffd5 !important;
}

.bg-success.bg-accent-2
{
    background-color: #aeffd5 !important;
}
.bg-success.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(174, 255, 213, .6);
            box-shadow: 0 1px 20px 1px rgba(174, 255, 213, .6);
}

.btn-success.btn-accent-2
{
    border-color: #1ec481 !important;
    background-color: #aeffd5 !important;
}
.btn-success.btn-accent-2:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-accent-2:focus,
.btn-success.btn-accent-2:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-accent-2
{
    color: #aeffd5 !important; 
    border-color: #aeffd5 !important;
}
.btn-outline-success.btn-outline-accent-2:hover
{
    background-color: #aeffd5 !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #aeffd5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #aeffd5 !important;
}

.border-success.border-accent-2
{
    border: 1px solid #aeffd5 !important;
}

.border-top-success.border-top-accent-2
{
    border-top: 1px solid #aeffd5 !important;
}

.border-bottom-success.border-bottom-accent-2
{
    border-bottom: 1px solid #aeffd5 !important;
}

.border-left-success.border-left-accent-2
{
    border-left: 1px solid #aeffd5 !important;
}

.border-right-success.border-right-accent-2
{
    border-right: 1px solid #aeffd5 !important;
}

.overlay-success.overlay-accent-2
{
    background: #aeffd5;
    /* The Fallback */
    background: rgba(174, 255, 213, .8);
}

.success.accent-3
{
    color: #7bffbb !important;
}

.bg-success.bg-accent-3
{
    background-color: #7bffbb !important;
}
.bg-success.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(123, 255, 187, .6);
            box-shadow: 0 1px 20px 1px rgba(123, 255, 187, .6);
}

.btn-success.btn-accent-3
{
    border-color: #1ec481 !important;
    background-color: #7bffbb !important;
}
.btn-success.btn-accent-3:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-accent-3:focus,
.btn-success.btn-accent-3:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-accent-3
{
    color: #7bffbb !important; 
    border-color: #7bffbb !important;
}
.btn-outline-success.btn-outline-accent-3:hover
{
    background-color: #7bffbb !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7bffbb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7bffbb !important;
}

.border-success.border-accent-3
{
    border: 1px solid #7bffbb !important;
}

.border-top-success.border-top-accent-3
{
    border-top: 1px solid #7bffbb !important;
}

.border-bottom-success.border-bottom-accent-3
{
    border-bottom: 1px solid #7bffbb !important;
}

.border-left-success.border-left-accent-3
{
    border-left: 1px solid #7bffbb !important;
}

.border-right-success.border-right-accent-3
{
    border-right: 1px solid #7bffbb !important;
}

.overlay-success.overlay-accent-3
{
    background: #7bffbb;
    /* The Fallback */
    background: rgba(123, 255, 187, .8);
}

.success.accent-4
{
    color: #62ffae !important;
}

.bg-success.bg-accent-4
{
    background-color: #62ffae !important;
}
.bg-success.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(98, 255, 174, .6);
            box-shadow: 0 1px 20px 1px rgba(98, 255, 174, .6);
}

.btn-success.btn-accent-4
{
    border-color: #1ec481 !important;
    background-color: #62ffae !important;
}
.btn-success.btn-accent-4:hover
{
    border-color: #1ec481 !important;
    background-color: #18be77 !important;
}
.btn-success.btn-accent-4:focus,
.btn-success.btn-accent-4:active
{
    border-color: #18be77 !important;
    background-color: #0fb365 !important;
}

.btn-outline-success.btn-outline-accent-4
{
    color: #62ffae !important; 
    border-color: #62ffae !important;
}
.btn-outline-success.btn-outline-accent-4:hover
{
    background-color: #62ffae !important;
}

input:focus ~ .bg-success
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #62ffae !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #62ffae !important;
}

.border-success.border-accent-4
{
    border: 1px solid #62ffae !important;
}

.border-top-success.border-top-accent-4
{
    border-top: 1px solid #62ffae !important;
}

.border-bottom-success.border-bottom-accent-4
{
    border-bottom: 1px solid #62ffae !important;
}

.border-left-success.border-left-accent-4
{
    border-left: 1px solid #62ffae !important;
}

.border-right-success.border-right-accent-4
{
    border-right: 1px solid #62ffae !important;
}

.overlay-success.overlay-accent-4
{
    background: #62ffae;
    /* The Fallback */
    background: rgba(98, 255, 174, .8);
}

.info.lighten-5
{
    color: #e4f3fd !important;
}

.bg-info.bg-lighten-5
{
    background-color: #e4f3fd !important;
}
.bg-info.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(228, 243, 253, .6);
            box-shadow: 0 1px 20px 1px rgba(228, 243, 253, .6);
}

.btn-info.btn-lighten-5
{
    border-color: #168dee !important;
    background-color: #e4f3fd !important;
}
.btn-info.btn-lighten-5:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-lighten-5:focus,
.btn-info.btn-lighten-5:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-lighten-5
{
    color: #e4f3fd !important; 
    border-color: #e4f3fd !important;
}
.btn-outline-info.btn-outline-lighten-5:hover
{
    background-color: #e4f3fd !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e4f3fd !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e4f3fd !important;
}

.border-info.border-lighten-5
{
    border: 1px solid #e4f3fd !important;
}

.border-top-info.border-top-lighten-5
{
    border-top: 1px solid #e4f3fd !important;
}

.border-bottom-info.border-bottom-lighten-5
{
    border-bottom: 1px solid #e4f3fd !important;
}

.border-left-info.border-left-lighten-5
{
    border-left: 1px solid #e4f3fd !important;
}

.border-right-info.border-right-lighten-5
{
    border-right: 1px solid #e4f3fd !important;
}

.overlay-info.overlay-lighten-5
{
    background: #e4f3fd;
    /* The Fallback */
    background: rgba(228, 243, 253, .8);
}

.info.lighten-4
{
    color: #bce2fb !important;
}

.bg-info.bg-lighten-4
{
    background-color: #bce2fb !important;
}
.bg-info.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(188, 226, 251, .6);
            box-shadow: 0 1px 20px 1px rgba(188, 226, 251, .6);
}

.btn-info.btn-lighten-4
{
    border-color: #168dee !important;
    background-color: #bce2fb !important;
}
.btn-info.btn-lighten-4:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-lighten-4:focus,
.btn-info.btn-lighten-4:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-lighten-4
{
    color: #bce2fb !important; 
    border-color: #bce2fb !important;
}
.btn-outline-info.btn-outline-lighten-4:hover
{
    background-color: #bce2fb !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bce2fb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bce2fb !important;
}

.border-info.border-lighten-4
{
    border: 1px solid #bce2fb !important;
}

.border-top-info.border-top-lighten-4
{
    border-top: 1px solid #bce2fb !important;
}

.border-bottom-info.border-bottom-lighten-4
{
    border-bottom: 1px solid #bce2fb !important;
}

.border-left-info.border-left-lighten-4
{
    border-left: 1px solid #bce2fb !important;
}

.border-right-info.border-right-lighten-4
{
    border-right: 1px solid #bce2fb !important;
}

.overlay-info.overlay-lighten-4
{
    background: #bce2fb;
    /* The Fallback */
    background: rgba(188, 226, 251, .8);
}

.info.lighten-3
{
    color: #8fcff9 !important;
}

.bg-info.bg-lighten-3
{
    background-color: #8fcff9 !important;
}
.bg-info.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(143, 207, 249, .6);
            box-shadow: 0 1px 20px 1px rgba(143, 207, 249, .6);
}

.btn-info.btn-lighten-3
{
    border-color: #168dee !important;
    background-color: #8fcff9 !important;
}
.btn-info.btn-lighten-3:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-lighten-3:focus,
.btn-info.btn-lighten-3:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-lighten-3
{
    color: #8fcff9 !important; 
    border-color: #8fcff9 !important;
}
.btn-outline-info.btn-outline-lighten-3:hover
{
    background-color: #8fcff9 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8fcff9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8fcff9 !important;
}

.border-info.border-lighten-3
{
    border: 1px solid #8fcff9 !important;
}

.border-top-info.border-top-lighten-3
{
    border-top: 1px solid #8fcff9 !important;
}

.border-bottom-info.border-bottom-lighten-3
{
    border-bottom: 1px solid #8fcff9 !important;
}

.border-left-info.border-left-lighten-3
{
    border-left: 1px solid #8fcff9 !important;
}

.border-right-info.border-right-lighten-3
{
    border-right: 1px solid #8fcff9 !important;
}

.overlay-info.overlay-lighten-3
{
    background: #8fcff9;
    /* The Fallback */
    background: rgba(143, 207, 249, .8);
}

.info.lighten-2
{
    color: #62bcf6 !important;
}

.bg-info.bg-lighten-2
{
    background-color: #62bcf6 !important;
}
.bg-info.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(98, 188, 246, .6);
            box-shadow: 0 1px 20px 1px rgba(98, 188, 246, .6);
}

.btn-info.btn-lighten-2
{
    border-color: #168dee !important;
    background-color: #62bcf6 !important;
}
.btn-info.btn-lighten-2:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-lighten-2:focus,
.btn-info.btn-lighten-2:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-lighten-2
{
    color: #62bcf6 !important; 
    border-color: #62bcf6 !important;
}
.btn-outline-info.btn-outline-lighten-2:hover
{
    background-color: #62bcf6 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #62bcf6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #62bcf6 !important;
}

.border-info.border-lighten-2
{
    border: 1px solid #62bcf6 !important;
}

.border-top-info.border-top-lighten-2
{
    border-top: 1px solid #62bcf6 !important;
}

.border-bottom-info.border-bottom-lighten-2
{
    border-bottom: 1px solid #62bcf6 !important;
}

.border-left-info.border-left-lighten-2
{
    border-left: 1px solid #62bcf6 !important;
}

.border-right-info.border-right-lighten-2
{
    border-right: 1px solid #62bcf6 !important;
}

.overlay-info.overlay-lighten-2
{
    background: #62bcf6;
    /* The Fallback */
    background: rgba(98, 188, 246, .8);
}

.info.lighten-1
{
    color: #40adf4 !important;
}

.bg-info.bg-lighten-1
{
    background-color: #40adf4 !important;
}
.bg-info.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(64, 173, 244, .6);
            box-shadow: 0 1px 20px 1px rgba(64, 173, 244, .6);
}

.btn-info.btn-lighten-1
{
    border-color: #168dee !important;
    background-color: #40adf4 !important;
}
.btn-info.btn-lighten-1:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-lighten-1:focus,
.btn-info.btn-lighten-1:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-lighten-1
{
    color: #40adf4 !important; 
    border-color: #40adf4 !important;
}
.btn-outline-info.btn-outline-lighten-1:hover
{
    background-color: #40adf4 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #40adf4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #40adf4 !important;
}

.border-info.border-lighten-1
{
    border: 1px solid #40adf4 !important;
}

.border-top-info.border-top-lighten-1
{
    border-top: 1px solid #40adf4 !important;
}

.border-bottom-info.border-bottom-lighten-1
{
    border-bottom: 1px solid #40adf4 !important;
}

.border-left-info.border-left-lighten-1
{
    border-left: 1px solid #40adf4 !important;
}

.border-right-info.border-right-lighten-1
{
    border-right: 1px solid #40adf4 !important;
}

.overlay-info.overlay-lighten-1
{
    background: #40adf4;
    /* The Fallback */
    background: rgba(64, 173, 244, .8);
}

.info
{
    color: #1e9ff2 !important;
}

.bg-info
{
    background-color: #1e9ff2 !important;
}
.bg-info .card-header,
.bg-info .card-footer
{
    background-color: transparent;
}
.bg-info.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(30, 159, 242, .6);
            box-shadow: 0 1px 20px 1px rgba(30, 159, 242, .6);
}

.selectboxit.selectboxit-btn.bg-info
{
    background-color: #1e9ff2 !important;
}

.alert-info
{
    color: #053858 !important; 
    border-color: #1e9ff2 !important;
    background-color: #d6efff !important;
}
.alert-info .alert-link
{
    color: #031f32 !important;
}

.border-info
{
    border-color: #1e9ff2;
}

.overlay-info
{
    background: #1e9ff2;
    /* The Fallback */
    background: rgba(30, 159, 242, .8);
}

/* .card-outline-info{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-info
{
    color: #fff; 
    border-color: #168dee !important;
    background-color: #1e9ff2 !important;
}
.btn-info:hover
{
    color: #fff !important; 
    border-color: #1a97f0 !important;
    background-color: #40adf4 !important;
}
.btn-info:focus,
.btn-info:active
{
    color: #fff !important; 
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(30, 159, 242, .6);
            box-shadow: 0 1px 20px 1px rgba(30, 159, 242, .6);
}

.btn-outline-info
{
    color: #1e9ff2; 
    border-color: #1e9ff2;
    background-color: transparent;
}
.btn-outline-info:hover
{
    color: #fff !important; 
    background-color: #1e9ff2;
}
.btn-outline-info.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(30, 159, 242, .6);
            box-shadow: 0 1px 20px 1px rgba(30, 159, 242, .6);
}

input[type='checkbox'].bg-info + .custom-control-label:before,
input[type='radio'].bg-info + .custom-control-label:before
{
    background-color: #1e9ff2 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1e9ff2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1e9ff2 !important;
}

.border-info
{
    border: 1px solid #1e9ff2 !important;
}

.border-top-info
{
    border-top: 1px solid #1e9ff2;
}

.border-bottom-info
{
    border-bottom: 1px solid #1e9ff2;
}

.border-left-info
{
    border-left: 1px solid #1e9ff2;
}

.border-right-info
{
    border-right: 1px solid #1e9ff2;
}

.info.darken-1
{
    color: #1a97f0 !important;
}

.bg-info.bg-darken-1
{
    background-color: #1a97f0 !important;
}
.bg-info.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(26, 151, 240, .6);
            box-shadow: 0 1px 20px 1px rgba(26, 151, 240, .6);
}

.btn-info.btn-darken-1
{
    border-color: #168dee !important;
    background-color: #1a97f0 !important;
}
.btn-info.btn-darken-1:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-darken-1:focus,
.btn-info.btn-darken-1:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-darken-1
{
    color: #1a97f0 !important; 
    border-color: #1a97f0 !important;
}
.btn-outline-info.btn-outline-darken-1:hover
{
    background-color: #1a97f0 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1a97f0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1a97f0 !important;
}

.border-info.border-darken-1
{
    border: 1px solid #1a97f0 !important;
}

.border-top-info.border-top-darken-1
{
    border-top: 1px solid #1a97f0 !important;
}

.border-bottom-info.border-bottom-darken-1
{
    border-bottom: 1px solid #1a97f0 !important;
}

.border-left-info.border-left-darken-1
{
    border-left: 1px solid #1a97f0 !important;
}

.border-right-info.border-right-darken-1
{
    border-right: 1px solid #1a97f0 !important;
}

.overlay-info.overlay-darken-1
{
    background: #1a97f0;
    /* The Fallback */
    background: rgba(26, 151, 240, .8);
}

.info.darken-2
{
    color: #168dee !important;
}

.bg-info.bg-darken-2
{
    background-color: #168dee !important;
}
.bg-info.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(22, 141, 238, .6);
            box-shadow: 0 1px 20px 1px rgba(22, 141, 238, .6);
}

.btn-info.btn-darken-2
{
    border-color: #168dee !important;
    background-color: #168dee !important;
}
.btn-info.btn-darken-2:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-darken-2:focus,
.btn-info.btn-darken-2:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-darken-2
{
    color: #168dee !important; 
    border-color: #168dee !important;
}
.btn-outline-info.btn-outline-darken-2:hover
{
    background-color: #168dee !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #168dee !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #168dee !important;
}

.border-info.border-darken-2
{
    border: 1px solid #168dee !important;
}

.border-top-info.border-top-darken-2
{
    border-top: 1px solid #168dee !important;
}

.border-bottom-info.border-bottom-darken-2
{
    border-bottom: 1px solid #168dee !important;
}

.border-left-info.border-left-darken-2
{
    border-left: 1px solid #168dee !important;
}

.border-right-info.border-right-darken-2
{
    border-right: 1px solid #168dee !important;
}

.overlay-info.overlay-darken-2
{
    background: #168dee;
    /* The Fallback */
    background: rgba(22, 141, 238, .8);
}

.info.darken-3
{
    color: #1283ec !important;
}

.bg-info.bg-darken-3
{
    background-color: #1283ec !important;
}
.bg-info.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(18, 131, 236, .6);
            box-shadow: 0 1px 20px 1px rgba(18, 131, 236, .6);
}

.btn-info.btn-darken-3
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-darken-3:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-darken-3:focus,
.btn-info.btn-darken-3:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-darken-3
{
    color: #1283ec !important; 
    border-color: #1283ec !important;
}
.btn-outline-info.btn-outline-darken-3:hover
{
    background-color: #1283ec !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1283ec !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1283ec !important;
}

.border-info.border-darken-3
{
    border: 1px solid #1283ec !important;
}

.border-top-info.border-top-darken-3
{
    border-top: 1px solid #1283ec !important;
}

.border-bottom-info.border-bottom-darken-3
{
    border-bottom: 1px solid #1283ec !important;
}

.border-left-info.border-left-darken-3
{
    border-left: 1px solid #1283ec !important;
}

.border-right-info.border-right-darken-3
{
    border-right: 1px solid #1283ec !important;
}

.overlay-info.overlay-darken-3
{
    background: #1283ec;
    /* The Fallback */
    background: rgba(18, 131, 236, .8);
}

.info.darken-4
{
    color: #0a72e8 !important;
}

.bg-info.bg-darken-4
{
    background-color: #0a72e8 !important;
}
.bg-info.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(10, 114, 232, .6);
            box-shadow: 0 1px 20px 1px rgba(10, 114, 232, .6);
}

.btn-info.btn-darken-4
{
    border-color: #168dee !important;
    background-color: #0a72e8 !important;
}
.btn-info.btn-darken-4:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-darken-4:focus,
.btn-info.btn-darken-4:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-darken-4
{
    color: #0a72e8 !important; 
    border-color: #0a72e8 !important;
}
.btn-outline-info.btn-outline-darken-4:hover
{
    background-color: #0a72e8 !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0a72e8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0a72e8 !important;
}

.border-info.border-darken-4
{
    border: 1px solid #0a72e8 !important;
}

.border-top-info.border-top-darken-4
{
    border-top: 1px solid #0a72e8 !important;
}

.border-bottom-info.border-bottom-darken-4
{
    border-bottom: 1px solid #0a72e8 !important;
}

.border-left-info.border-left-darken-4
{
    border-left: 1px solid #0a72e8 !important;
}

.border-right-info.border-right-darken-4
{
    border-right: 1px solid #0a72e8 !important;
}

.overlay-info.overlay-darken-4
{
    background: #0a72e8;
    /* The Fallback */
    background: rgba(10, 114, 232, .8);
}

.info.accent-1
{
    color: #eff5ff !important;
}

.bg-info.bg-accent-1
{
    background-color: #eff5ff !important;
}
.bg-info.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(239, 245, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(239, 245, 255, .6);
}

.btn-info.btn-accent-1
{
    border-color: #168dee !important;
    background-color: #eff5ff !important;
}
.btn-info.btn-accent-1:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-accent-1:focus,
.btn-info.btn-accent-1:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-accent-1
{
    color: #eff5ff !important; 
    border-color: #eff5ff !important;
}
.btn-outline-info.btn-outline-accent-1:hover
{
    background-color: #eff5ff !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eff5ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eff5ff !important;
}

.border-info.border-accent-1
{
    border: 1px solid #eff5ff !important;
}

.border-top-info.border-top-accent-1
{
    border-top: 1px solid #eff5ff !important;
}

.border-bottom-info.border-bottom-accent-1
{
    border-bottom: 1px solid #eff5ff !important;
}

.border-left-info.border-left-accent-1
{
    border-left: 1px solid #eff5ff !important;
}

.border-right-info.border-right-accent-1
{
    border-right: 1px solid #eff5ff !important;
}

.overlay-info.overlay-accent-1
{
    background: #eff5ff;
    /* The Fallback */
    background: rgba(239, 245, 255, .8);
}

.info.accent-2
{
    color: #dfecff !important;
}

.bg-info.bg-accent-2
{
    background-color: #dfecff !important;
}
.bg-info.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(223, 236, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(223, 236, 255, .6);
}

.btn-info.btn-accent-2
{
    border-color: #168dee !important;
    background-color: #dfecff !important;
}
.btn-info.btn-accent-2:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-accent-2:focus,
.btn-info.btn-accent-2:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-accent-2
{
    color: #dfecff !important; 
    border-color: #dfecff !important;
}
.btn-outline-info.btn-outline-accent-2:hover
{
    background-color: #dfecff !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dfecff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #dfecff !important;
}

.border-info.border-accent-2
{
    border: 1px solid #dfecff !important;
}

.border-top-info.border-top-accent-2
{
    border-top: 1px solid #dfecff !important;
}

.border-bottom-info.border-bottom-accent-2
{
    border-bottom: 1px solid #dfecff !important;
}

.border-left-info.border-left-accent-2
{
    border-left: 1px solid #dfecff !important;
}

.border-right-info.border-right-accent-2
{
    border-right: 1px solid #dfecff !important;
}

.overlay-info.overlay-accent-2
{
    background: #dfecff;
    /* The Fallback */
    background: rgba(223, 236, 255, .8);
}

.info.accent-3
{
    color: #accfff !important;
}

.bg-info.bg-accent-3
{
    background-color: #accfff !important;
}
.bg-info.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(172, 207, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(172, 207, 255, .6);
}

.btn-info.btn-accent-3
{
    border-color: #168dee !important;
    background-color: #accfff !important;
}
.btn-info.btn-accent-3:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-accent-3:focus,
.btn-info.btn-accent-3:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-accent-3
{
    color: #accfff !important; 
    border-color: #accfff !important;
}
.btn-outline-info.btn-outline-accent-3:hover
{
    background-color: #accfff !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #accfff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #accfff !important;
}

.border-info.border-accent-3
{
    border: 1px solid #accfff !important;
}

.border-top-info.border-top-accent-3
{
    border-top: 1px solid #accfff !important;
}

.border-bottom-info.border-bottom-accent-3
{
    border-bottom: 1px solid #accfff !important;
}

.border-left-info.border-left-accent-3
{
    border-left: 1px solid #accfff !important;
}

.border-right-info.border-right-accent-3
{
    border-right: 1px solid #accfff !important;
}

.overlay-info.overlay-accent-3
{
    background: #accfff;
    /* The Fallback */
    background: rgba(172, 207, 255, .8);
}

.info.accent-4
{
    color: #92c0ff !important;
}

.bg-info.bg-accent-4
{
    background-color: #92c0ff !important;
}
.bg-info.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(146, 192, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(146, 192, 255, .6);
}

.btn-info.btn-accent-4
{
    border-color: #168dee !important;
    background-color: #92c0ff !important;
}
.btn-info.btn-accent-4:hover
{
    border-color: #168dee !important;
    background-color: #1283ec !important;
}
.btn-info.btn-accent-4:focus,
.btn-info.btn-accent-4:active
{
    border-color: #1283ec !important;
    background-color: #0a72e8 !important;
}

.btn-outline-info.btn-outline-accent-4
{
    color: #92c0ff !important; 
    border-color: #92c0ff !important;
}
.btn-outline-info.btn-outline-accent-4:hover
{
    background-color: #92c0ff !important;
}

input:focus ~ .bg-info
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #92c0ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #92c0ff !important;
}

.border-info.border-accent-4
{
    border: 1px solid #92c0ff !important;
}

.border-top-info.border-top-accent-4
{
    border-top: 1px solid #92c0ff !important;
}

.border-bottom-info.border-bottom-accent-4
{
    border-bottom: 1px solid #92c0ff !important;
}

.border-left-info.border-left-accent-4
{
    border-left: 1px solid #92c0ff !important;
}

.border-right-info.border-right-accent-4
{
    border-right: 1px solid #92c0ff !important;
}

.overlay-info.overlay-accent-4
{
    background: #92c0ff;
    /* The Fallback */
    background: rgba(146, 192, 255, .8);
}

.warning.lighten-5
{
    color: #fff2e9 !important;
}

.bg-warning.bg-lighten-5
{
    background-color: #fff2e9 !important;
}
.bg-warning.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 242, 233, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 242, 233, .6);
}

.btn-warning.btn-lighten-5
{
    border-color: #ff7e39 !important;
    background-color: #fff2e9 !important;
}
.btn-warning.btn-lighten-5:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-lighten-5:focus,
.btn-warning.btn-lighten-5:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-lighten-5
{
    color: #fff2e9 !important; 
    border-color: #fff2e9 !important;
}
.btn-outline-warning.btn-outline-lighten-5:hover
{
    background-color: #fff2e9 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff2e9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff2e9 !important;
}

.border-warning.border-lighten-5
{
    border: 1px solid #fff2e9 !important;
}

.border-top-warning.border-top-lighten-5
{
    border-top: 1px solid #fff2e9 !important;
}

.border-bottom-warning.border-bottom-lighten-5
{
    border-bottom: 1px solid #fff2e9 !important;
}

.border-left-warning.border-left-lighten-5
{
    border-left: 1px solid #fff2e9 !important;
}

.border-right-warning.border-right-lighten-5
{
    border-right: 1px solid #fff2e9 !important;
}

.overlay-warning.overlay-lighten-5
{
    background: #fff2e9;
    /* The Fallback */
    background: rgba(255, 242, 233, .8);
}

.warning.lighten-4
{
    color: #ffdec8 !important;
}

.bg-warning.bg-lighten-4
{
    background-color: #ffdec8 !important;
}
.bg-warning.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 222, 200, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 222, 200, .6);
}

.btn-warning.btn-lighten-4
{
    border-color: #ff7e39 !important;
    background-color: #ffdec8 !important;
}
.btn-warning.btn-lighten-4:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-lighten-4:focus,
.btn-warning.btn-lighten-4:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-lighten-4
{
    color: #ffdec8 !important; 
    border-color: #ffdec8 !important;
}
.btn-outline-warning.btn-outline-lighten-4:hover
{
    background-color: #ffdec8 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffdec8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffdec8 !important;
}

.border-warning.border-lighten-4
{
    border: 1px solid #ffdec8 !important;
}

.border-top-warning.border-top-lighten-4
{
    border-top: 1px solid #ffdec8 !important;
}

.border-bottom-warning.border-bottom-lighten-4
{
    border-bottom: 1px solid #ffdec8 !important;
}

.border-left-warning.border-left-lighten-4
{
    border-left: 1px solid #ffdec8 !important;
}

.border-right-warning.border-right-lighten-4
{
    border-right: 1px solid #ffdec8 !important;
}

.overlay-warning.overlay-lighten-4
{
    background: #ffdec8;
    /* The Fallback */
    background: rgba(255, 222, 200, .8);
}

.warning.lighten-3
{
    color: #ffc8a4 !important;
}

.bg-warning.bg-lighten-3
{
    background-color: #ffc8a4 !important;
}
.bg-warning.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 200, 164, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 200, 164, .6);
}

.btn-warning.btn-lighten-3
{
    border-color: #ff7e39 !important;
    background-color: #ffc8a4 !important;
}
.btn-warning.btn-lighten-3:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-lighten-3:focus,
.btn-warning.btn-lighten-3:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-lighten-3
{
    color: #ffc8a4 !important; 
    border-color: #ffc8a4 !important;
}
.btn-outline-warning.btn-outline-lighten-3:hover
{
    background-color: #ffc8a4 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc8a4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc8a4 !important;
}

.border-warning.border-lighten-3
{
    border: 1px solid #ffc8a4 !important;
}

.border-top-warning.border-top-lighten-3
{
    border-top: 1px solid #ffc8a4 !important;
}

.border-bottom-warning.border-bottom-lighten-3
{
    border-bottom: 1px solid #ffc8a4 !important;
}

.border-left-warning.border-left-lighten-3
{
    border-left: 1px solid #ffc8a4 !important;
}

.border-right-warning.border-right-lighten-3
{
    border-right: 1px solid #ffc8a4 !important;
}

.overlay-warning.overlay-lighten-3
{
    background: #ffc8a4;
    /* The Fallback */
    background: rgba(255, 200, 164, .8);
}

.warning.lighten-2
{
    color: #ffb280 !important;
}

.bg-warning.bg-lighten-2
{
    background-color: #ffb280 !important;
}
.bg-warning.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 178, 128, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 178, 128, .6);
}

.btn-warning.btn-lighten-2
{
    border-color: #ff7e39 !important;
    background-color: #ffb280 !important;
}
.btn-warning.btn-lighten-2:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-lighten-2:focus,
.btn-warning.btn-lighten-2:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-lighten-2
{
    color: #ffb280 !important; 
    border-color: #ffb280 !important;
}
.btn-outline-warning.btn-outline-lighten-2:hover
{
    background-color: #ffb280 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb280 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb280 !important;
}

.border-warning.border-lighten-2
{
    border: 1px solid #ffb280 !important;
}

.border-top-warning.border-top-lighten-2
{
    border-top: 1px solid #ffb280 !important;
}

.border-bottom-warning.border-bottom-lighten-2
{
    border-bottom: 1px solid #ffb280 !important;
}

.border-left-warning.border-left-lighten-2
{
    border-left: 1px solid #ffb280 !important;
}

.border-right-warning.border-right-lighten-2
{
    border-right: 1px solid #ffb280 !important;
}

.overlay-warning.overlay-lighten-2
{
    background: #ffb280;
    /* The Fallback */
    background: rgba(255, 178, 128, .8);
}

.warning.lighten-1
{
    color: #ffa264 !important;
}

.bg-warning.bg-lighten-1
{
    background-color: #ffa264 !important;
}
.bg-warning.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 162, 100, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 162, 100, .6);
}

.btn-warning.btn-lighten-1
{
    border-color: #ff7e39 !important;
    background-color: #ffa264 !important;
}
.btn-warning.btn-lighten-1:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-lighten-1:focus,
.btn-warning.btn-lighten-1:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-lighten-1
{
    color: #ffa264 !important; 
    border-color: #ffa264 !important;
}
.btn-outline-warning.btn-outline-lighten-1:hover
{
    background-color: #ffa264 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa264 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa264 !important;
}

.border-warning.border-lighten-1
{
    border: 1px solid #ffa264 !important;
}

.border-top-warning.border-top-lighten-1
{
    border-top: 1px solid #ffa264 !important;
}

.border-bottom-warning.border-bottom-lighten-1
{
    border-bottom: 1px solid #ffa264 !important;
}

.border-left-warning.border-left-lighten-1
{
    border-left: 1px solid #ffa264 !important;
}

.border-right-warning.border-right-lighten-1
{
    border-right: 1px solid #ffa264 !important;
}

.overlay-warning.overlay-lighten-1
{
    background: #ffa264;
    /* The Fallback */
    background: rgba(255, 162, 100, .8);
}

.warning
{
    color: #ff9149 !important;
}

.bg-warning
{
    background-color: #e87d34 !important;
}
.bg-warning .card-header,
.bg-warning .card-footer
{
    background-color: transparent;
}
.bg-warning.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 145, 73, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 145, 73, .6);
}

.selectboxit.selectboxit-btn.bg-warning
{
    background-color: #ff9149 !important;
}

.alert-warning
{
    color: #963b00 !important; 
    border-color: #ff9149 !important;
    background-color: #ffbc90 !important;
}
.alert-warning .alert-link
{
    color: #6d2b00 !important;
}

.border-warning
{
    border-color: #ff9149;
}

.overlay-warning
{
    background: #ff9149;
    /* The Fallback */
    background: rgba(255, 145, 73, .8);
}

/* .card-outline-warning{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-warning
{
    color: #fff; 
    border-color: #ff7e39 !important;
    background-color: #ff9149 !important;
}
.btn-warning:hover
{
    color: #fff !important; 
    border-color: #ff8942 !important;
    background-color: #ffa264 !important;
}
.btn-warning:focus,
.btn-warning:active
{
    color: #fff !important; 
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 145, 73, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 145, 73, .6);
}

.btn-outline-warning
{
    color: #ff9149; 
    border-color: #ff9149;
    background-color: transparent;
}
.btn-outline-warning:hover
{
    color: #fff !important; 
    background-color: #ff9149;
}
.btn-outline-warning.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 145, 73, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 145, 73, .6);
}

input[type='checkbox'].bg-warning + .custom-control-label:before,
input[type='radio'].bg-warning + .custom-control-label:before
{
    background-color: #ff9149 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff9149 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff9149 !important;
}

.border-warning
{
    border: 1px solid #ff9149 !important;
}

.border-top-warning
{
    border-top: 1px solid #ff9149;
}

.border-bottom-warning
{
    border-bottom: 1px solid #ff9149;
}

.border-left-warning
{
    border-left: 1px solid #ff9149;
}

.border-right-warning
{
    border-right: 1px solid #ff9149;
}

.warning.darken-1
{
    color: #ff8942 !important;
}

.bg-warning.bg-darken-1
{
    background-color: #ff8942 !important;
}
.bg-warning.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 137, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 137, 66, .6);
}

.btn-warning.btn-darken-1
{
    border-color: #ff7e39 !important;
    background-color: #ff8942 !important;
}
.btn-warning.btn-darken-1:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-darken-1:focus,
.btn-warning.btn-darken-1:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-darken-1
{
    color: #ff8942 !important; 
    border-color: #ff8942 !important;
}
.btn-outline-warning.btn-outline-darken-1:hover
{
    background-color: #ff8942 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8942 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8942 !important;
}

.border-warning.border-darken-1
{
    border: 1px solid #ff8942 !important;
}

.border-top-warning.border-top-darken-1
{
    border-top: 1px solid #ff8942 !important;
}

.border-bottom-warning.border-bottom-darken-1
{
    border-bottom: 1px solid #ff8942 !important;
}

.border-left-warning.border-left-darken-1
{
    border-left: 1px solid #ff8942 !important;
}

.border-right-warning.border-right-darken-1
{
    border-right: 1px solid #ff8942 !important;
}

.overlay-warning.overlay-darken-1
{
    background: #ff8942;
    /* The Fallback */
    background: rgba(255, 137, 66, .8);
}

.warning.darken-2
{
    color: #ff7e39 !important;
}

.bg-warning.bg-darken-2
{
    background-color: #ff7e39 !important;
}
.bg-warning.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 126, 57, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 126, 57, .6);
}

.btn-warning.btn-darken-2
{
    border-color: #ff7e39 !important;
    background-color: #ff7e39 !important;
}
.btn-warning.btn-darken-2:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-darken-2:focus,
.btn-warning.btn-darken-2:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-darken-2
{
    color: #ff7e39 !important; 
    border-color: #ff7e39 !important;
}
.btn-outline-warning.btn-outline-darken-2:hover
{
    background-color: #ff7e39 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff7e39 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff7e39 !important;
}

.border-warning.border-darken-2
{
    border: 1px solid #ff7e39 !important;
}

.border-top-warning.border-top-darken-2
{
    border-top: 1px solid #ff7e39 !important;
}

.border-bottom-warning.border-bottom-darken-2
{
    border-bottom: 1px solid #ff7e39 !important;
}

.border-left-warning.border-left-darken-2
{
    border-left: 1px solid #ff7e39 !important;
}

.border-right-warning.border-right-darken-2
{
    border-right: 1px solid #ff7e39 !important;
}

.overlay-warning.overlay-darken-2
{
    background: #ff7e39;
    /* The Fallback */
    background: rgba(255, 126, 57, .8);
}

.warning.darken-3
{
    color: #ff7431 !important;
}

.bg-warning.bg-darken-3
{
    background-color: #ff7431 !important;
}
.bg-warning.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 116, 49, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 116, 49, .6);
}

.btn-warning.btn-darken-3
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-darken-3:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-darken-3:focus,
.btn-warning.btn-darken-3:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-darken-3
{
    color: #ff7431 !important; 
    border-color: #ff7431 !important;
}
.btn-outline-warning.btn-outline-darken-3:hover
{
    background-color: #ff7431 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff7431 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff7431 !important;
}

.border-warning.border-darken-3
{
    border: 1px solid #ff7431 !important;
}

.border-top-warning.border-top-darken-3
{
    border-top: 1px solid #ff7431 !important;
}

.border-bottom-warning.border-bottom-darken-3
{
    border-bottom: 1px solid #ff7431 !important;
}

.border-left-warning.border-left-darken-3
{
    border-left: 1px solid #ff7431 !important;
}

.border-right-warning.border-right-darken-3
{
    border-right: 1px solid #ff7431 !important;
}

.overlay-warning.overlay-darken-3
{
    background: #ff7431;
    /* The Fallback */
    background: rgba(255, 116, 49, .8);
}

.warning.darken-4
{
    color: #ff6221 !important;
}

.bg-warning.bg-darken-4
{
    background-color: #ff6221 !important;
}
.bg-warning.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 98, 33, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 98, 33, .6);
}

.btn-warning.btn-darken-4
{
    border-color: #ff7e39 !important;
    background-color: #ff6221 !important;
}
.btn-warning.btn-darken-4:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-darken-4:focus,
.btn-warning.btn-darken-4:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-darken-4
{
    color: #ff6221 !important; 
    border-color: #ff6221 !important;
}
.btn-outline-warning.btn-outline-darken-4:hover
{
    background-color: #ff6221 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6221 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6221 !important;
}

.border-warning.border-darken-4
{
    border: 1px solid #ff6221 !important;
}

.border-top-warning.border-top-darken-4
{
    border-top: 1px solid #ff6221 !important;
}

.border-bottom-warning.border-bottom-darken-4
{
    border-bottom: 1px solid #ff6221 !important;
}

.border-left-warning.border-left-darken-4
{
    border-left: 1px solid #ff6221 !important;
}

.border-right-warning.border-right-darken-4
{
    border-right: 1px solid #ff6221 !important;
}

.overlay-warning.overlay-darken-4
{
    background: #ff6221;
    /* The Fallback */
    background: rgba(255, 98, 33, .8);
}

.warning.accent-1
{
    color: #fff5ef !important;
}

.bg-warning.bg-accent-1
{
    background-color: #fff5ef !important;
}
.bg-warning.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 245, 239, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 245, 239, .6);
}

.btn-warning.btn-accent-1
{
    border-color: #ff7e39 !important;
    background-color: #fff5ef !important;
}
.btn-warning.btn-accent-1:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-accent-1:focus,
.btn-warning.btn-accent-1:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-accent-1
{
    color: #fff5ef !important; 
    border-color: #fff5ef !important;
}
.btn-outline-warning.btn-outline-accent-1:hover
{
    background-color: #fff5ef !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff5ef !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff5ef !important;
}

.border-warning.border-accent-1
{
    border: 1px solid #fff5ef !important;
}

.border-top-warning.border-top-accent-1
{
    border-top: 1px solid #fff5ef !important;
}

.border-bottom-warning.border-bottom-accent-1
{
    border-bottom: 1px solid #fff5ef !important;
}

.border-left-warning.border-left-accent-1
{
    border-left: 1px solid #fff5ef !important;
}

.border-right-warning.border-right-accent-1
{
    border-right: 1px solid #fff5ef !important;
}

.overlay-warning.overlay-accent-1
{
    background: #fff5ef;
    /* The Fallback */
    background: rgba(255, 245, 239, .8);
}

.warning.accent-2
{
    color: #ffe5d8 !important;
}

.bg-warning.bg-accent-2
{
    background-color: #ffe5d8 !important;
}
.bg-warning.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 229, 216, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 229, 216, .6);
}

.btn-warning.btn-accent-2
{
    border-color: #ff7e39 !important;
    background-color: #ffe5d8 !important;
}
.btn-warning.btn-accent-2:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-accent-2:focus,
.btn-warning.btn-accent-2:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-accent-2
{
    color: #ffe5d8 !important; 
    border-color: #ffe5d8 !important;
}
.btn-outline-warning.btn-outline-accent-2:hover
{
    background-color: #ffe5d8 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe5d8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe5d8 !important;
}

.border-warning.border-accent-2
{
    border: 1px solid #ffe5d8 !important;
}

.border-top-warning.border-top-accent-2
{
    border-top: 1px solid #ffe5d8 !important;
}

.border-bottom-warning.border-bottom-accent-2
{
    border-bottom: 1px solid #ffe5d8 !important;
}

.border-left-warning.border-left-accent-2
{
    border-left: 1px solid #ffe5d8 !important;
}

.border-right-warning.border-right-accent-2
{
    border-right: 1px solid #ffe5d8 !important;
}

.overlay-warning.overlay-accent-2
{
    background: #ffe5d8;
    /* The Fallback */
    background: rgba(255, 229, 216, .8);
}

.warning.accent-3
{
    color: #ffddd0 !important;
}

.bg-warning.bg-accent-3
{
    background-color: #ffddd0 !important;
}
.bg-warning.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 221, 208, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 221, 208, .6);
}

.btn-warning.btn-accent-3
{
    border-color: #ff7e39 !important;
    background-color: #ffddd0 !important;
}
.btn-warning.btn-accent-3:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-accent-3:focus,
.btn-warning.btn-accent-3:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-accent-3
{
    color: #ffddd0 !important; 
    border-color: #ffddd0 !important;
}
.btn-outline-warning.btn-outline-accent-3:hover
{
    background-color: #ffddd0 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffddd0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffddd0 !important;
}

.border-warning.border-accent-3
{
    border: 1px solid #ffddd0 !important;
}

.border-top-warning.border-top-accent-3
{
    border-top: 1px solid #ffddd0 !important;
}

.border-bottom-warning.border-bottom-accent-3
{
    border-bottom: 1px solid #ffddd0 !important;
}

.border-left-warning.border-left-accent-3
{
    border-left: 1px solid #ffddd0 !important;
}

.border-right-warning.border-right-accent-3
{
    border-right: 1px solid #ffddd0 !important;
}

.overlay-warning.overlay-accent-3
{
    background: #ffddd0;
    /* The Fallback */
    background: rgba(255, 221, 208, .8);
}

.warning.accent-4
{
    color: #ffcab7 !important;
}

.bg-warning.bg-accent-4
{
    background-color: #ffcab7 !important;
}
.bg-warning.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 202, 183, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 202, 183, .6);
}

.btn-warning.btn-accent-4
{
    border-color: #ff7e39 !important;
    background-color: #ffcab7 !important;
}
.btn-warning.btn-accent-4:hover
{
    border-color: #ff7e39 !important;
    background-color: #ff7431 !important;
}
.btn-warning.btn-accent-4:focus,
.btn-warning.btn-accent-4:active
{
    border-color: #ff7431 !important;
    background-color: #ff6221 !important;
}

.btn-outline-warning.btn-outline-accent-4
{
    color: #ffcab7 !important; 
    border-color: #ffcab7 !important;
}
.btn-outline-warning.btn-outline-accent-4:hover
{
    background-color: #ffcab7 !important;
}

input:focus ~ .bg-warning
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffcab7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffcab7 !important;
}

.border-warning.border-accent-4
{
    border: 1px solid #ffcab7 !important;
}

.border-top-warning.border-top-accent-4
{
    border-top: 1px solid #ffcab7 !important;
}

.border-bottom-warning.border-bottom-accent-4
{
    border-bottom: 1px solid #ffcab7 !important;
}

.border-left-warning.border-left-accent-4
{
    border-left: 1px solid #ffcab7 !important;
}

.border-right-warning.border-right-accent-4
{
    border-right: 1px solid #ffcab7 !important;
}

.overlay-warning.overlay-accent-4
{
    background: #ffcab7;
    /* The Fallback */
    background: rgba(255, 202, 183, .8);
}

.danger.lighten-5
{
    color: #ffe9ec !important;
}

.bg-danger.bg-lighten-5
{
    background-color: #ffe9ec !important;
}
.bg-danger.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 233, 236, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 233, 236, .6);
}

.btn-danger.btn-lighten-5
{
    border-color: #ff394f !important;
    background-color: #ffe9ec !important;
}
.btn-danger.btn-lighten-5:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-lighten-5:focus,
.btn-danger.btn-lighten-5:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-lighten-5
{
    color: #ffe9ec !important; 
    border-color: #ffe9ec !important;
}
.btn-outline-danger.btn-outline-lighten-5:hover
{
    background-color: #ffe9ec !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe9ec !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe9ec !important;
}

.border-danger.border-lighten-5
{
    border: 1px solid #ffe9ec !important;
}

.border-top-danger.border-top-lighten-5
{
    border-top: 1px solid #ffe9ec !important;
}

.border-bottom-danger.border-bottom-lighten-5
{
    border-bottom: 1px solid #ffe9ec !important;
}

.border-left-danger.border-left-lighten-5
{
    border-left: 1px solid #ffe9ec !important;
}

.border-right-danger.border-right-lighten-5
{
    border-right: 1px solid #ffe9ec !important;
}

.overlay-danger.overlay-lighten-5
{
    background: #ffe9ec;
    /* The Fallback */
    background: rgba(255, 233, 236, .8);
}

.danger.lighten-4
{
    color: #ffc8d0 !important;
}

.bg-danger.bg-lighten-4
{
    background-color: #ffc8d0 !important;
}
.bg-danger.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 200, 208, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 200, 208, .6);
}

.btn-danger.btn-lighten-4
{
    border-color: #ff394f !important;
    background-color: #ffc8d0 !important;
}
.btn-danger.btn-lighten-4:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-lighten-4:focus,
.btn-danger.btn-lighten-4:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-lighten-4
{
    color: #ffc8d0 !important; 
    border-color: #ffc8d0 !important;
}
.btn-outline-danger.btn-outline-lighten-4:hover
{
    background-color: #ffc8d0 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc8d0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc8d0 !important;
}

.border-danger.border-lighten-4
{
    border: 1px solid #ffc8d0 !important;
}

.border-top-danger.border-top-lighten-4
{
    border-top: 1px solid #ffc8d0 !important;
}

.border-bottom-danger.border-bottom-lighten-4
{
    border-bottom: 1px solid #ffc8d0 !important;
}

.border-left-danger.border-left-lighten-4
{
    border-left: 1px solid #ffc8d0 !important;
}

.border-right-danger.border-right-lighten-4
{
    border-right: 1px solid #ffc8d0 !important;
}

.overlay-danger.overlay-lighten-4
{
    background: #ffc8d0;
    /* The Fallback */
    background: rgba(255, 200, 208, .8);
}

.danger.lighten-3
{
    color: #ffa4b0 !important;
}

.bg-danger.bg-lighten-3
{
    background-color: #ffa4b0 !important;
}
.bg-danger.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 164, 176, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 164, 176, .6);
}

.btn-danger.btn-lighten-3
{
    border-color: #ff394f !important;
    background-color: #ffa4b0 !important;
}
.btn-danger.btn-lighten-3:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-lighten-3:focus,
.btn-danger.btn-lighten-3:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-lighten-3
{
    color: #ffa4b0 !important; 
    border-color: #ffa4b0 !important;
}
.btn-outline-danger.btn-outline-lighten-3:hover
{
    background-color: #ffa4b0 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa4b0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa4b0 !important;
}

.border-danger.border-lighten-3
{
    border: 1px solid #ffa4b0 !important;
}

.border-top-danger.border-top-lighten-3
{
    border-top: 1px solid #ffa4b0 !important;
}

.border-bottom-danger.border-bottom-lighten-3
{
    border-bottom: 1px solid #ffa4b0 !important;
}

.border-left-danger.border-left-lighten-3
{
    border-left: 1px solid #ffa4b0 !important;
}

.border-right-danger.border-right-lighten-3
{
    border-right: 1px solid #ffa4b0 !important;
}

.overlay-danger.overlay-lighten-3
{
    background: #ffa4b0;
    /* The Fallback */
    background: rgba(255, 164, 176, .8);
}

.danger.lighten-2
{
    color: #ff8090 !important;
}

.bg-danger.bg-lighten-2
{
    background-color: #ff8090 !important;
}
.bg-danger.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 128, 144, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 128, 144, .6);
}

.btn-danger.btn-lighten-2
{
    border-color: #ff394f !important;
    background-color: #ff8090 !important;
}
.btn-danger.btn-lighten-2:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-lighten-2:focus,
.btn-danger.btn-lighten-2:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-lighten-2
{
    color: #ff8090 !important; 
    border-color: #ff8090 !important;
}
.btn-outline-danger.btn-outline-lighten-2:hover
{
    background-color: #ff8090 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8090 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8090 !important;
}

.border-danger.border-lighten-2
{
    border: 1px solid #ff8090 !important;
}

.border-top-danger.border-top-lighten-2
{
    border-top: 1px solid #ff8090 !important;
}

.border-bottom-danger.border-bottom-lighten-2
{
    border-bottom: 1px solid #ff8090 !important;
}

.border-left-danger.border-left-lighten-2
{
    border-left: 1px solid #ff8090 !important;
}

.border-right-danger.border-right-lighten-2
{
    border-right: 1px solid #ff8090 !important;
}

.overlay-danger.overlay-lighten-2
{
    background: #ff8090;
    /* The Fallback */
    background: rgba(255, 128, 144, .8);
}

.danger.lighten-1
{
    color: #ff6479 !important;
}

.bg-danger.bg-lighten-1
{
    background-color: #ff6479 !important;
}
.bg-danger.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 100, 121, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 100, 121, .6);
}

.btn-danger.btn-lighten-1
{
    border-color: #ff394f !important;
    background-color: #ff6479 !important;
}
.btn-danger.btn-lighten-1:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-lighten-1:focus,
.btn-danger.btn-lighten-1:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-lighten-1
{
    color: #ff6479 !important; 
    border-color: #ff6479 !important;
}
.btn-outline-danger.btn-outline-lighten-1:hover
{
    background-color: #ff6479 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6479 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6479 !important;
}

.border-danger.border-lighten-1
{
    border: 1px solid #ff6479 !important;
}

.border-top-danger.border-top-lighten-1
{
    border-top: 1px solid #ff6479 !important;
}

.border-bottom-danger.border-bottom-lighten-1
{
    border-bottom: 1px solid #ff6479 !important;
}

.border-left-danger.border-left-lighten-1
{
    border-left: 1px solid #ff6479 !important;
}

.border-right-danger.border-right-lighten-1
{
    border-right: 1px solid #ff6479 !important;
}

.overlay-danger.overlay-lighten-1
{
    background: #ff6479;
    /* The Fallback */
    background: rgba(255, 100, 121, .8);
}

.danger
{
    color: #ff4961 !important;
}

.bg-danger
{
    background-color: #ff4961 !important;
}
.bg-danger .card-header,
.bg-danger .card-footer
{
    background-color: transparent;
}
.bg-danger.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 73, 97, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 73, 97, .6);
}

.selectboxit.selectboxit-btn.bg-danger
{
    background-color: #ff4961 !important;
}

.alert-danger
{
    color: #960014 !important; 
    border-color: #ff4961 !important;
    background-color: #ff909f !important;
}
.alert-danger .alert-link
{
    color: #6d000e !important;
}

.border-danger
{
    border-color: #ff4961;
}

.overlay-danger
{
    background: #ff4961;
    /* The Fallback */
    background: rgba(255, 73, 97, .8);
}

/* .card-outline-danger{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-danger
{
    color: #fff; 
    border-color: #ff394f !important;
    background-color: #ff4961 !important;
}
.btn-danger:hover
{
    color: #fff !important; 
    border-color: #ff4259 !important;
    background-color: #ff6479 !important;
}
.btn-danger:focus,
.btn-danger:active
{
    color: #fff !important; 
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 73, 97, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 73, 97, .6);
}

.btn-outline-danger
{
    color: #ff4961; 
    border-color: #ff4961;
    background-color: transparent;
}
.btn-outline-danger:hover
{
    color: #fff !important; 
    background-color: #ff4961;
}
.btn-outline-danger.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 73, 97, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 73, 97, .6);
}

input[type='checkbox'].bg-danger + .custom-control-label:before,
input[type='radio'].bg-danger + .custom-control-label:before
{
    background-color: #ff4961 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff4961 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff4961 !important;
}

.border-danger
{
    border: 1px solid #ff4961 !important;
}

.border-top-danger
{
    border-top: 1px solid #ff4961;
}

.border-bottom-danger
{
    border-bottom: 1px solid #ff4961;
}

.border-left-danger
{
    border-left: 1px solid #ff4961;
}

.border-right-danger
{
    border-right: 1px solid #ff4961;
}

.danger.darken-1
{
    color: #ff4259 !important;
}

.bg-danger.bg-darken-1
{
    background-color: #ff4259 !important;
}
.bg-danger.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 66, 89, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 66, 89, .6);
}

.btn-danger.btn-darken-1
{
    border-color: #ff394f !important;
    background-color: #ff4259 !important;
}
.btn-danger.btn-darken-1:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-darken-1:focus,
.btn-danger.btn-darken-1:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-darken-1
{
    color: #ff4259 !important; 
    border-color: #ff4259 !important;
}
.btn-outline-danger.btn-outline-darken-1:hover
{
    background-color: #ff4259 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff4259 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff4259 !important;
}

.border-danger.border-darken-1
{
    border: 1px solid #ff4259 !important;
}

.border-top-danger.border-top-darken-1
{
    border-top: 1px solid #ff4259 !important;
}

.border-bottom-danger.border-bottom-darken-1
{
    border-bottom: 1px solid #ff4259 !important;
}

.border-left-danger.border-left-darken-1
{
    border-left: 1px solid #ff4259 !important;
}

.border-right-danger.border-right-darken-1
{
    border-right: 1px solid #ff4259 !important;
}

.overlay-danger.overlay-darken-1
{
    background: #ff4259;
    /* The Fallback */
    background: rgba(255, 66, 89, .8);
}

.danger.darken-2
{
    color: #ff394f !important;
}

.bg-danger.bg-darken-2
{
    background-color: #ff394f !important;
}
.bg-danger.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 57, 79, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 57, 79, .6);
}

.btn-danger.btn-darken-2
{
    border-color: #ff394f !important;
    background-color: #ff394f !important;
}
.btn-danger.btn-darken-2:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-darken-2:focus,
.btn-danger.btn-darken-2:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-darken-2
{
    color: #ff394f !important; 
    border-color: #ff394f !important;
}
.btn-outline-danger.btn-outline-darken-2:hover
{
    background-color: #ff394f !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff394f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff394f !important;
}

.border-danger.border-darken-2
{
    border: 1px solid #ff394f !important;
}

.border-top-danger.border-top-darken-2
{
    border-top: 1px solid #ff394f !important;
}

.border-bottom-danger.border-bottom-darken-2
{
    border-bottom: 1px solid #ff394f !important;
}

.border-left-danger.border-left-darken-2
{
    border-left: 1px solid #ff394f !important;
}

.border-right-danger.border-right-darken-2
{
    border-right: 1px solid #ff394f !important;
}

.overlay-danger.overlay-darken-2
{
    background: #ff394f;
    /* The Fallback */
    background: rgba(255, 57, 79, .8);
}

.danger.darken-3
{
    color: #ff3145 !important;
}

.bg-danger.bg-darken-3
{
    background-color: #ff3145 !important;
}
.bg-danger.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 49, 69, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 49, 69, .6);
}

.btn-danger.btn-darken-3
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-darken-3:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-darken-3:focus,
.btn-danger.btn-darken-3:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-darken-3
{
    color: #ff3145 !important; 
    border-color: #ff3145 !important;
}
.btn-outline-danger.btn-outline-darken-3:hover
{
    background-color: #ff3145 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff3145 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff3145 !important;
}

.border-danger.border-darken-3
{
    border: 1px solid #ff3145 !important;
}

.border-top-danger.border-top-darken-3
{
    border-top: 1px solid #ff3145 !important;
}

.border-bottom-danger.border-bottom-darken-3
{
    border-bottom: 1px solid #ff3145 !important;
}

.border-left-danger.border-left-darken-3
{
    border-left: 1px solid #ff3145 !important;
}

.border-right-danger.border-right-darken-3
{
    border-right: 1px solid #ff3145 !important;
}

.overlay-danger.overlay-darken-3
{
    background: #ff3145;
    /* The Fallback */
    background: rgba(255, 49, 69, .8);
}

.danger.darken-4
{
    color: #ff2133 !important;
}

.bg-danger.bg-darken-4
{
    background-color: #ff2133 !important;
}
.bg-danger.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 33, 51, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 33, 51, .6);
}

.btn-danger.btn-darken-4
{
    border-color: #ff394f !important;
    background-color: #ff2133 !important;
}
.btn-danger.btn-darken-4:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-darken-4:focus,
.btn-danger.btn-darken-4:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-darken-4
{
    color: #ff2133 !important; 
    border-color: #ff2133 !important;
}
.btn-outline-danger.btn-outline-darken-4:hover
{
    background-color: #ff2133 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff2133 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff2133 !important;
}

.border-danger.border-darken-4
{
    border: 1px solid #ff2133 !important;
}

.border-top-danger.border-top-darken-4
{
    border-top: 1px solid #ff2133 !important;
}

.border-bottom-danger.border-bottom-darken-4
{
    border-bottom: 1px solid #ff2133 !important;
}

.border-left-danger.border-left-darken-4
{
    border-left: 1px solid #ff2133 !important;
}

.border-right-danger.border-right-darken-4
{
    border-right: 1px solid #ff2133 !important;
}

.overlay-danger.overlay-darken-4
{
    background: #ff2133;
    /* The Fallback */
    background: rgba(255, 33, 51, .8);
}

.danger.accent-1
{
    color: #ffeef1 !important;
}

.bg-danger.bg-accent-1
{
    background-color: #ffeef1 !important;
}
.bg-danger.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 238, 241, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 238, 241, .6);
}

.btn-danger.btn-accent-1
{
    border-color: #ff394f !important;
    background-color: #ffeef1 !important;
}
.btn-danger.btn-accent-1:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-accent-1:focus,
.btn-danger.btn-accent-1:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-accent-1
{
    color: #ffeef1 !important; 
    border-color: #ffeef1 !important;
}
.btn-outline-danger.btn-outline-accent-1:hover
{
    background-color: #ffeef1 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffeef1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffeef1 !important;
}

.border-danger.border-accent-1
{
    border: 1px solid #ffeef1 !important;
}

.border-top-danger.border-top-accent-1
{
    border-top: 1px solid #ffeef1 !important;
}

.border-bottom-danger.border-bottom-accent-1
{
    border-bottom: 1px solid #ffeef1 !important;
}

.border-left-danger.border-left-accent-1
{
    border-left: 1px solid #ffeef1 !important;
}

.border-right-danger.border-right-accent-1
{
    border-right: 1px solid #ffeef1 !important;
}

.overlay-danger.overlay-accent-1
{
    background: #ffeef1;
    /* The Fallback */
    background: rgba(255, 238, 241, .8);
}

.danger.accent-2
{
    color: #ffd6db !important;
}

.bg-danger.bg-accent-2
{
    background-color: #ffd6db !important;
}
.bg-danger.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 214, 219, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 214, 219, .6);
}

.btn-danger.btn-accent-2
{
    border-color: #ff394f !important;
    background-color: #ffd6db !important;
}
.btn-danger.btn-accent-2:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-accent-2:focus,
.btn-danger.btn-accent-2:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-accent-2
{
    color: #ffd6db !important; 
    border-color: #ffd6db !important;
}
.btn-outline-danger.btn-outline-accent-2:hover
{
    background-color: #ffd6db !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd6db !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd6db !important;
}

.border-danger.border-accent-2
{
    border: 1px solid #ffd6db !important;
}

.border-top-danger.border-top-accent-2
{
    border-top: 1px solid #ffd6db !important;
}

.border-bottom-danger.border-bottom-accent-2
{
    border-bottom: 1px solid #ffd6db !important;
}

.border-left-danger.border-left-accent-2
{
    border-left: 1px solid #ffd6db !important;
}

.border-right-danger.border-right-accent-2
{
    border-right: 1px solid #ffd6db !important;
}

.overlay-danger.overlay-accent-2
{
    background: #ffd6db;
    /* The Fallback */
    background: rgba(255, 214, 219, .8);
}

.danger.accent-3
{
    color: #ffd0d3 !important;
}

.bg-danger.bg-accent-3
{
    background-color: #ffd0d3 !important;
}
.bg-danger.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 208, 211, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 208, 211, .6);
}

.btn-danger.btn-accent-3
{
    border-color: #ff394f !important;
    background-color: #ffd0d3 !important;
}
.btn-danger.btn-accent-3:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-accent-3:focus,
.btn-danger.btn-accent-3:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-accent-3
{
    color: #ffd0d3 !important; 
    border-color: #ffd0d3 !important;
}
.btn-outline-danger.btn-outline-accent-3:hover
{
    background-color: #ffd0d3 !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd0d3 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd0d3 !important;
}

.border-danger.border-accent-3
{
    border: 1px solid #ffd0d3 !important;
}

.border-top-danger.border-top-accent-3
{
    border-top: 1px solid #ffd0d3 !important;
}

.border-bottom-danger.border-bottom-accent-3
{
    border-bottom: 1px solid #ffd0d3 !important;
}

.border-left-danger.border-left-accent-3
{
    border-left: 1px solid #ffd0d3 !important;
}

.border-right-danger.border-right-accent-3
{
    border-right: 1px solid #ffd0d3 !important;
}

.overlay-danger.overlay-accent-3
{
    background: #ffd0d3;
    /* The Fallback */
    background: rgba(255, 208, 211, .8);
}

.danger.accent-4
{
    color: #ffb7bc !important;
}

.bg-danger.bg-accent-4
{
    background-color: #ffb7bc !important;
}
.bg-danger.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 183, 188, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 183, 188, .6);
}

.btn-danger.btn-accent-4
{
    border-color: #ff394f !important;
    background-color: #ffb7bc !important;
}
.btn-danger.btn-accent-4:hover
{
    border-color: #ff394f !important;
    background-color: #ff3145 !important;
}
.btn-danger.btn-accent-4:focus,
.btn-danger.btn-accent-4:active
{
    border-color: #ff3145 !important;
    background-color: #ff2133 !important;
}

.btn-outline-danger.btn-outline-accent-4
{
    color: #ffb7bc !important; 
    border-color: #ffb7bc !important;
}
.btn-outline-danger.btn-outline-accent-4:hover
{
    background-color: #ffb7bc !important;
}

input:focus ~ .bg-danger
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb7bc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb7bc !important;
}

.border-danger.border-accent-4
{
    border: 1px solid #ffb7bc !important;
}

.border-top-danger.border-top-accent-4
{
    border-top: 1px solid #ffb7bc !important;
}

.border-bottom-danger.border-bottom-accent-4
{
    border-bottom: 1px solid #ffb7bc !important;
}

.border-left-danger.border-left-accent-4
{
    border-left: 1px solid #ffb7bc !important;
}

.border-right-danger.border-right-accent-4
{
    border-right: 1px solid #ffb7bc !important;
}

.overlay-danger.overlay-accent-4
{
    background: #ffb7bc;
    /* The Fallback */
    background: rgba(255, 183, 188, .8);
}

.red.lighten-5
{
    color: #ffebee !important;
}

.bg-red.bg-lighten-5
{
    background-color: #ffebee !important;
}
.bg-red.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 235, 238, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 235, 238, .6);
}

.btn-red.btn-lighten-5
{
    border-color: #d32f2f !important;
    background-color: #ffebee !important;
}
.btn-red.btn-lighten-5:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-5:focus,
.btn-red.btn-lighten-5:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-5
{
    color: #ffebee !important; 
    border-color: #ffebee !important;
}
.btn-outline-red.btn-outline-lighten-5:hover
{
    background-color: #ffebee !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffebee !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffebee !important;
}

.border-red.border-lighten-5
{
    border: 1px solid #ffebee !important;
}

.border-top-red.border-top-lighten-5
{
    border-top: 1px solid #ffebee !important;
}

.border-bottom-red.border-bottom-lighten-5
{
    border-bottom: 1px solid #ffebee !important;
}

.border-left-red.border-left-lighten-5
{
    border-left: 1px solid #ffebee !important;
}

.border-right-red.border-right-lighten-5
{
    border-right: 1px solid #ffebee !important;
}

.overlay-red.overlay-lighten-5
{
    background: #ffebee;
    /* The Fallback */
    background: rgba(255, 235, 238, .8);
}

.red.lighten-4
{
    color: #ffcdd2 !important;
}

.bg-red.bg-lighten-4
{
    background-color: #ffcdd2 !important;
}
.bg-red.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 205, 210, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 205, 210, .6);
}

.btn-red.btn-lighten-4
{
    border-color: #d32f2f !important;
    background-color: #ffcdd2 !important;
}
.btn-red.btn-lighten-4:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-4:focus,
.btn-red.btn-lighten-4:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-4
{
    color: #ffcdd2 !important; 
    border-color: #ffcdd2 !important;
}
.btn-outline-red.btn-outline-lighten-4:hover
{
    background-color: #ffcdd2 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffcdd2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffcdd2 !important;
}

.border-red.border-lighten-4
{
    border: 1px solid #ffcdd2 !important;
}

.border-top-red.border-top-lighten-4
{
    border-top: 1px solid #ffcdd2 !important;
}

.border-bottom-red.border-bottom-lighten-4
{
    border-bottom: 1px solid #ffcdd2 !important;
}

.border-left-red.border-left-lighten-4
{
    border-left: 1px solid #ffcdd2 !important;
}

.border-right-red.border-right-lighten-4
{
    border-right: 1px solid #ffcdd2 !important;
}

.overlay-red.overlay-lighten-4
{
    background: #ffcdd2;
    /* The Fallback */
    background: rgba(255, 205, 210, .8);
}

.red.lighten-3
{
    color: #ef9a9a !important;
}

.bg-red.bg-lighten-3
{
    background-color: #ef9a9a !important;
}
.bg-red.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(239, 154, 154, .6);
            box-shadow: 0 1px 20px 1px rgba(239, 154, 154, .6);
}

.btn-red.btn-lighten-3
{
    border-color: #d32f2f !important;
    background-color: #ef9a9a !important;
}
.btn-red.btn-lighten-3:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-3:focus,
.btn-red.btn-lighten-3:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-3
{
    color: #ef9a9a !important; 
    border-color: #ef9a9a !important;
}
.btn-outline-red.btn-outline-lighten-3:hover
{
    background-color: #ef9a9a !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef9a9a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef9a9a !important;
}

.border-red.border-lighten-3
{
    border: 1px solid #ef9a9a !important;
}

.border-top-red.border-top-lighten-3
{
    border-top: 1px solid #ef9a9a !important;
}

.border-bottom-red.border-bottom-lighten-3
{
    border-bottom: 1px solid #ef9a9a !important;
}

.border-left-red.border-left-lighten-3
{
    border-left: 1px solid #ef9a9a !important;
}

.border-right-red.border-right-lighten-3
{
    border-right: 1px solid #ef9a9a !important;
}

.overlay-red.overlay-lighten-3
{
    background: #ef9a9a;
    /* The Fallback */
    background: rgba(239, 154, 154, .8);
}

.red.lighten-2
{
    color: #e57373 !important;
}

.bg-red.bg-lighten-2
{
    background-color: #e57373 !important;
}
.bg-red.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(229, 115, 115, .6);
            box-shadow: 0 1px 20px 1px rgba(229, 115, 115, .6);
}

.btn-red.btn-lighten-2
{
    border-color: #d32f2f !important;
    background-color: #e57373 !important;
}
.btn-red.btn-lighten-2:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-2:focus,
.btn-red.btn-lighten-2:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-2
{
    color: #e57373 !important; 
    border-color: #e57373 !important;
}
.btn-outline-red.btn-outline-lighten-2:hover
{
    background-color: #e57373 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e57373 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e57373 !important;
}

.border-red.border-lighten-2
{
    border: 1px solid #e57373 !important;
}

.border-top-red.border-top-lighten-2
{
    border-top: 1px solid #e57373 !important;
}

.border-bottom-red.border-bottom-lighten-2
{
    border-bottom: 1px solid #e57373 !important;
}

.border-left-red.border-left-lighten-2
{
    border-left: 1px solid #e57373 !important;
}

.border-right-red.border-right-lighten-2
{
    border-right: 1px solid #e57373 !important;
}

.overlay-red.overlay-lighten-2
{
    background: #e57373;
    /* The Fallback */
    background: rgba(229, 115, 115, .8);
}

.red.lighten-1
{
    color: #ef5350 !important;
}

.bg-red.bg-lighten-1
{
    background-color: #ef5350 !important;
}
.bg-red.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(239, 83, 80, .6);
            box-shadow: 0 1px 20px 1px rgba(239, 83, 80, .6);
}

.btn-red.btn-lighten-1
{
    border-color: #d32f2f !important;
    background-color: #ef5350 !important;
}
.btn-red.btn-lighten-1:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-1:focus,
.btn-red.btn-lighten-1:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-lighten-1
{
    color: #ef5350 !important; 
    border-color: #ef5350 !important;
}
.btn-outline-red.btn-outline-lighten-1:hover
{
    background-color: #ef5350 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef5350 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ef5350 !important;
}

.border-red.border-lighten-1
{
    border: 1px solid #ef5350 !important;
}

.border-top-red.border-top-lighten-1
{
    border-top: 1px solid #ef5350 !important;
}

.border-bottom-red.border-bottom-lighten-1
{
    border-bottom: 1px solid #ef5350 !important;
}

.border-left-red.border-left-lighten-1
{
    border-left: 1px solid #ef5350 !important;
}

.border-right-red.border-right-lighten-1
{
    border-right: 1px solid #ef5350 !important;
}

.overlay-red.overlay-lighten-1
{
    background: #ef5350;
    /* The Fallback */
    background: rgba(239, 83, 80, .8);
}

.red
{
    color: #f44336 !important;
}

.bg-red
{
    background-color: #f44336 !important;
}
.bg-red .card-header,
.bg-red .card-footer
{
    background-color: transparent;
}
.bg-red.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
}

.selectboxit.selectboxit-btn.bg-red
{
    background-color: #f44336 !important;
}

.alert-red
{
    color: #710e06 !important; 
    border-color: #f44336 !important;
    background-color: #f8827a !important;
}
.alert-red .alert-link
{
    color: #4b0904 !important;
}

.border-red
{
    border-color: #f44336;
}

.overlay-red
{
    background: #f44336;
    /* The Fallback */
    background: rgba(244, 67, 54, .8);
}

/* .card-outline-red{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-red
{
    color: #fff; 
    border-color: #d32f2f !important;
    background-color: #f44336 !important;
}
.btn-red:hover
{
    color: #fff !important; 
    border-color: #e53935 !important;
    background-color: #ef5350 !important;
}
.btn-red:focus,
.btn-red:active
{
    color: #fff !important; 
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
}

.btn-outline-red
{
    color: #f44336; 
    border-color: #f44336;
    background-color: transparent;
}
.btn-outline-red:hover
{
    color: #fff !important; 
    background-color: #f44336;
}
.btn-outline-red.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 67, 54, .6);
}

input[type='checkbox'].bg-red + .custom-control-label:before,
input[type='radio'].bg-red + .custom-control-label:before
{
    background-color: #f44336 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f44336 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f44336 !important;
}

.border-red
{
    border: 1px solid #f44336 !important;
}

.border-top-red
{
    border-top: 1px solid #f44336;
}

.border-bottom-red
{
    border-bottom: 1px solid #f44336;
}

.border-left-red
{
    border-left: 1px solid #f44336;
}

.border-right-red
{
    border-right: 1px solid #f44336;
}

.red.darken-1
{
    color: #e53935 !important;
}

.bg-red.bg-darken-1
{
    background-color: #e53935 !important;
}
.bg-red.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(229, 57, 53, .6);
            box-shadow: 0 1px 20px 1px rgba(229, 57, 53, .6);
}

.btn-red.btn-darken-1
{
    border-color: #d32f2f !important;
    background-color: #e53935 !important;
}
.btn-red.btn-darken-1:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-1:focus,
.btn-red.btn-darken-1:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-darken-1
{
    color: #e53935 !important; 
    border-color: #e53935 !important;
}
.btn-outline-red.btn-outline-darken-1:hover
{
    background-color: #e53935 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e53935 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e53935 !important;
}

.border-red.border-darken-1
{
    border: 1px solid #e53935 !important;
}

.border-top-red.border-top-darken-1
{
    border-top: 1px solid #e53935 !important;
}

.border-bottom-red.border-bottom-darken-1
{
    border-bottom: 1px solid #e53935 !important;
}

.border-left-red.border-left-darken-1
{
    border-left: 1px solid #e53935 !important;
}

.border-right-red.border-right-darken-1
{
    border-right: 1px solid #e53935 !important;
}

.overlay-red.overlay-darken-1
{
    background: #e53935;
    /* The Fallback */
    background: rgba(229, 57, 53, .8);
}

.red.darken-2
{
    color: #d32f2f !important;
}

.bg-red.bg-darken-2
{
    background-color: #d32f2f !important;
}
.bg-red.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(211, 47, 47, .6);
            box-shadow: 0 1px 20px 1px rgba(211, 47, 47, .6);
}

.btn-red.btn-darken-2
{
    border-color: #d32f2f !important;
    background-color: #d32f2f !important;
}
.btn-red.btn-darken-2:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-2:focus,
.btn-red.btn-darken-2:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-darken-2
{
    color: #d32f2f !important; 
    border-color: #d32f2f !important;
}
.btn-outline-red.btn-outline-darken-2:hover
{
    background-color: #d32f2f !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d32f2f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d32f2f !important;
}

.border-red.border-darken-2
{
    border: 1px solid #d32f2f !important;
}

.border-top-red.border-top-darken-2
{
    border-top: 1px solid #d32f2f !important;
}

.border-bottom-red.border-bottom-darken-2
{
    border-bottom: 1px solid #d32f2f !important;
}

.border-left-red.border-left-darken-2
{
    border-left: 1px solid #d32f2f !important;
}

.border-right-red.border-right-darken-2
{
    border-right: 1px solid #d32f2f !important;
}

.overlay-red.overlay-darken-2
{
    background: #d32f2f;
    /* The Fallback */
    background: rgba(211, 47, 47, .8);
}

.red.darken-3
{
    color: #c62828 !important;
}

.bg-red.bg-darken-3
{
    background-color: #c62828 !important;
}
.bg-red.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(198, 40, 40, .6);
            box-shadow: 0 1px 20px 1px rgba(198, 40, 40, .6);
}

.btn-red.btn-darken-3
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-3:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-3:focus,
.btn-red.btn-darken-3:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-darken-3
{
    color: #c62828 !important; 
    border-color: #c62828 !important;
}
.btn-outline-red.btn-outline-darken-3:hover
{
    background-color: #c62828 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c62828 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c62828 !important;
}

.border-red.border-darken-3
{
    border: 1px solid #c62828 !important;
}

.border-top-red.border-top-darken-3
{
    border-top: 1px solid #c62828 !important;
}

.border-bottom-red.border-bottom-darken-3
{
    border-bottom: 1px solid #c62828 !important;
}

.border-left-red.border-left-darken-3
{
    border-left: 1px solid #c62828 !important;
}

.border-right-red.border-right-darken-3
{
    border-right: 1px solid #c62828 !important;
}

.overlay-red.overlay-darken-3
{
    background: #c62828;
    /* The Fallback */
    background: rgba(198, 40, 40, .8);
}

.red.darken-4
{
    color: #b71c1c !important;
}

.bg-red.bg-darken-4
{
    background-color: #b71c1c !important;
}
.bg-red.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(183, 28, 28, .6);
            box-shadow: 0 1px 20px 1px rgba(183, 28, 28, .6);
}

.btn-red.btn-darken-4
{
    border-color: #d32f2f !important;
    background-color: #b71c1c !important;
}
.btn-red.btn-darken-4:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-4:focus,
.btn-red.btn-darken-4:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-darken-4
{
    color: #b71c1c !important; 
    border-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-darken-4:hover
{
    background-color: #b71c1c !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b71c1c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b71c1c !important;
}

.border-red.border-darken-4
{
    border: 1px solid #b71c1c !important;
}

.border-top-red.border-top-darken-4
{
    border-top: 1px solid #b71c1c !important;
}

.border-bottom-red.border-bottom-darken-4
{
    border-bottom: 1px solid #b71c1c !important;
}

.border-left-red.border-left-darken-4
{
    border-left: 1px solid #b71c1c !important;
}

.border-right-red.border-right-darken-4
{
    border-right: 1px solid #b71c1c !important;
}

.overlay-red.overlay-darken-4
{
    background: #b71c1c;
    /* The Fallback */
    background: rgba(183, 28, 28, .8);
}

.red.accent-1
{
    color: #ff8a80 !important;
}

.bg-red.bg-accent-1
{
    background-color: #ff8a80 !important;
}
.bg-red.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 138, 128, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 138, 128, .6);
}

.btn-red.btn-accent-1
{
    border-color: #d32f2f !important;
    background-color: #ff8a80 !important;
}
.btn-red.btn-accent-1:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-accent-1:focus,
.btn-red.btn-accent-1:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-accent-1
{
    color: #ff8a80 !important; 
    border-color: #ff8a80 !important;
}
.btn-outline-red.btn-outline-accent-1:hover
{
    background-color: #ff8a80 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8a80 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8a80 !important;
}

.border-red.border-accent-1
{
    border: 1px solid #ff8a80 !important;
}

.border-top-red.border-top-accent-1
{
    border-top: 1px solid #ff8a80 !important;
}

.border-bottom-red.border-bottom-accent-1
{
    border-bottom: 1px solid #ff8a80 !important;
}

.border-left-red.border-left-accent-1
{
    border-left: 1px solid #ff8a80 !important;
}

.border-right-red.border-right-accent-1
{
    border-right: 1px solid #ff8a80 !important;
}

.overlay-red.overlay-accent-1
{
    background: #ff8a80;
    /* The Fallback */
    background: rgba(255, 138, 128, .8);
}

.red.accent-2
{
    color: #ff5252 !important;
}

.bg-red.bg-accent-2
{
    background-color: #ff5252 !important;
}
.bg-red.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 82, 82, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 82, 82, .6);
}

.btn-red.btn-accent-2
{
    border-color: #d32f2f !important;
    background-color: #ff5252 !important;
}
.btn-red.btn-accent-2:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-accent-2:focus,
.btn-red.btn-accent-2:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-accent-2
{
    color: #ff5252 !important; 
    border-color: #ff5252 !important;
}
.btn-outline-red.btn-outline-accent-2:hover
{
    background-color: #ff5252 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff5252 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff5252 !important;
}

.border-red.border-accent-2
{
    border: 1px solid #ff5252 !important;
}

.border-top-red.border-top-accent-2
{
    border-top: 1px solid #ff5252 !important;
}

.border-bottom-red.border-bottom-accent-2
{
    border-bottom: 1px solid #ff5252 !important;
}

.border-left-red.border-left-accent-2
{
    border-left: 1px solid #ff5252 !important;
}

.border-right-red.border-right-accent-2
{
    border-right: 1px solid #ff5252 !important;
}

.overlay-red.overlay-accent-2
{
    background: #ff5252;
    /* The Fallback */
    background: rgba(255, 82, 82, .8);
}

.red.accent-3
{
    color: #ff1744 !important;
}

.bg-red.bg-accent-3
{
    background-color: #ff1744 !important;
}
.bg-red.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 23, 68, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 23, 68, .6);
}

.btn-red.btn-accent-3
{
    border-color: #d32f2f !important;
    background-color: #ff1744 !important;
}
.btn-red.btn-accent-3:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-accent-3:focus,
.btn-red.btn-accent-3:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-accent-3
{
    color: #ff1744 !important; 
    border-color: #ff1744 !important;
}
.btn-outline-red.btn-outline-accent-3:hover
{
    background-color: #ff1744 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff1744 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff1744 !important;
}

.border-red.border-accent-3
{
    border: 1px solid #ff1744 !important;
}

.border-top-red.border-top-accent-3
{
    border-top: 1px solid #ff1744 !important;
}

.border-bottom-red.border-bottom-accent-3
{
    border-bottom: 1px solid #ff1744 !important;
}

.border-left-red.border-left-accent-3
{
    border-left: 1px solid #ff1744 !important;
}

.border-right-red.border-right-accent-3
{
    border-right: 1px solid #ff1744 !important;
}

.overlay-red.overlay-accent-3
{
    background: #ff1744;
    /* The Fallback */
    background: rgba(255, 23, 68, .8);
}

.red.accent-4
{
    color: #d50000 !important;
}

.bg-red.bg-accent-4
{
    background-color: #d50000 !important;
}
.bg-red.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(213, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(213, 0, 0, .6);
}

.btn-red.btn-accent-4
{
    border-color: #d32f2f !important;
    background-color: #d50000 !important;
}
.btn-red.btn-accent-4:hover
{
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-accent-4:focus,
.btn-red.btn-accent-4:active
{
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}

.btn-outline-red.btn-outline-accent-4
{
    color: #d50000 !important; 
    border-color: #d50000 !important;
}
.btn-outline-red.btn-outline-accent-4:hover
{
    background-color: #d50000 !important;
}

input:focus ~ .bg-red
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d50000 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d50000 !important;
}

.border-red.border-accent-4
{
    border: 1px solid #d50000 !important;
}

.border-top-red.border-top-accent-4
{
    border-top: 1px solid #d50000 !important;
}

.border-bottom-red.border-bottom-accent-4
{
    border-bottom: 1px solid #d50000 !important;
}

.border-left-red.border-left-accent-4
{
    border-left: 1px solid #d50000 !important;
}

.border-right-red.border-right-accent-4
{
    border-right: 1px solid #d50000 !important;
}

.overlay-red.overlay-accent-4
{
    background: #d50000;
    /* The Fallback */
    background: rgba(213, 0, 0, .8);
}

.pink.lighten-5
{
    color: #fce4ec !important;
}

.bg-pink.bg-lighten-5
{
    background-color: #fce4ec !important;
}
.bg-pink.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(252, 228, 236, .6);
            box-shadow: 0 1px 20px 1px rgba(252, 228, 236, .6);
}

.btn-pink.btn-lighten-5
{
    border-color: #c2185b !important;
    background-color: #fce4ec !important;
}
.btn-pink.btn-lighten-5:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-5:focus,
.btn-pink.btn-lighten-5:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-5
{
    color: #fce4ec !important; 
    border-color: #fce4ec !important;
}
.btn-outline-pink.btn-outline-lighten-5:hover
{
    background-color: #fce4ec !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fce4ec !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fce4ec !important;
}

.border-pink.border-lighten-5
{
    border: 1px solid #fce4ec !important;
}

.border-top-pink.border-top-lighten-5
{
    border-top: 1px solid #fce4ec !important;
}

.border-bottom-pink.border-bottom-lighten-5
{
    border-bottom: 1px solid #fce4ec !important;
}

.border-left-pink.border-left-lighten-5
{
    border-left: 1px solid #fce4ec !important;
}

.border-right-pink.border-right-lighten-5
{
    border-right: 1px solid #fce4ec !important;
}

.overlay-pink.overlay-lighten-5
{
    background: #fce4ec;
    /* The Fallback */
    background: rgba(252, 228, 236, .8);
}

.pink.lighten-4
{
    color: #f8bbd0 !important;
}

.bg-pink.bg-lighten-4
{
    background-color: #f8bbd0 !important;
}
.bg-pink.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(248, 187, 208, .6);
            box-shadow: 0 1px 20px 1px rgba(248, 187, 208, .6);
}

.btn-pink.btn-lighten-4
{
    border-color: #c2185b !important;
    background-color: #f8bbd0 !important;
}
.btn-pink.btn-lighten-4:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-4:focus,
.btn-pink.btn-lighten-4:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-4
{
    color: #f8bbd0 !important; 
    border-color: #f8bbd0 !important;
}
.btn-outline-pink.btn-outline-lighten-4:hover
{
    background-color: #f8bbd0 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f8bbd0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f8bbd0 !important;
}

.border-pink.border-lighten-4
{
    border: 1px solid #f8bbd0 !important;
}

.border-top-pink.border-top-lighten-4
{
    border-top: 1px solid #f8bbd0 !important;
}

.border-bottom-pink.border-bottom-lighten-4
{
    border-bottom: 1px solid #f8bbd0 !important;
}

.border-left-pink.border-left-lighten-4
{
    border-left: 1px solid #f8bbd0 !important;
}

.border-right-pink.border-right-lighten-4
{
    border-right: 1px solid #f8bbd0 !important;
}

.overlay-pink.overlay-lighten-4
{
    background: #f8bbd0;
    /* The Fallback */
    background: rgba(248, 187, 208, .8);
}

.pink.lighten-3
{
    color: #f48fb1 !important;
}

.bg-pink.bg-lighten-3
{
    background-color: #f48fb1 !important;
}
.bg-pink.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(244, 143, 177, .6);
            box-shadow: 0 1px 20px 1px rgba(244, 143, 177, .6);
}

.btn-pink.btn-lighten-3
{
    border-color: #c2185b !important;
    background-color: #f48fb1 !important;
}
.btn-pink.btn-lighten-3:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-3:focus,
.btn-pink.btn-lighten-3:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-3
{
    color: #f48fb1 !important; 
    border-color: #f48fb1 !important;
}
.btn-outline-pink.btn-outline-lighten-3:hover
{
    background-color: #f48fb1 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f48fb1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f48fb1 !important;
}

.border-pink.border-lighten-3
{
    border: 1px solid #f48fb1 !important;
}

.border-top-pink.border-top-lighten-3
{
    border-top: 1px solid #f48fb1 !important;
}

.border-bottom-pink.border-bottom-lighten-3
{
    border-bottom: 1px solid #f48fb1 !important;
}

.border-left-pink.border-left-lighten-3
{
    border-left: 1px solid #f48fb1 !important;
}

.border-right-pink.border-right-lighten-3
{
    border-right: 1px solid #f48fb1 !important;
}

.overlay-pink.overlay-lighten-3
{
    background: #f48fb1;
    /* The Fallback */
    background: rgba(244, 143, 177, .8);
}

.pink.lighten-2
{
    color: #f06292 !important;
}

.bg-pink.bg-lighten-2
{
    background-color: #f06292 !important;
}
.bg-pink.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(240, 98, 146, .6);
            box-shadow: 0 1px 20px 1px rgba(240, 98, 146, .6);
}

.btn-pink.btn-lighten-2
{
    border-color: #c2185b !important;
    background-color: #f06292 !important;
}
.btn-pink.btn-lighten-2:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-2:focus,
.btn-pink.btn-lighten-2:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-2
{
    color: #f06292 !important; 
    border-color: #f06292 !important;
}
.btn-outline-pink.btn-outline-lighten-2:hover
{
    background-color: #f06292 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f06292 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f06292 !important;
}

.border-pink.border-lighten-2
{
    border: 1px solid #f06292 !important;
}

.border-top-pink.border-top-lighten-2
{
    border-top: 1px solid #f06292 !important;
}

.border-bottom-pink.border-bottom-lighten-2
{
    border-bottom: 1px solid #f06292 !important;
}

.border-left-pink.border-left-lighten-2
{
    border-left: 1px solid #f06292 !important;
}

.border-right-pink.border-right-lighten-2
{
    border-right: 1px solid #f06292 !important;
}

.overlay-pink.overlay-lighten-2
{
    background: #f06292;
    /* The Fallback */
    background: rgba(240, 98, 146, .8);
}

.pink.lighten-1
{
    color: #ec407a !important;
}

.bg-pink.bg-lighten-1
{
    background-color: #ec407a !important;
}
.bg-pink.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(236, 64, 122, .6);
            box-shadow: 0 1px 20px 1px rgba(236, 64, 122, .6);
}

.btn-pink.btn-lighten-1
{
    border-color: #c2185b !important;
    background-color: #ec407a !important;
}
.btn-pink.btn-lighten-1:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-1:focus,
.btn-pink.btn-lighten-1:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-lighten-1
{
    color: #ec407a !important; 
    border-color: #ec407a !important;
}
.btn-outline-pink.btn-outline-lighten-1:hover
{
    background-color: #ec407a !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ec407a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ec407a !important;
}

.border-pink.border-lighten-1
{
    border: 1px solid #ec407a !important;
}

.border-top-pink.border-top-lighten-1
{
    border-top: 1px solid #ec407a !important;
}

.border-bottom-pink.border-bottom-lighten-1
{
    border-bottom: 1px solid #ec407a !important;
}

.border-left-pink.border-left-lighten-1
{
    border-left: 1px solid #ec407a !important;
}

.border-right-pink.border-right-lighten-1
{
    border-right: 1px solid #ec407a !important;
}

.overlay-pink.overlay-lighten-1
{
    background: #ec407a;
    /* The Fallback */
    background: rgba(236, 64, 122, .8);
}

.pink
{
    color: #e91e63 !important;
}

.bg-pink
{
    background-color: #e91e63 !important;
}
.bg-pink .card-header,
.bg-pink .card-footer
{
    background-color: transparent;
}
.bg-pink.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
            box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
}

.selectboxit.selectboxit-btn.bg-pink
{
    background-color: #e91e63 !important;
}

.alert-pink
{
    color: #4d081f !important; 
    border-color: #e91e63 !important;
    background-color: #ef5f90 !important;
}
.alert-pink .alert-link
{
    color: #280410 !important;
}

.border-pink
{
    border-color: #e91e63;
}

.overlay-pink
{
    background: #e91e63;
    /* The Fallback */
    background: rgba(233, 30, 99, .8);
}

/* .card-outline-pink{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-pink
{
    color: #fff; 
    border-color: #c2185b !important;
    background-color: #e91e63 !important;
}
.btn-pink:hover
{
    color: #fff !important; 
    border-color: #d81b60 !important;
    background-color: #ec407a !important;
}
.btn-pink:focus,
.btn-pink:active
{
    color: #fff !important; 
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
            box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
}

.btn-outline-pink
{
    color: #e91e63; 
    border-color: #e91e63;
    background-color: transparent;
}
.btn-outline-pink:hover
{
    color: #fff !important; 
    background-color: #e91e63;
}
.btn-outline-pink.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
            box-shadow: 0 1px 20px 1px rgba(233, 30, 99, .6);
}

input[type='checkbox'].bg-pink + .custom-control-label:before,
input[type='radio'].bg-pink + .custom-control-label:before
{
    background-color: #e91e63 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e91e63 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e91e63 !important;
}

.border-pink
{
    border: 1px solid #e91e63 !important;
}

.border-top-pink
{
    border-top: 1px solid #e91e63;
}

.border-bottom-pink
{
    border-bottom: 1px solid #e91e63;
}

.border-left-pink
{
    border-left: 1px solid #e91e63;
}

.border-right-pink
{
    border-right: 1px solid #e91e63;
}

.pink.darken-1
{
    color: #d81b60 !important;
}

.bg-pink.bg-darken-1
{
    background-color: #d81b60 !important;
}
.bg-pink.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(216, 27, 96, .6);
            box-shadow: 0 1px 20px 1px rgba(216, 27, 96, .6);
}

.btn-pink.btn-darken-1
{
    border-color: #c2185b !important;
    background-color: #d81b60 !important;
}
.btn-pink.btn-darken-1:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-1:focus,
.btn-pink.btn-darken-1:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-darken-1
{
    color: #d81b60 !important; 
    border-color: #d81b60 !important;
}
.btn-outline-pink.btn-outline-darken-1:hover
{
    background-color: #d81b60 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d81b60 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d81b60 !important;
}

.border-pink.border-darken-1
{
    border: 1px solid #d81b60 !important;
}

.border-top-pink.border-top-darken-1
{
    border-top: 1px solid #d81b60 !important;
}

.border-bottom-pink.border-bottom-darken-1
{
    border-bottom: 1px solid #d81b60 !important;
}

.border-left-pink.border-left-darken-1
{
    border-left: 1px solid #d81b60 !important;
}

.border-right-pink.border-right-darken-1
{
    border-right: 1px solid #d81b60 !important;
}

.overlay-pink.overlay-darken-1
{
    background: #d81b60;
    /* The Fallback */
    background: rgba(216, 27, 96, .8);
}

.pink.darken-2
{
    color: #c2185b !important;
}

.bg-pink.bg-darken-2
{
    background-color: #c2185b !important;
}
.bg-pink.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(194, 24, 91, .6);
            box-shadow: 0 1px 20px 1px rgba(194, 24, 91, .6);
}

.btn-pink.btn-darken-2
{
    border-color: #c2185b !important;
    background-color: #c2185b !important;
}
.btn-pink.btn-darken-2:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-2:focus,
.btn-pink.btn-darken-2:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-darken-2
{
    color: #c2185b !important; 
    border-color: #c2185b !important;
}
.btn-outline-pink.btn-outline-darken-2:hover
{
    background-color: #c2185b !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c2185b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c2185b !important;
}

.border-pink.border-darken-2
{
    border: 1px solid #c2185b !important;
}

.border-top-pink.border-top-darken-2
{
    border-top: 1px solid #c2185b !important;
}

.border-bottom-pink.border-bottom-darken-2
{
    border-bottom: 1px solid #c2185b !important;
}

.border-left-pink.border-left-darken-2
{
    border-left: 1px solid #c2185b !important;
}

.border-right-pink.border-right-darken-2
{
    border-right: 1px solid #c2185b !important;
}

.overlay-pink.overlay-darken-2
{
    background: #c2185b;
    /* The Fallback */
    background: rgba(194, 24, 91, .8);
}

.pink.darken-3
{
    color: #ad1457 !important;
}

.bg-pink.bg-darken-3
{
    background-color: #ad1457 !important;
}
.bg-pink.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(173, 20, 87, .6);
            box-shadow: 0 1px 20px 1px rgba(173, 20, 87, .6);
}

.btn-pink.btn-darken-3
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-3:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-3:focus,
.btn-pink.btn-darken-3:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-darken-3
{
    color: #ad1457 !important; 
    border-color: #ad1457 !important;
}
.btn-outline-pink.btn-outline-darken-3:hover
{
    background-color: #ad1457 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ad1457 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ad1457 !important;
}

.border-pink.border-darken-3
{
    border: 1px solid #ad1457 !important;
}

.border-top-pink.border-top-darken-3
{
    border-top: 1px solid #ad1457 !important;
}

.border-bottom-pink.border-bottom-darken-3
{
    border-bottom: 1px solid #ad1457 !important;
}

.border-left-pink.border-left-darken-3
{
    border-left: 1px solid #ad1457 !important;
}

.border-right-pink.border-right-darken-3
{
    border-right: 1px solid #ad1457 !important;
}

.overlay-pink.overlay-darken-3
{
    background: #ad1457;
    /* The Fallback */
    background: rgba(173, 20, 87, .8);
}

.pink.darken-4
{
    color: #880e4f !important;
}

.bg-pink.bg-darken-4
{
    background-color: #880e4f !important;
}
.bg-pink.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(136, 14, 79, .6);
            box-shadow: 0 1px 20px 1px rgba(136, 14, 79, .6);
}

.btn-pink.btn-darken-4
{
    border-color: #c2185b !important;
    background-color: #880e4f !important;
}
.btn-pink.btn-darken-4:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-4:focus,
.btn-pink.btn-darken-4:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-darken-4
{
    color: #880e4f !important; 
    border-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-darken-4:hover
{
    background-color: #880e4f !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #880e4f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #880e4f !important;
}

.border-pink.border-darken-4
{
    border: 1px solid #880e4f !important;
}

.border-top-pink.border-top-darken-4
{
    border-top: 1px solid #880e4f !important;
}

.border-bottom-pink.border-bottom-darken-4
{
    border-bottom: 1px solid #880e4f !important;
}

.border-left-pink.border-left-darken-4
{
    border-left: 1px solid #880e4f !important;
}

.border-right-pink.border-right-darken-4
{
    border-right: 1px solid #880e4f !important;
}

.overlay-pink.overlay-darken-4
{
    background: #880e4f;
    /* The Fallback */
    background: rgba(136, 14, 79, .8);
}

.pink.accent-1
{
    color: #ff80ab !important;
}

.bg-pink.bg-accent-1
{
    background-color: #ff80ab !important;
}
.bg-pink.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 128, 171, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 128, 171, .6);
}

.btn-pink.btn-accent-1
{
    border-color: #c2185b !important;
    background-color: #ff80ab !important;
}
.btn-pink.btn-accent-1:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-1:focus,
.btn-pink.btn-accent-1:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-accent-1
{
    color: #ff80ab !important; 
    border-color: #ff80ab !important;
}
.btn-outline-pink.btn-outline-accent-1:hover
{
    background-color: #ff80ab !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff80ab !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff80ab !important;
}

.border-pink.border-accent-1
{
    border: 1px solid #ff80ab !important;
}

.border-top-pink.border-top-accent-1
{
    border-top: 1px solid #ff80ab !important;
}

.border-bottom-pink.border-bottom-accent-1
{
    border-bottom: 1px solid #ff80ab !important;
}

.border-left-pink.border-left-accent-1
{
    border-left: 1px solid #ff80ab !important;
}

.border-right-pink.border-right-accent-1
{
    border-right: 1px solid #ff80ab !important;
}

.overlay-pink.overlay-accent-1
{
    background: #ff80ab;
    /* The Fallback */
    background: rgba(255, 128, 171, .8);
}

.pink.accent-2
{
    color: #ff4081 !important;
}

.bg-pink.bg-accent-2
{
    background-color: #ff4081 !important;
}
.bg-pink.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 64, 129, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 64, 129, .6);
}

.btn-pink.btn-accent-2
{
    border-color: #c2185b !important;
    background-color: #ff4081 !important;
}
.btn-pink.btn-accent-2:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-2:focus,
.btn-pink.btn-accent-2:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-accent-2
{
    color: #ff4081 !important; 
    border-color: #ff4081 !important;
}
.btn-outline-pink.btn-outline-accent-2:hover
{
    background-color: #ff4081 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff4081 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff4081 !important;
}

.border-pink.border-accent-2
{
    border: 1px solid #ff4081 !important;
}

.border-top-pink.border-top-accent-2
{
    border-top: 1px solid #ff4081 !important;
}

.border-bottom-pink.border-bottom-accent-2
{
    border-bottom: 1px solid #ff4081 !important;
}

.border-left-pink.border-left-accent-2
{
    border-left: 1px solid #ff4081 !important;
}

.border-right-pink.border-right-accent-2
{
    border-right: 1px solid #ff4081 !important;
}

.overlay-pink.overlay-accent-2
{
    background: #ff4081;
    /* The Fallback */
    background: rgba(255, 64, 129, .8);
}

.pink.accent-3
{
    color: #f50057 !important;
}

.bg-pink.bg-accent-3
{
    background-color: #f50057 !important;
}
.bg-pink.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(245, 0, 87, .6);
            box-shadow: 0 1px 20px 1px rgba(245, 0, 87, .6);
}

.btn-pink.btn-accent-3
{
    border-color: #c2185b !important;
    background-color: #f50057 !important;
}
.btn-pink.btn-accent-3:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-3:focus,
.btn-pink.btn-accent-3:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-accent-3
{
    color: #f50057 !important; 
    border-color: #f50057 !important;
}
.btn-outline-pink.btn-outline-accent-3:hover
{
    background-color: #f50057 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f50057 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f50057 !important;
}

.border-pink.border-accent-3
{
    border: 1px solid #f50057 !important;
}

.border-top-pink.border-top-accent-3
{
    border-top: 1px solid #f50057 !important;
}

.border-bottom-pink.border-bottom-accent-3
{
    border-bottom: 1px solid #f50057 !important;
}

.border-left-pink.border-left-accent-3
{
    border-left: 1px solid #f50057 !important;
}

.border-right-pink.border-right-accent-3
{
    border-right: 1px solid #f50057 !important;
}

.overlay-pink.overlay-accent-3
{
    background: #f50057;
    /* The Fallback */
    background: rgba(245, 0, 87, .8);
}

.pink.accent-4
{
    color: #c51162 !important;
}

.bg-pink.bg-accent-4
{
    background-color: #c51162 !important;
}
.bg-pink.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(197, 17, 98, .6);
            box-shadow: 0 1px 20px 1px rgba(197, 17, 98, .6);
}

.btn-pink.btn-accent-4
{
    border-color: #c2185b !important;
    background-color: #c51162 !important;
}
.btn-pink.btn-accent-4:hover
{
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-4:focus,
.btn-pink.btn-accent-4:active
{
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}

.btn-outline-pink.btn-outline-accent-4
{
    color: #c51162 !important; 
    border-color: #c51162 !important;
}
.btn-outline-pink.btn-outline-accent-4:hover
{
    background-color: #c51162 !important;
}

input:focus ~ .bg-pink
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c51162 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #c51162 !important;
}

.border-pink.border-accent-4
{
    border: 1px solid #c51162 !important;
}

.border-top-pink.border-top-accent-4
{
    border-top: 1px solid #c51162 !important;
}

.border-bottom-pink.border-bottom-accent-4
{
    border-bottom: 1px solid #c51162 !important;
}

.border-left-pink.border-left-accent-4
{
    border-left: 1px solid #c51162 !important;
}

.border-right-pink.border-right-accent-4
{
    border-right: 1px solid #c51162 !important;
}

.overlay-pink.overlay-accent-4
{
    background: #c51162;
    /* The Fallback */
    background: rgba(197, 17, 98, .8);
}

.purple.lighten-5
{
    color: #f3e5f5 !important;
}

.bg-purple.bg-lighten-5
{
    background-color: #f3e5f5 !important;
}
.bg-purple.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(243, 229, 245, .6);
            box-shadow: 0 1px 20px 1px rgba(243, 229, 245, .6);
}

.btn-purple.btn-lighten-5
{
    border-color: #7b1fa2 !important;
    background-color: #f3e5f5 !important;
}
.btn-purple.btn-lighten-5:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-5:focus,
.btn-purple.btn-lighten-5:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-5
{
    color: #f3e5f5 !important; 
    border-color: #f3e5f5 !important;
}
.btn-outline-purple.btn-outline-lighten-5:hover
{
    background-color: #f3e5f5 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f3e5f5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f3e5f5 !important;
}

.border-purple.border-lighten-5
{
    border: 1px solid #f3e5f5 !important;
}

.border-top-purple.border-top-lighten-5
{
    border-top: 1px solid #f3e5f5 !important;
}

.border-bottom-purple.border-bottom-lighten-5
{
    border-bottom: 1px solid #f3e5f5 !important;
}

.border-left-purple.border-left-lighten-5
{
    border-left: 1px solid #f3e5f5 !important;
}

.border-right-purple.border-right-lighten-5
{
    border-right: 1px solid #f3e5f5 !important;
}

.overlay-purple.overlay-lighten-5
{
    background: #f3e5f5;
    /* The Fallback */
    background: rgba(243, 229, 245, .8);
}

.purple.lighten-4
{
    color: #e1bee7 !important;
}

.bg-purple.bg-lighten-4
{
    background-color: #e1bee7 !important;
}
.bg-purple.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(225, 190, 231, .6);
            box-shadow: 0 1px 20px 1px rgba(225, 190, 231, .6);
}

.btn-purple.btn-lighten-4
{
    border-color: #7b1fa2 !important;
    background-color: #e1bee7 !important;
}
.btn-purple.btn-lighten-4:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-4:focus,
.btn-purple.btn-lighten-4:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-4
{
    color: #e1bee7 !important; 
    border-color: #e1bee7 !important;
}
.btn-outline-purple.btn-outline-lighten-4:hover
{
    background-color: #e1bee7 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e1bee7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e1bee7 !important;
}

.border-purple.border-lighten-4
{
    border: 1px solid #e1bee7 !important;
}

.border-top-purple.border-top-lighten-4
{
    border-top: 1px solid #e1bee7 !important;
}

.border-bottom-purple.border-bottom-lighten-4
{
    border-bottom: 1px solid #e1bee7 !important;
}

.border-left-purple.border-left-lighten-4
{
    border-left: 1px solid #e1bee7 !important;
}

.border-right-purple.border-right-lighten-4
{
    border-right: 1px solid #e1bee7 !important;
}

.overlay-purple.overlay-lighten-4
{
    background: #e1bee7;
    /* The Fallback */
    background: rgba(225, 190, 231, .8);
}

.purple.lighten-3
{
    color: #ce93d8 !important;
}

.bg-purple.bg-lighten-3
{
    background-color: #ce93d8 !important;
}
.bg-purple.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(206, 147, 216, .6);
            box-shadow: 0 1px 20px 1px rgba(206, 147, 216, .6);
}

.btn-purple.btn-lighten-3
{
    border-color: #7b1fa2 !important;
    background-color: #ce93d8 !important;
}
.btn-purple.btn-lighten-3:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-3:focus,
.btn-purple.btn-lighten-3:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-3
{
    color: #ce93d8 !important; 
    border-color: #ce93d8 !important;
}
.btn-outline-purple.btn-outline-lighten-3:hover
{
    background-color: #ce93d8 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ce93d8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ce93d8 !important;
}

.border-purple.border-lighten-3
{
    border: 1px solid #ce93d8 !important;
}

.border-top-purple.border-top-lighten-3
{
    border-top: 1px solid #ce93d8 !important;
}

.border-bottom-purple.border-bottom-lighten-3
{
    border-bottom: 1px solid #ce93d8 !important;
}

.border-left-purple.border-left-lighten-3
{
    border-left: 1px solid #ce93d8 !important;
}

.border-right-purple.border-right-lighten-3
{
    border-right: 1px solid #ce93d8 !important;
}

.overlay-purple.overlay-lighten-3
{
    background: #ce93d8;
    /* The Fallback */
    background: rgba(206, 147, 216, .8);
}

.purple.lighten-2
{
    color: #ba68c8 !important;
}

.bg-purple.bg-lighten-2
{
    background-color: #ba68c8 !important;
}
.bg-purple.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(186, 104, 200, .6);
            box-shadow: 0 1px 20px 1px rgba(186, 104, 200, .6);
}

.btn-purple.btn-lighten-2
{
    border-color: #7b1fa2 !important;
    background-color: #ba68c8 !important;
}
.btn-purple.btn-lighten-2:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-2:focus,
.btn-purple.btn-lighten-2:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-2
{
    color: #ba68c8 !important; 
    border-color: #ba68c8 !important;
}
.btn-outline-purple.btn-outline-lighten-2:hover
{
    background-color: #ba68c8 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ba68c8 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ba68c8 !important;
}

.border-purple.border-lighten-2
{
    border: 1px solid #ba68c8 !important;
}

.border-top-purple.border-top-lighten-2
{
    border-top: 1px solid #ba68c8 !important;
}

.border-bottom-purple.border-bottom-lighten-2
{
    border-bottom: 1px solid #ba68c8 !important;
}

.border-left-purple.border-left-lighten-2
{
    border-left: 1px solid #ba68c8 !important;
}

.border-right-purple.border-right-lighten-2
{
    border-right: 1px solid #ba68c8 !important;
}

.overlay-purple.overlay-lighten-2
{
    background: #ba68c8;
    /* The Fallback */
    background: rgba(186, 104, 200, .8);
}

.purple.lighten-1
{
    color: #ab47bc !important;
}

.bg-purple.bg-lighten-1
{
    background-color: #ab47bc !important;
}
.bg-purple.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(171, 71, 188, .6);
            box-shadow: 0 1px 20px 1px rgba(171, 71, 188, .6);
}

.btn-purple.btn-lighten-1
{
    border-color: #7b1fa2 !important;
    background-color: #ab47bc !important;
}
.btn-purple.btn-lighten-1:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-1:focus,
.btn-purple.btn-lighten-1:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-lighten-1
{
    color: #ab47bc !important; 
    border-color: #ab47bc !important;
}
.btn-outline-purple.btn-outline-lighten-1:hover
{
    background-color: #ab47bc !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ab47bc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ab47bc !important;
}

.border-purple.border-lighten-1
{
    border: 1px solid #ab47bc !important;
}

.border-top-purple.border-top-lighten-1
{
    border-top: 1px solid #ab47bc !important;
}

.border-bottom-purple.border-bottom-lighten-1
{
    border-bottom: 1px solid #ab47bc !important;
}

.border-left-purple.border-left-lighten-1
{
    border-left: 1px solid #ab47bc !important;
}

.border-right-purple.border-right-lighten-1
{
    border-right: 1px solid #ab47bc !important;
}

.overlay-purple.overlay-lighten-1
{
    background: #ab47bc;
    /* The Fallback */
    background: rgba(171, 71, 188, .8);
}

.purple
{
    color: #9c27b0 !important;
}

.bg-purple
{
    background-color: #9c27b0 !important;
}
.bg-purple .card-header,
.bg-purple .card-footer
{
    background-color: transparent;
}
.bg-purple.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
            box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
}

.selectboxit.selectboxit-btn.bg-purple
{
    background-color: #9c27b0 !important;
}

.alert-purple
{
    color: #1a071e !important; 
    border-color: #9c27b0 !important;
    background-color: #c248d6 !important;
}
.alert-purple .alert-link
{
    color: black !important;
}

.border-purple
{
    border-color: #9c27b0;
}

.overlay-purple
{
    background: #9c27b0;
    /* The Fallback */
    background: rgba(156, 39, 176, .8);
}

/* .card-outline-purple{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-purple
{
    color: #fff; 
    border-color: #7b1fa2 !important;
    background-color: #9c27b0 !important;
}
.btn-purple:hover
{
    color: #fff !important; 
    border-color: #8e24aa !important;
    background-color: #ab47bc !important;
}
.btn-purple:focus,
.btn-purple:active
{
    color: #fff !important; 
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
            box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
}

.btn-outline-purple
{
    color: #9c27b0; 
    border-color: #9c27b0;
    background-color: transparent;
}
.btn-outline-purple:hover
{
    color: #fff !important; 
    background-color: #9c27b0;
}
.btn-outline-purple.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
            box-shadow: 0 1px 20px 1px rgba(156, 39, 176, .6);
}

input[type='checkbox'].bg-purple + .custom-control-label:before,
input[type='radio'].bg-purple + .custom-control-label:before
{
    background-color: #9c27b0 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9c27b0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #9c27b0 !important;
}

.border-purple
{
    border: 1px solid #9c27b0 !important;
}

.border-top-purple
{
    border-top: 1px solid #9c27b0;
}

.border-bottom-purple
{
    border-bottom: 1px solid #9c27b0;
}

.border-left-purple
{
    border-left: 1px solid #9c27b0;
}

.border-right-purple
{
    border-right: 1px solid #9c27b0;
}

.purple.darken-1
{
    color: #8e24aa !important;
}

.bg-purple.bg-darken-1
{
    background-color: #8e24aa !important;
}
.bg-purple.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(142, 36, 170, .6);
            box-shadow: 0 1px 20px 1px rgba(142, 36, 170, .6);
}

.btn-purple.btn-darken-1
{
    border-color: #7b1fa2 !important;
    background-color: #8e24aa !important;
}
.btn-purple.btn-darken-1:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-1:focus,
.btn-purple.btn-darken-1:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-darken-1
{
    color: #8e24aa !important; 
    border-color: #8e24aa !important;
}
.btn-outline-purple.btn-outline-darken-1:hover
{
    background-color: #8e24aa !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8e24aa !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #8e24aa !important;
}

.border-purple.border-darken-1
{
    border: 1px solid #8e24aa !important;
}

.border-top-purple.border-top-darken-1
{
    border-top: 1px solid #8e24aa !important;
}

.border-bottom-purple.border-bottom-darken-1
{
    border-bottom: 1px solid #8e24aa !important;
}

.border-left-purple.border-left-darken-1
{
    border-left: 1px solid #8e24aa !important;
}

.border-right-purple.border-right-darken-1
{
    border-right: 1px solid #8e24aa !important;
}

.overlay-purple.overlay-darken-1
{
    background: #8e24aa;
    /* The Fallback */
    background: rgba(142, 36, 170, .8);
}

.purple.darken-2
{
    color: #7b1fa2 !important;
}

.bg-purple.bg-darken-2
{
    background-color: #7b1fa2 !important;
}
.bg-purple.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(123, 31, 162, .6);
            box-shadow: 0 1px 20px 1px rgba(123, 31, 162, .6);
}

.btn-purple.btn-darken-2
{
    border-color: #7b1fa2 !important;
    background-color: #7b1fa2 !important;
}
.btn-purple.btn-darken-2:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-2:focus,
.btn-purple.btn-darken-2:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-darken-2
{
    color: #7b1fa2 !important; 
    border-color: #7b1fa2 !important;
}
.btn-outline-purple.btn-outline-darken-2:hover
{
    background-color: #7b1fa2 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7b1fa2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7b1fa2 !important;
}

.border-purple.border-darken-2
{
    border: 1px solid #7b1fa2 !important;
}

.border-top-purple.border-top-darken-2
{
    border-top: 1px solid #7b1fa2 !important;
}

.border-bottom-purple.border-bottom-darken-2
{
    border-bottom: 1px solid #7b1fa2 !important;
}

.border-left-purple.border-left-darken-2
{
    border-left: 1px solid #7b1fa2 !important;
}

.border-right-purple.border-right-darken-2
{
    border-right: 1px solid #7b1fa2 !important;
}

.overlay-purple.overlay-darken-2
{
    background: #7b1fa2;
    /* The Fallback */
    background: rgba(123, 31, 162, .8);
}

.purple.darken-3
{
    color: #6a1b9a !important;
}

.bg-purple.bg-darken-3
{
    background-color: #6a1b9a !important;
}
.bg-purple.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(106, 27, 154, .6);
            box-shadow: 0 1px 20px 1px rgba(106, 27, 154, .6);
}

.btn-purple.btn-darken-3
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-3:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-3:focus,
.btn-purple.btn-darken-3:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-darken-3
{
    color: #6a1b9a !important; 
    border-color: #6a1b9a !important;
}
.btn-outline-purple.btn-outline-darken-3:hover
{
    background-color: #6a1b9a !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6a1b9a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6a1b9a !important;
}

.border-purple.border-darken-3
{
    border: 1px solid #6a1b9a !important;
}

.border-top-purple.border-top-darken-3
{
    border-top: 1px solid #6a1b9a !important;
}

.border-bottom-purple.border-bottom-darken-3
{
    border-bottom: 1px solid #6a1b9a !important;
}

.border-left-purple.border-left-darken-3
{
    border-left: 1px solid #6a1b9a !important;
}

.border-right-purple.border-right-darken-3
{
    border-right: 1px solid #6a1b9a !important;
}

.overlay-purple.overlay-darken-3
{
    background: #6a1b9a;
    /* The Fallback */
    background: rgba(106, 27, 154, .8);
}

.purple.darken-4
{
    color: #4a148c !important;
}

.bg-purple.bg-darken-4
{
    background-color: #4a148c !important;
}
.bg-purple.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(74, 20, 140, .6);
            box-shadow: 0 1px 20px 1px rgba(74, 20, 140, .6);
}

.btn-purple.btn-darken-4
{
    border-color: #7b1fa2 !important;
    background-color: #4a148c !important;
}
.btn-purple.btn-darken-4:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-4:focus,
.btn-purple.btn-darken-4:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-darken-4
{
    color: #4a148c !important; 
    border-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-darken-4:hover
{
    background-color: #4a148c !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4a148c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4a148c !important;
}

.border-purple.border-darken-4
{
    border: 1px solid #4a148c !important;
}

.border-top-purple.border-top-darken-4
{
    border-top: 1px solid #4a148c !important;
}

.border-bottom-purple.border-bottom-darken-4
{
    border-bottom: 1px solid #4a148c !important;
}

.border-left-purple.border-left-darken-4
{
    border-left: 1px solid #4a148c !important;
}

.border-right-purple.border-right-darken-4
{
    border-right: 1px solid #4a148c !important;
}

.overlay-purple.overlay-darken-4
{
    background: #4a148c;
    /* The Fallback */
    background: rgba(74, 20, 140, .8);
}

.purple.accent-1
{
    color: #ea80fc !important;
}

.bg-purple.bg-accent-1
{
    background-color: #ea80fc !important;
}
.bg-purple.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(234, 128, 252, .6);
            box-shadow: 0 1px 20px 1px rgba(234, 128, 252, .6);
}

.btn-purple.btn-accent-1
{
    border-color: #7b1fa2 !important;
    background-color: #ea80fc !important;
}
.btn-purple.btn-accent-1:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-1:focus,
.btn-purple.btn-accent-1:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-accent-1
{
    color: #ea80fc !important; 
    border-color: #ea80fc !important;
}
.btn-outline-purple.btn-outline-accent-1:hover
{
    background-color: #ea80fc !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ea80fc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ea80fc !important;
}

.border-purple.border-accent-1
{
    border: 1px solid #ea80fc !important;
}

.border-top-purple.border-top-accent-1
{
    border-top: 1px solid #ea80fc !important;
}

.border-bottom-purple.border-bottom-accent-1
{
    border-bottom: 1px solid #ea80fc !important;
}

.border-left-purple.border-left-accent-1
{
    border-left: 1px solid #ea80fc !important;
}

.border-right-purple.border-right-accent-1
{
    border-right: 1px solid #ea80fc !important;
}

.overlay-purple.overlay-accent-1
{
    background: #ea80fc;
    /* The Fallback */
    background: rgba(234, 128, 252, .8);
}

.purple.accent-2
{
    color: #e040fb !important;
}

.bg-purple.bg-accent-2
{
    background-color: #e040fb !important;
}
.bg-purple.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(224, 64, 251, .6);
            box-shadow: 0 1px 20px 1px rgba(224, 64, 251, .6);
}

.btn-purple.btn-accent-2
{
    border-color: #7b1fa2 !important;
    background-color: #e040fb !important;
}
.btn-purple.btn-accent-2:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-2:focus,
.btn-purple.btn-accent-2:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-accent-2
{
    color: #e040fb !important; 
    border-color: #e040fb !important;
}
.btn-outline-purple.btn-outline-accent-2:hover
{
    background-color: #e040fb !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e040fb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e040fb !important;
}

.border-purple.border-accent-2
{
    border: 1px solid #e040fb !important;
}

.border-top-purple.border-top-accent-2
{
    border-top: 1px solid #e040fb !important;
}

.border-bottom-purple.border-bottom-accent-2
{
    border-bottom: 1px solid #e040fb !important;
}

.border-left-purple.border-left-accent-2
{
    border-left: 1px solid #e040fb !important;
}

.border-right-purple.border-right-accent-2
{
    border-right: 1px solid #e040fb !important;
}

.overlay-purple.overlay-accent-2
{
    background: #e040fb;
    /* The Fallback */
    background: rgba(224, 64, 251, .8);
}

.purple.accent-3
{
    color: #d500f9 !important;
}

.bg-purple.bg-accent-3
{
    background-color: #d500f9 !important;
}
.bg-purple.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(213, 0, 249, .6);
            box-shadow: 0 1px 20px 1px rgba(213, 0, 249, .6);
}

.btn-purple.btn-accent-3
{
    border-color: #7b1fa2 !important;
    background-color: #d500f9 !important;
}
.btn-purple.btn-accent-3:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-3:focus,
.btn-purple.btn-accent-3:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-accent-3
{
    color: #d500f9 !important; 
    border-color: #d500f9 !important;
}
.btn-outline-purple.btn-outline-accent-3:hover
{
    background-color: #d500f9 !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d500f9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d500f9 !important;
}

.border-purple.border-accent-3
{
    border: 1px solid #d500f9 !important;
}

.border-top-purple.border-top-accent-3
{
    border-top: 1px solid #d500f9 !important;
}

.border-bottom-purple.border-bottom-accent-3
{
    border-bottom: 1px solid #d500f9 !important;
}

.border-left-purple.border-left-accent-3
{
    border-left: 1px solid #d500f9 !important;
}

.border-right-purple.border-right-accent-3
{
    border-right: 1px solid #d500f9 !important;
}

.overlay-purple.overlay-accent-3
{
    background: #d500f9;
    /* The Fallback */
    background: rgba(213, 0, 249, .8);
}

.purple.accent-4
{
    color: #d0f !important;
}

.bg-purple.bg-accent-4
{
    background-color: #d0f !important;
}
.bg-purple.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(221, 0, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(221, 0, 255, .6);
}

.btn-purple.btn-accent-4
{
    border-color: #7b1fa2 !important;
    background-color: #d0f !important;
}
.btn-purple.btn-accent-4:hover
{
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-4:focus,
.btn-purple.btn-accent-4:active
{
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}

.btn-outline-purple.btn-outline-accent-4
{
    color: #d0f !important; 
    border-color: #d0f !important;
}
.btn-outline-purple.btn-outline-accent-4:hover
{
    background-color: #d0f !important;
}

input:focus ~ .bg-purple
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d0f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #d0f !important;
}

.border-purple.border-accent-4
{
    border: 1px solid #d0f !important;
}

.border-top-purple.border-top-accent-4
{
    border-top: 1px solid #d0f !important;
}

.border-bottom-purple.border-bottom-accent-4
{
    border-bottom: 1px solid #d0f !important;
}

.border-left-purple.border-left-accent-4
{
    border-left: 1px solid #d0f !important;
}

.border-right-purple.border-right-accent-4
{
    border-right: 1px solid #d0f !important;
}

.overlay-purple.overlay-accent-4
{
    background: #d0f;
    /* The Fallback */
    background: rgba(221, 0, 255, .8);
}

.blue.lighten-5
{
    color: #e3f2fd !important;
}

.bg-blue.bg-lighten-5
{
    background-color: #e3f2fd !important;
}
.bg-blue.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(227, 242, 253, .6);
            box-shadow: 0 1px 20px 1px rgba(227, 242, 253, .6);
}

.btn-blue.btn-lighten-5
{
    border-color: #1976d2 !important;
    background-color: #e3f2fd !important;
}
.btn-blue.btn-lighten-5:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-5:focus,
.btn-blue.btn-lighten-5:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-5
{
    color: #e3f2fd !important; 
    border-color: #e3f2fd !important;
}
.btn-outline-blue.btn-outline-lighten-5:hover
{
    background-color: #e3f2fd !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e3f2fd !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e3f2fd !important;
}

.border-blue.border-lighten-5
{
    border: 1px solid #e3f2fd !important;
}

.border-top-blue.border-top-lighten-5
{
    border-top: 1px solid #e3f2fd !important;
}

.border-bottom-blue.border-bottom-lighten-5
{
    border-bottom: 1px solid #e3f2fd !important;
}

.border-left-blue.border-left-lighten-5
{
    border-left: 1px solid #e3f2fd !important;
}

.border-right-blue.border-right-lighten-5
{
    border-right: 1px solid #e3f2fd !important;
}

.overlay-blue.overlay-lighten-5
{
    background: #e3f2fd;
    /* The Fallback */
    background: rgba(227, 242, 253, .8);
}

.blue.lighten-4
{
    color: #bbdefb !important;
}

.bg-blue.bg-lighten-4
{
    background-color: #bbdefb !important;
}
.bg-blue.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(187, 222, 251, .6);
            box-shadow: 0 1px 20px 1px rgba(187, 222, 251, .6);
}

.btn-blue.btn-lighten-4
{
    border-color: #1976d2 !important;
    background-color: #bbdefb !important;
}
.btn-blue.btn-lighten-4:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-4:focus,
.btn-blue.btn-lighten-4:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-4
{
    color: #bbdefb !important; 
    border-color: #bbdefb !important;
}
.btn-outline-blue.btn-outline-lighten-4:hover
{
    background-color: #bbdefb !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bbdefb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #bbdefb !important;
}

.border-blue.border-lighten-4
{
    border: 1px solid #bbdefb !important;
}

.border-top-blue.border-top-lighten-4
{
    border-top: 1px solid #bbdefb !important;
}

.border-bottom-blue.border-bottom-lighten-4
{
    border-bottom: 1px solid #bbdefb !important;
}

.border-left-blue.border-left-lighten-4
{
    border-left: 1px solid #bbdefb !important;
}

.border-right-blue.border-right-lighten-4
{
    border-right: 1px solid #bbdefb !important;
}

.overlay-blue.overlay-lighten-4
{
    background: #bbdefb;
    /* The Fallback */
    background: rgba(187, 222, 251, .8);
}

.blue.lighten-3
{
    color: #90caf9 !important;
}

.bg-blue.bg-lighten-3
{
    background-color: #90caf9 !important;
}
.bg-blue.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(144, 202, 249, .6);
            box-shadow: 0 1px 20px 1px rgba(144, 202, 249, .6);
}

.btn-blue.btn-lighten-3
{
    border-color: #1976d2 !important;
    background-color: #90caf9 !important;
}
.btn-blue.btn-lighten-3:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-3:focus,
.btn-blue.btn-lighten-3:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-3
{
    color: #90caf9 !important; 
    border-color: #90caf9 !important;
}
.btn-outline-blue.btn-outline-lighten-3:hover
{
    background-color: #90caf9 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #90caf9 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #90caf9 !important;
}

.border-blue.border-lighten-3
{
    border: 1px solid #90caf9 !important;
}

.border-top-blue.border-top-lighten-3
{
    border-top: 1px solid #90caf9 !important;
}

.border-bottom-blue.border-bottom-lighten-3
{
    border-bottom: 1px solid #90caf9 !important;
}

.border-left-blue.border-left-lighten-3
{
    border-left: 1px solid #90caf9 !important;
}

.border-right-blue.border-right-lighten-3
{
    border-right: 1px solid #90caf9 !important;
}

.overlay-blue.overlay-lighten-3
{
    background: #90caf9;
    /* The Fallback */
    background: rgba(144, 202, 249, .8);
}

.blue.lighten-2
{
    color: #64b5f6 !important;
}

.bg-blue.bg-lighten-2
{
    background-color: #64b5f6 !important;
}
.bg-blue.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(100, 181, 246, .6);
            box-shadow: 0 1px 20px 1px rgba(100, 181, 246, .6);
}

.btn-blue.btn-lighten-2
{
    border-color: #1976d2 !important;
    background-color: #64b5f6 !important;
}
.btn-blue.btn-lighten-2:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-2:focus,
.btn-blue.btn-lighten-2:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-2
{
    color: #64b5f6 !important; 
    border-color: #64b5f6 !important;
}
.btn-outline-blue.btn-outline-lighten-2:hover
{
    background-color: #64b5f6 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64b5f6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64b5f6 !important;
}

.border-blue.border-lighten-2
{
    border: 1px solid #64b5f6 !important;
}

.border-top-blue.border-top-lighten-2
{
    border-top: 1px solid #64b5f6 !important;
}

.border-bottom-blue.border-bottom-lighten-2
{
    border-bottom: 1px solid #64b5f6 !important;
}

.border-left-blue.border-left-lighten-2
{
    border-left: 1px solid #64b5f6 !important;
}

.border-right-blue.border-right-lighten-2
{
    border-right: 1px solid #64b5f6 !important;
}

.overlay-blue.overlay-lighten-2
{
    background: #64b5f6;
    /* The Fallback */
    background: rgba(100, 181, 246, .8);
}

.blue.lighten-1
{
    color: #42a5f5 !important;
}

.bg-blue.bg-lighten-1
{
    background-color: #42a5f5 !important;
}
.bg-blue.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(66, 165, 245, .6);
            box-shadow: 0 1px 20px 1px rgba(66, 165, 245, .6);
}

.btn-blue.btn-lighten-1
{
    border-color: #1976d2 !important;
    background-color: #42a5f5 !important;
}
.btn-blue.btn-lighten-1:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-1:focus,
.btn-blue.btn-lighten-1:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-lighten-1
{
    color: #42a5f5 !important; 
    border-color: #42a5f5 !important;
}
.btn-outline-blue.btn-outline-lighten-1:hover
{
    background-color: #42a5f5 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #42a5f5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #42a5f5 !important;
}

.border-blue.border-lighten-1
{
    border: 1px solid #42a5f5 !important;
}

.border-top-blue.border-top-lighten-1
{
    border-top: 1px solid #42a5f5 !important;
}

.border-bottom-blue.border-bottom-lighten-1
{
    border-bottom: 1px solid #42a5f5 !important;
}

.border-left-blue.border-left-lighten-1
{
    border-left: 1px solid #42a5f5 !important;
}

.border-right-blue.border-right-lighten-1
{
    border-right: 1px solid #42a5f5 !important;
}

.overlay-blue.overlay-lighten-1
{
    background: #42a5f5;
    /* The Fallback */
    background: rgba(66, 165, 245, .8);
}

.blue
{
    color: #2196f3 !important;
}

.bg-blue
{
    background-color: #2196f3 !important;
}
.bg-blue .card-header,
.bg-blue .card-footer
{
    background-color: transparent;
}
.bg-blue.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
            box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
}

.selectboxit.selectboxit-btn.bg-blue
{
    background-color: #2196f3 !important;
}

.alert-blue
{
    color: #05365d !important; 
    border-color: #2196f3 !important;
    background-color: #65b6f7 !important;
}
.alert-blue .alert-link
{
    color: #031f36 !important;
}

.border-blue
{
    border-color: #2196f3;
}

.overlay-blue
{
    background: #2196f3;
    /* The Fallback */
    background: rgba(33, 150, 243, .8);
}

/* .card-outline-blue{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-blue
{
    color: #fff; 
    border-color: #1976d2 !important;
    background-color: #2196f3 !important;
}
.btn-blue:hover
{
    color: #fff !important; 
    border-color: #1e88e5 !important;
    background-color: #42a5f5 !important;
}
.btn-blue:focus,
.btn-blue:active
{
    color: #fff !important; 
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
            box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
}

.btn-outline-blue
{
    color: #2196f3; 
    border-color: #2196f3;
    background-color: transparent;
}
.btn-outline-blue:hover
{
    color: #fff !important; 
    background-color: #2196f3;
}
.btn-outline-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
            box-shadow: 0 1px 20px 1px rgba(33, 150, 243, .6);
}

input[type='checkbox'].bg-blue + .custom-control-label:before,
input[type='radio'].bg-blue + .custom-control-label:before
{
    background-color: #2196f3 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2196f3 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2196f3 !important;
}

.border-blue
{
    border: 1px solid #2196f3 !important;
}

.border-top-blue
{
    border-top: 1px solid #2196f3;
}

.border-bottom-blue
{
    border-bottom: 1px solid #2196f3;
}

.border-left-blue
{
    border-left: 1px solid #2196f3;
}

.border-right-blue
{
    border-right: 1px solid #2196f3;
}

.blue.darken-1
{
    color: #1e88e5 !important;
}

.bg-blue.bg-darken-1
{
    background-color: #1e88e5 !important;
}
.bg-blue.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(30, 136, 229, .6);
            box-shadow: 0 1px 20px 1px rgba(30, 136, 229, .6);
}

.btn-blue.btn-darken-1
{
    border-color: #1976d2 !important;
    background-color: #1e88e5 !important;
}
.btn-blue.btn-darken-1:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-1:focus,
.btn-blue.btn-darken-1:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-darken-1
{
    color: #1e88e5 !important; 
    border-color: #1e88e5 !important;
}
.btn-outline-blue.btn-outline-darken-1:hover
{
    background-color: #1e88e5 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1e88e5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1e88e5 !important;
}

.border-blue.border-darken-1
{
    border: 1px solid #1e88e5 !important;
}

.border-top-blue.border-top-darken-1
{
    border-top: 1px solid #1e88e5 !important;
}

.border-bottom-blue.border-bottom-darken-1
{
    border-bottom: 1px solid #1e88e5 !important;
}

.border-left-blue.border-left-darken-1
{
    border-left: 1px solid #1e88e5 !important;
}

.border-right-blue.border-right-darken-1
{
    border-right: 1px solid #1e88e5 !important;
}

.overlay-blue.overlay-darken-1
{
    background: #1e88e5;
    /* The Fallback */
    background: rgba(30, 136, 229, .8);
}

.blue.darken-2
{
    color: #1976d2 !important;
}

.bg-blue.bg-darken-2
{
    background-color: #1976d2 !important;
}
.bg-blue.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(25, 118, 210, .6);
            box-shadow: 0 1px 20px 1px rgba(25, 118, 210, .6);
}

.btn-blue.btn-darken-2
{
    border-color: #1976d2 !important;
    background-color: #1976d2 !important;
}
.btn-blue.btn-darken-2:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-2:focus,
.btn-blue.btn-darken-2:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-darken-2
{
    color: #1976d2 !important; 
    border-color: #1976d2 !important;
}
.btn-outline-blue.btn-outline-darken-2:hover
{
    background-color: #1976d2 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1976d2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1976d2 !important;
}

.border-blue.border-darken-2
{
    border: 1px solid #1976d2 !important;
}

.border-top-blue.border-top-darken-2
{
    border-top: 1px solid #1976d2 !important;
}

.border-bottom-blue.border-bottom-darken-2
{
    border-bottom: 1px solid #1976d2 !important;
}

.border-left-blue.border-left-darken-2
{
    border-left: 1px solid #1976d2 !important;
}

.border-right-blue.border-right-darken-2
{
    border-right: 1px solid #1976d2 !important;
}

.overlay-blue.overlay-darken-2
{
    background: #1976d2;
    /* The Fallback */
    background: rgba(25, 118, 210, .8);
}

.blue.darken-3
{
    color: #1565c0 !important;
}

.bg-blue.bg-darken-3
{
    background-color: #1565c0 !important;
}
.bg-blue.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(21, 101, 192, .6);
            box-shadow: 0 1px 20px 1px rgba(21, 101, 192, .6);
}

.btn-blue.btn-darken-3
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-3:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-3:focus,
.btn-blue.btn-darken-3:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-darken-3
{
    color: #1565c0 !important; 
    border-color: #1565c0 !important;
}
.btn-outline-blue.btn-outline-darken-3:hover
{
    background-color: #1565c0 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1565c0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1565c0 !important;
}

.border-blue.border-darken-3
{
    border: 1px solid #1565c0 !important;
}

.border-top-blue.border-top-darken-3
{
    border-top: 1px solid #1565c0 !important;
}

.border-bottom-blue.border-bottom-darken-3
{
    border-bottom: 1px solid #1565c0 !important;
}

.border-left-blue.border-left-darken-3
{
    border-left: 1px solid #1565c0 !important;
}

.border-right-blue.border-right-darken-3
{
    border-right: 1px solid #1565c0 !important;
}

.overlay-blue.overlay-darken-3
{
    background: #1565c0;
    /* The Fallback */
    background: rgba(21, 101, 192, .8);
}

.blue.darken-4
{
    color: #0d47a1 !important;
}

.bg-blue.bg-darken-4
{
    background-color: #0d47a1 !important;
}
.bg-blue.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(13, 71, 161, .6);
            box-shadow: 0 1px 20px 1px rgba(13, 71, 161, .6);
}

.btn-blue.btn-darken-4
{
    border-color: #1976d2 !important;
    background-color: #0d47a1 !important;
}
.btn-blue.btn-darken-4:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-4:focus,
.btn-blue.btn-darken-4:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-darken-4
{
    color: #0d47a1 !important; 
    border-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-darken-4:hover
{
    background-color: #0d47a1 !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0d47a1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0d47a1 !important;
}

.border-blue.border-darken-4
{
    border: 1px solid #0d47a1 !important;
}

.border-top-blue.border-top-darken-4
{
    border-top: 1px solid #0d47a1 !important;
}

.border-bottom-blue.border-bottom-darken-4
{
    border-bottom: 1px solid #0d47a1 !important;
}

.border-left-blue.border-left-darken-4
{
    border-left: 1px solid #0d47a1 !important;
}

.border-right-blue.border-right-darken-4
{
    border-right: 1px solid #0d47a1 !important;
}

.overlay-blue.overlay-darken-4
{
    background: #0d47a1;
    /* The Fallback */
    background: rgba(13, 71, 161, .8);
}

.blue.accent-1
{
    color: #82b1ff !important;
}

.bg-blue.bg-accent-1
{
    background-color: #82b1ff !important;
}
.bg-blue.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(130, 177, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(130, 177, 255, .6);
}

.btn-blue.btn-accent-1
{
    border-color: #1976d2 !important;
    background-color: #82b1ff !important;
}
.btn-blue.btn-accent-1:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-1:focus,
.btn-blue.btn-accent-1:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-accent-1
{
    color: #82b1ff !important; 
    border-color: #82b1ff !important;
}
.btn-outline-blue.btn-outline-accent-1:hover
{
    background-color: #82b1ff !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #82b1ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #82b1ff !important;
}

.border-blue.border-accent-1
{
    border: 1px solid #82b1ff !important;
}

.border-top-blue.border-top-accent-1
{
    border-top: 1px solid #82b1ff !important;
}

.border-bottom-blue.border-bottom-accent-1
{
    border-bottom: 1px solid #82b1ff !important;
}

.border-left-blue.border-left-accent-1
{
    border-left: 1px solid #82b1ff !important;
}

.border-right-blue.border-right-accent-1
{
    border-right: 1px solid #82b1ff !important;
}

.overlay-blue.overlay-accent-1
{
    background: #82b1ff;
    /* The Fallback */
    background: rgba(130, 177, 255, .8);
}

.blue.accent-2
{
    color: #448aff !important;
}

.bg-blue.bg-accent-2
{
    background-color: #448aff !important;
}
.bg-blue.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(68, 138, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(68, 138, 255, .6);
}

.btn-blue.btn-accent-2
{
    border-color: #1976d2 !important;
    background-color: #448aff !important;
}
.btn-blue.btn-accent-2:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-2:focus,
.btn-blue.btn-accent-2:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-accent-2
{
    color: #448aff !important; 
    border-color: #448aff !important;
}
.btn-outline-blue.btn-outline-accent-2:hover
{
    background-color: #448aff !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #448aff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #448aff !important;
}

.border-blue.border-accent-2
{
    border: 1px solid #448aff !important;
}

.border-top-blue.border-top-accent-2
{
    border-top: 1px solid #448aff !important;
}

.border-bottom-blue.border-bottom-accent-2
{
    border-bottom: 1px solid #448aff !important;
}

.border-left-blue.border-left-accent-2
{
    border-left: 1px solid #448aff !important;
}

.border-right-blue.border-right-accent-2
{
    border-right: 1px solid #448aff !important;
}

.overlay-blue.overlay-accent-2
{
    background: #448aff;
    /* The Fallback */
    background: rgba(68, 138, 255, .8);
}

.blue.accent-3
{
    color: #2979ff !important;
}

.bg-blue.bg-accent-3
{
    background-color: #2979ff !important;
}
.bg-blue.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(41, 121, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(41, 121, 255, .6);
}

.btn-blue.btn-accent-3
{
    border-color: #1976d2 !important;
    background-color: #2979ff !important;
}
.btn-blue.btn-accent-3:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-3:focus,
.btn-blue.btn-accent-3:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-accent-3
{
    color: #2979ff !important; 
    border-color: #2979ff !important;
}
.btn-outline-blue.btn-outline-accent-3:hover
{
    background-color: #2979ff !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2979ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2979ff !important;
}

.border-blue.border-accent-3
{
    border: 1px solid #2979ff !important;
}

.border-top-blue.border-top-accent-3
{
    border-top: 1px solid #2979ff !important;
}

.border-bottom-blue.border-bottom-accent-3
{
    border-bottom: 1px solid #2979ff !important;
}

.border-left-blue.border-left-accent-3
{
    border-left: 1px solid #2979ff !important;
}

.border-right-blue.border-right-accent-3
{
    border-right: 1px solid #2979ff !important;
}

.overlay-blue.overlay-accent-3
{
    background: #2979ff;
    /* The Fallback */
    background: rgba(41, 121, 255, .8);
}

.blue.accent-4
{
    color: #2962ff !important;
}

.bg-blue.bg-accent-4
{
    background-color: #2962ff !important;
}
.bg-blue.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(41, 98, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(41, 98, 255, .6);
}

.btn-blue.btn-accent-4
{
    border-color: #1976d2 !important;
    background-color: #2962ff !important;
}
.btn-blue.btn-accent-4:hover
{
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-4:focus,
.btn-blue.btn-accent-4:active
{
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}

.btn-outline-blue.btn-outline-accent-4
{
    color: #2962ff !important; 
    border-color: #2962ff !important;
}
.btn-outline-blue.btn-outline-accent-4:hover
{
    background-color: #2962ff !important;
}

input:focus ~ .bg-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2962ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #2962ff !important;
}

.border-blue.border-accent-4
{
    border: 1px solid #2962ff !important;
}

.border-top-blue.border-top-accent-4
{
    border-top: 1px solid #2962ff !important;
}

.border-bottom-blue.border-bottom-accent-4
{
    border-bottom: 1px solid #2962ff !important;
}

.border-left-blue.border-left-accent-4
{
    border-left: 1px solid #2962ff !important;
}

.border-right-blue.border-right-accent-4
{
    border-right: 1px solid #2962ff !important;
}

.overlay-blue.overlay-accent-4
{
    background: #2962ff;
    /* The Fallback */
    background: rgba(41, 98, 255, .8);
}

.cyan.lighten-5
{
    color: #e0f7fa !important;
}

.bg-cyan.bg-lighten-5
{
    background-color: #e0f7fa !important;
}
.bg-cyan.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(224, 247, 250, .6);
            box-shadow: 0 1px 20px 1px rgba(224, 247, 250, .6);
}

.btn-cyan.btn-lighten-5
{
    border-color: #0097a7 !important;
    background-color: #e0f7fa !important;
}
.btn-cyan.btn-lighten-5:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-5:focus,
.btn-cyan.btn-lighten-5:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-5
{
    color: #e0f7fa !important; 
    border-color: #e0f7fa !important;
}
.btn-outline-cyan.btn-outline-lighten-5:hover
{
    background-color: #e0f7fa !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0f7fa !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0f7fa !important;
}

.border-cyan.border-lighten-5
{
    border: 1px solid #e0f7fa !important;
}

.border-top-cyan.border-top-lighten-5
{
    border-top: 1px solid #e0f7fa !important;
}

.border-bottom-cyan.border-bottom-lighten-5
{
    border-bottom: 1px solid #e0f7fa !important;
}

.border-left-cyan.border-left-lighten-5
{
    border-left: 1px solid #e0f7fa !important;
}

.border-right-cyan.border-right-lighten-5
{
    border-right: 1px solid #e0f7fa !important;
}

.overlay-cyan.overlay-lighten-5
{
    background: #e0f7fa;
    /* The Fallback */
    background: rgba(224, 247, 250, .8);
}

.cyan.lighten-4
{
    color: #b2ebf2 !important;
}

.bg-cyan.bg-lighten-4
{
    background-color: #b2ebf2 !important;
}
.bg-cyan.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(178, 235, 242, .6);
            box-shadow: 0 1px 20px 1px rgba(178, 235, 242, .6);
}

.btn-cyan.btn-lighten-4
{
    border-color: #0097a7 !important;
    background-color: #b2ebf2 !important;
}
.btn-cyan.btn-lighten-4:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-4:focus,
.btn-cyan.btn-lighten-4:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-4
{
    color: #b2ebf2 !important; 
    border-color: #b2ebf2 !important;
}
.btn-outline-cyan.btn-outline-lighten-4:hover
{
    background-color: #b2ebf2 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2ebf2 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2ebf2 !important;
}

.border-cyan.border-lighten-4
{
    border: 1px solid #b2ebf2 !important;
}

.border-top-cyan.border-top-lighten-4
{
    border-top: 1px solid #b2ebf2 !important;
}

.border-bottom-cyan.border-bottom-lighten-4
{
    border-bottom: 1px solid #b2ebf2 !important;
}

.border-left-cyan.border-left-lighten-4
{
    border-left: 1px solid #b2ebf2 !important;
}

.border-right-cyan.border-right-lighten-4
{
    border-right: 1px solid #b2ebf2 !important;
}

.overlay-cyan.overlay-lighten-4
{
    background: #b2ebf2;
    /* The Fallback */
    background: rgba(178, 235, 242, .8);
}

.cyan.lighten-3
{
    color: #80deea !important;
}

.bg-cyan.bg-lighten-3
{
    background-color: #80deea !important;
}
.bg-cyan.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(128, 222, 234, .6);
            box-shadow: 0 1px 20px 1px rgba(128, 222, 234, .6);
}

.btn-cyan.btn-lighten-3
{
    border-color: #0097a7 !important;
    background-color: #80deea !important;
}
.btn-cyan.btn-lighten-3:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-3:focus,
.btn-cyan.btn-lighten-3:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-3
{
    color: #80deea !important; 
    border-color: #80deea !important;
}
.btn-outline-cyan.btn-outline-lighten-3:hover
{
    background-color: #80deea !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80deea !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80deea !important;
}

.border-cyan.border-lighten-3
{
    border: 1px solid #80deea !important;
}

.border-top-cyan.border-top-lighten-3
{
    border-top: 1px solid #80deea !important;
}

.border-bottom-cyan.border-bottom-lighten-3
{
    border-bottom: 1px solid #80deea !important;
}

.border-left-cyan.border-left-lighten-3
{
    border-left: 1px solid #80deea !important;
}

.border-right-cyan.border-right-lighten-3
{
    border-right: 1px solid #80deea !important;
}

.overlay-cyan.overlay-lighten-3
{
    background: #80deea;
    /* The Fallback */
    background: rgba(128, 222, 234, .8);
}

.cyan.lighten-2
{
    color: #4dd0e1 !important;
}

.bg-cyan.bg-lighten-2
{
    background-color: #4dd0e1 !important;
}
.bg-cyan.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(77, 208, 225, .6);
            box-shadow: 0 1px 20px 1px rgba(77, 208, 225, .6);
}

.btn-cyan.btn-lighten-2
{
    border-color: #0097a7 !important;
    background-color: #4dd0e1 !important;
}
.btn-cyan.btn-lighten-2:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-2:focus,
.btn-cyan.btn-lighten-2:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-2
{
    color: #4dd0e1 !important; 
    border-color: #4dd0e1 !important;
}
.btn-outline-cyan.btn-outline-lighten-2:hover
{
    background-color: #4dd0e1 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4dd0e1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4dd0e1 !important;
}

.border-cyan.border-lighten-2
{
    border: 1px solid #4dd0e1 !important;
}

.border-top-cyan.border-top-lighten-2
{
    border-top: 1px solid #4dd0e1 !important;
}

.border-bottom-cyan.border-bottom-lighten-2
{
    border-bottom: 1px solid #4dd0e1 !important;
}

.border-left-cyan.border-left-lighten-2
{
    border-left: 1px solid #4dd0e1 !important;
}

.border-right-cyan.border-right-lighten-2
{
    border-right: 1px solid #4dd0e1 !important;
}

.overlay-cyan.overlay-lighten-2
{
    background: #4dd0e1;
    /* The Fallback */
    background: rgba(77, 208, 225, .8);
}

.cyan.lighten-1
{
    color: #26c6da !important;
}

.bg-cyan.bg-lighten-1
{
    background-color: #26c6da !important;
}
.bg-cyan.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(38, 198, 218, .6);
            box-shadow: 0 1px 20px 1px rgba(38, 198, 218, .6);
}

.btn-cyan.btn-lighten-1
{
    border-color: #0097a7 !important;
    background-color: #26c6da !important;
}
.btn-cyan.btn-lighten-1:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-1:focus,
.btn-cyan.btn-lighten-1:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-lighten-1
{
    color: #26c6da !important; 
    border-color: #26c6da !important;
}
.btn-outline-cyan.btn-outline-lighten-1:hover
{
    background-color: #26c6da !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #26c6da !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #26c6da !important;
}

.border-cyan.border-lighten-1
{
    border: 1px solid #26c6da !important;
}

.border-top-cyan.border-top-lighten-1
{
    border-top: 1px solid #26c6da !important;
}

.border-bottom-cyan.border-bottom-lighten-1
{
    border-bottom: 1px solid #26c6da !important;
}

.border-left-cyan.border-left-lighten-1
{
    border-left: 1px solid #26c6da !important;
}

.border-right-cyan.border-right-lighten-1
{
    border-right: 1px solid #26c6da !important;
}

.overlay-cyan.overlay-lighten-1
{
    background: #26c6da;
    /* The Fallback */
    background: rgba(38, 198, 218, .8);
}

.cyan
{
    color: #00bcd4 !important;
}

.bg-cyan
{
    background-color: #00bcd4 !important;
}
.bg-cyan .card-header,
.bg-cyan .card-footer
{
    background-color: transparent;
}
.bg-cyan.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
}

.selectboxit.selectboxit-btn.bg-cyan
{
    background-color: #00bcd4 !important;
}

.alert-cyan
{
    color: #001e22 !important; 
    border-color: #00bcd4 !important;
    background-color: #1ce5ff !important;
}
.alert-cyan .alert-link
{
    color: black !important;
}

.border-cyan
{
    border-color: #00bcd4;
}

.overlay-cyan
{
    background: #00bcd4;
    /* The Fallback */
    background: rgba(0, 188, 212, .8);
}

/* .card-outline-cyan{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-cyan
{
    color: #fff; 
    border-color: #0097a7 !important;
    background-color: #00bcd4 !important;
}
.btn-cyan:hover
{
    color: #fff !important; 
    border-color: #00acc1 !important;
    background-color: #26c6da !important;
}
.btn-cyan:focus,
.btn-cyan:active
{
    color: #fff !important; 
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
}

.btn-outline-cyan
{
    color: #00bcd4; 
    border-color: #00bcd4;
    background-color: transparent;
}
.btn-outline-cyan:hover
{
    color: #fff !important; 
    background-color: #00bcd4;
}
.btn-outline-cyan.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 188, 212, .6);
}

input[type='checkbox'].bg-cyan + .custom-control-label:before,
input[type='radio'].bg-cyan + .custom-control-label:before
{
    background-color: #00bcd4 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00bcd4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00bcd4 !important;
}

.border-cyan
{
    border: 1px solid #00bcd4 !important;
}

.border-top-cyan
{
    border-top: 1px solid #00bcd4;
}

.border-bottom-cyan
{
    border-bottom: 1px solid #00bcd4;
}

.border-left-cyan
{
    border-left: 1px solid #00bcd4;
}

.border-right-cyan
{
    border-right: 1px solid #00bcd4;
}

.cyan.darken-1
{
    color: #00acc1 !important;
}

.bg-cyan.bg-darken-1
{
    background-color: #00acc1 !important;
}
.bg-cyan.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 172, 193, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 172, 193, .6);
}

.btn-cyan.btn-darken-1
{
    border-color: #0097a7 !important;
    background-color: #00acc1 !important;
}
.btn-cyan.btn-darken-1:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-1:focus,
.btn-cyan.btn-darken-1:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-darken-1
{
    color: #00acc1 !important; 
    border-color: #00acc1 !important;
}
.btn-outline-cyan.btn-outline-darken-1:hover
{
    background-color: #00acc1 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00acc1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00acc1 !important;
}

.border-cyan.border-darken-1
{
    border: 1px solid #00acc1 !important;
}

.border-top-cyan.border-top-darken-1
{
    border-top: 1px solid #00acc1 !important;
}

.border-bottom-cyan.border-bottom-darken-1
{
    border-bottom: 1px solid #00acc1 !important;
}

.border-left-cyan.border-left-darken-1
{
    border-left: 1px solid #00acc1 !important;
}

.border-right-cyan.border-right-darken-1
{
    border-right: 1px solid #00acc1 !important;
}

.overlay-cyan.overlay-darken-1
{
    background: #00acc1;
    /* The Fallback */
    background: rgba(0, 172, 193, .8);
}

.cyan.darken-2
{
    color: #0097a7 !important;
}

.bg-cyan.bg-darken-2
{
    background-color: #0097a7 !important;
}
.bg-cyan.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 151, 167, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 151, 167, .6);
}

.btn-cyan.btn-darken-2
{
    border-color: #0097a7 !important;
    background-color: #0097a7 !important;
}
.btn-cyan.btn-darken-2:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-2:focus,
.btn-cyan.btn-darken-2:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-darken-2
{
    color: #0097a7 !important; 
    border-color: #0097a7 !important;
}
.btn-outline-cyan.btn-outline-darken-2:hover
{
    background-color: #0097a7 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0097a7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #0097a7 !important;
}

.border-cyan.border-darken-2
{
    border: 1px solid #0097a7 !important;
}

.border-top-cyan.border-top-darken-2
{
    border-top: 1px solid #0097a7 !important;
}

.border-bottom-cyan.border-bottom-darken-2
{
    border-bottom: 1px solid #0097a7 !important;
}

.border-left-cyan.border-left-darken-2
{
    border-left: 1px solid #0097a7 !important;
}

.border-right-cyan.border-right-darken-2
{
    border-right: 1px solid #0097a7 !important;
}

.overlay-cyan.overlay-darken-2
{
    background: #0097a7;
    /* The Fallback */
    background: rgba(0, 151, 167, .8);
}

.cyan.darken-3
{
    color: #00838f !important;
}

.bg-cyan.bg-darken-3
{
    background-color: #00838f !important;
}
.bg-cyan.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 131, 143, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 131, 143, .6);
}

.btn-cyan.btn-darken-3
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-3:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-3:focus,
.btn-cyan.btn-darken-3:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-darken-3
{
    color: #00838f !important; 
    border-color: #00838f !important;
}
.btn-outline-cyan.btn-outline-darken-3:hover
{
    background-color: #00838f !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00838f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00838f !important;
}

.border-cyan.border-darken-3
{
    border: 1px solid #00838f !important;
}

.border-top-cyan.border-top-darken-3
{
    border-top: 1px solid #00838f !important;
}

.border-bottom-cyan.border-bottom-darken-3
{
    border-bottom: 1px solid #00838f !important;
}

.border-left-cyan.border-left-darken-3
{
    border-left: 1px solid #00838f !important;
}

.border-right-cyan.border-right-darken-3
{
    border-right: 1px solid #00838f !important;
}

.overlay-cyan.overlay-darken-3
{
    background: #00838f;
    /* The Fallback */
    background: rgba(0, 131, 143, .8);
}

.cyan.darken-4
{
    color: #006064 !important;
}

.bg-cyan.bg-darken-4
{
    background-color: #006064 !important;
}
.bg-cyan.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 96, 100, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 96, 100, .6);
}

.btn-cyan.btn-darken-4
{
    border-color: #0097a7 !important;
    background-color: #006064 !important;
}
.btn-cyan.btn-darken-4:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-4:focus,
.btn-cyan.btn-darken-4:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-darken-4
{
    color: #006064 !important; 
    border-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-darken-4:hover
{
    background-color: #006064 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #006064 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #006064 !important;
}

.border-cyan.border-darken-4
{
    border: 1px solid #006064 !important;
}

.border-top-cyan.border-top-darken-4
{
    border-top: 1px solid #006064 !important;
}

.border-bottom-cyan.border-bottom-darken-4
{
    border-bottom: 1px solid #006064 !important;
}

.border-left-cyan.border-left-darken-4
{
    border-left: 1px solid #006064 !important;
}

.border-right-cyan.border-right-darken-4
{
    border-right: 1px solid #006064 !important;
}

.overlay-cyan.overlay-darken-4
{
    background: #006064;
    /* The Fallback */
    background: rgba(0, 96, 100, .8);
}

.cyan.accent-1
{
    color: #84ffff !important;
}

.bg-cyan.bg-accent-1
{
    background-color: #84ffff !important;
}
.bg-cyan.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(132, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(132, 255, 255, .6);
}

.btn-cyan.btn-accent-1
{
    border-color: #0097a7 !important;
    background-color: #84ffff !important;
}
.btn-cyan.btn-accent-1:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-1:focus,
.btn-cyan.btn-accent-1:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-accent-1
{
    color: #84ffff !important; 
    border-color: #84ffff !important;
}
.btn-outline-cyan.btn-outline-accent-1:hover
{
    background-color: #84ffff !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #84ffff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #84ffff !important;
}

.border-cyan.border-accent-1
{
    border: 1px solid #84ffff !important;
}

.border-top-cyan.border-top-accent-1
{
    border-top: 1px solid #84ffff !important;
}

.border-bottom-cyan.border-bottom-accent-1
{
    border-bottom: 1px solid #84ffff !important;
}

.border-left-cyan.border-left-accent-1
{
    border-left: 1px solid #84ffff !important;
}

.border-right-cyan.border-right-accent-1
{
    border-right: 1px solid #84ffff !important;
}

.overlay-cyan.overlay-accent-1
{
    background: #84ffff;
    /* The Fallback */
    background: rgba(132, 255, 255, .8);
}

.cyan.accent-2
{
    color: #18ffff !important;
}

.bg-cyan.bg-accent-2
{
    background-color: #18ffff !important;
}
.bg-cyan.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(24, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(24, 255, 255, .6);
}

.btn-cyan.btn-accent-2
{
    border-color: #0097a7 !important;
    background-color: #18ffff !important;
}
.btn-cyan.btn-accent-2:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-2:focus,
.btn-cyan.btn-accent-2:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-accent-2
{
    color: #18ffff !important; 
    border-color: #18ffff !important;
}
.btn-outline-cyan.btn-outline-accent-2:hover
{
    background-color: #18ffff !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #18ffff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #18ffff !important;
}

.border-cyan.border-accent-2
{
    border: 1px solid #18ffff !important;
}

.border-top-cyan.border-top-accent-2
{
    border-top: 1px solid #18ffff !important;
}

.border-bottom-cyan.border-bottom-accent-2
{
    border-bottom: 1px solid #18ffff !important;
}

.border-left-cyan.border-left-accent-2
{
    border-left: 1px solid #18ffff !important;
}

.border-right-cyan.border-right-accent-2
{
    border-right: 1px solid #18ffff !important;
}

.overlay-cyan.overlay-accent-2
{
    background: #18ffff;
    /* The Fallback */
    background: rgba(24, 255, 255, .8);
}

.cyan.accent-3
{
    color: #00e5ff !important;
}

.bg-cyan.bg-accent-3
{
    background-color: #00e5ff !important;
}
.bg-cyan.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 229, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 229, 255, .6);
}

.btn-cyan.btn-accent-3
{
    border-color: #0097a7 !important;
    background-color: #00e5ff !important;
}
.btn-cyan.btn-accent-3:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-3:focus,
.btn-cyan.btn-accent-3:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-accent-3
{
    color: #00e5ff !important; 
    border-color: #00e5ff !important;
}
.btn-outline-cyan.btn-outline-accent-3:hover
{
    background-color: #00e5ff !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00e5ff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00e5ff !important;
}

.border-cyan.border-accent-3
{
    border: 1px solid #00e5ff !important;
}

.border-top-cyan.border-top-accent-3
{
    border-top: 1px solid #00e5ff !important;
}

.border-bottom-cyan.border-bottom-accent-3
{
    border-bottom: 1px solid #00e5ff !important;
}

.border-left-cyan.border-left-accent-3
{
    border-left: 1px solid #00e5ff !important;
}

.border-right-cyan.border-right-accent-3
{
    border-right: 1px solid #00e5ff !important;
}

.overlay-cyan.overlay-accent-3
{
    background: #00e5ff;
    /* The Fallback */
    background: rgba(0, 229, 255, .8);
}

.cyan.accent-4
{
    color: #00b8d4 !important;
}

.bg-cyan.bg-accent-4
{
    background-color: #00b8d4 !important;
}
.bg-cyan.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 184, 212, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 184, 212, .6);
}

.btn-cyan.btn-accent-4
{
    border-color: #0097a7 !important;
    background-color: #00b8d4 !important;
}
.btn-cyan.btn-accent-4:hover
{
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-4:focus,
.btn-cyan.btn-accent-4:active
{
    border-color: #00838f !important;
    background-color: #006064 !important;
}

.btn-outline-cyan.btn-outline-accent-4
{
    color: #00b8d4 !important; 
    border-color: #00b8d4 !important;
}
.btn-outline-cyan.btn-outline-accent-4:hover
{
    background-color: #00b8d4 !important;
}

input:focus ~ .bg-cyan
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00b8d4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00b8d4 !important;
}

.border-cyan.border-accent-4
{
    border: 1px solid #00b8d4 !important;
}

.border-top-cyan.border-top-accent-4
{
    border-top: 1px solid #00b8d4 !important;
}

.border-bottom-cyan.border-bottom-accent-4
{
    border-bottom: 1px solid #00b8d4 !important;
}

.border-left-cyan.border-left-accent-4
{
    border-left: 1px solid #00b8d4 !important;
}

.border-right-cyan.border-right-accent-4
{
    border-right: 1px solid #00b8d4 !important;
}

.overlay-cyan.overlay-accent-4
{
    background: #00b8d4;
    /* The Fallback */
    background: rgba(0, 184, 212, .8);
}

.teal.lighten-5
{
    color: #e0f2f1 !important;
}

.bg-teal.bg-lighten-5
{
    background-color: #e0f2f1 !important;
}
.bg-teal.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(224, 242, 241, .6);
            box-shadow: 0 1px 20px 1px rgba(224, 242, 241, .6);
}

.btn-teal.btn-lighten-5
{
    border-color: #00796b !important;
    background-color: #e0f2f1 !important;
}
.btn-teal.btn-lighten-5:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-5:focus,
.btn-teal.btn-lighten-5:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-5
{
    color: #e0f2f1 !important; 
    border-color: #e0f2f1 !important;
}
.btn-outline-teal.btn-outline-lighten-5:hover
{
    background-color: #e0f2f1 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0f2f1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #e0f2f1 !important;
}

.border-teal.border-lighten-5
{
    border: 1px solid #e0f2f1 !important;
}

.border-top-teal.border-top-lighten-5
{
    border-top: 1px solid #e0f2f1 !important;
}

.border-bottom-teal.border-bottom-lighten-5
{
    border-bottom: 1px solid #e0f2f1 !important;
}

.border-left-teal.border-left-lighten-5
{
    border-left: 1px solid #e0f2f1 !important;
}

.border-right-teal.border-right-lighten-5
{
    border-right: 1px solid #e0f2f1 !important;
}

.overlay-teal.overlay-lighten-5
{
    background: #e0f2f1;
    /* The Fallback */
    background: rgba(224, 242, 241, .8);
}

.teal.lighten-4
{
    color: #b2dfdb !important;
}

.bg-teal.bg-lighten-4
{
    background-color: #b2dfdb !important;
}
.bg-teal.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(178, 223, 219, .6);
            box-shadow: 0 1px 20px 1px rgba(178, 223, 219, .6);
}

.btn-teal.btn-lighten-4
{
    border-color: #00796b !important;
    background-color: #b2dfdb !important;
}
.btn-teal.btn-lighten-4:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-4:focus,
.btn-teal.btn-lighten-4:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-4
{
    color: #b2dfdb !important; 
    border-color: #b2dfdb !important;
}
.btn-outline-teal.btn-outline-lighten-4:hover
{
    background-color: #b2dfdb !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2dfdb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b2dfdb !important;
}

.border-teal.border-lighten-4
{
    border: 1px solid #b2dfdb !important;
}

.border-top-teal.border-top-lighten-4
{
    border-top: 1px solid #b2dfdb !important;
}

.border-bottom-teal.border-bottom-lighten-4
{
    border-bottom: 1px solid #b2dfdb !important;
}

.border-left-teal.border-left-lighten-4
{
    border-left: 1px solid #b2dfdb !important;
}

.border-right-teal.border-right-lighten-4
{
    border-right: 1px solid #b2dfdb !important;
}

.overlay-teal.overlay-lighten-4
{
    background: #b2dfdb;
    /* The Fallback */
    background: rgba(178, 223, 219, .8);
}

.teal.lighten-3
{
    color: #80cbc4 !important;
}

.bg-teal.bg-lighten-3
{
    background-color: #80cbc4 !important;
}
.bg-teal.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(128, 203, 196, .6);
            box-shadow: 0 1px 20px 1px rgba(128, 203, 196, .6);
}

.btn-teal.btn-lighten-3
{
    border-color: #00796b !important;
    background-color: #80cbc4 !important;
}
.btn-teal.btn-lighten-3:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-3:focus,
.btn-teal.btn-lighten-3:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-3
{
    color: #80cbc4 !important; 
    border-color: #80cbc4 !important;
}
.btn-outline-teal.btn-outline-lighten-3:hover
{
    background-color: #80cbc4 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80cbc4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #80cbc4 !important;
}

.border-teal.border-lighten-3
{
    border: 1px solid #80cbc4 !important;
}

.border-top-teal.border-top-lighten-3
{
    border-top: 1px solid #80cbc4 !important;
}

.border-bottom-teal.border-bottom-lighten-3
{
    border-bottom: 1px solid #80cbc4 !important;
}

.border-left-teal.border-left-lighten-3
{
    border-left: 1px solid #80cbc4 !important;
}

.border-right-teal.border-right-lighten-3
{
    border-right: 1px solid #80cbc4 !important;
}

.overlay-teal.overlay-lighten-3
{
    background: #80cbc4;
    /* The Fallback */
    background: rgba(128, 203, 196, .8);
}

.teal.lighten-2
{
    color: #4db6ac !important;
}

.bg-teal.bg-lighten-2
{
    background-color: #4db6ac !important;
}
.bg-teal.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(77, 182, 172, .6);
            box-shadow: 0 1px 20px 1px rgba(77, 182, 172, .6);
}

.btn-teal.btn-lighten-2
{
    border-color: #00796b !important;
    background-color: #4db6ac !important;
}
.btn-teal.btn-lighten-2:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-2:focus,
.btn-teal.btn-lighten-2:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-2
{
    color: #4db6ac !important; 
    border-color: #4db6ac !important;
}
.btn-outline-teal.btn-outline-lighten-2:hover
{
    background-color: #4db6ac !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4db6ac !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4db6ac !important;
}

.border-teal.border-lighten-2
{
    border: 1px solid #4db6ac !important;
}

.border-top-teal.border-top-lighten-2
{
    border-top: 1px solid #4db6ac !important;
}

.border-bottom-teal.border-bottom-lighten-2
{
    border-bottom: 1px solid #4db6ac !important;
}

.border-left-teal.border-left-lighten-2
{
    border-left: 1px solid #4db6ac !important;
}

.border-right-teal.border-right-lighten-2
{
    border-right: 1px solid #4db6ac !important;
}

.overlay-teal.overlay-lighten-2
{
    background: #4db6ac;
    /* The Fallback */
    background: rgba(77, 182, 172, .8);
}

.teal.lighten-1
{
    color: #26a69a !important;
}

.bg-teal.bg-lighten-1
{
    background-color: #26a69a !important;
}
.bg-teal.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(38, 166, 154, .6);
            box-shadow: 0 1px 20px 1px rgba(38, 166, 154, .6);
}

.btn-teal.btn-lighten-1
{
    border-color: #00796b !important;
    background-color: #26a69a !important;
}
.btn-teal.btn-lighten-1:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-1:focus,
.btn-teal.btn-lighten-1:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-lighten-1
{
    color: #26a69a !important; 
    border-color: #26a69a !important;
}
.btn-outline-teal.btn-outline-lighten-1:hover
{
    background-color: #26a69a !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #26a69a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #26a69a !important;
}

.border-teal.border-lighten-1
{
    border: 1px solid #26a69a !important;
}

.border-top-teal.border-top-lighten-1
{
    border-top: 1px solid #26a69a !important;
}

.border-bottom-teal.border-bottom-lighten-1
{
    border-bottom: 1px solid #26a69a !important;
}

.border-left-teal.border-left-lighten-1
{
    border-left: 1px solid #26a69a !important;
}

.border-right-teal.border-right-lighten-1
{
    border-right: 1px solid #26a69a !important;
}

.overlay-teal.overlay-lighten-1
{
    background: #26a69a;
    /* The Fallback */
    background: rgba(38, 166, 154, .8);
}

.teal
{
    color: #009688 !important;
}

.bg-teal
{
    background-color: #009688 !important;
}
.bg-teal .card-header,
.bg-teal .card-footer
{
    background-color: transparent;
}
.bg-teal.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
}

.selectboxit.selectboxit-btn.bg-teal
{
    background-color: #009688 !important;
}

.alert-teal
{
    color: black !important; 
    border-color: #009688 !important;
    background-color: #00ddc9 !important;
}
.alert-teal .alert-link
{
    color: black !important;
}

.border-teal
{
    border-color: #009688;
}

.overlay-teal
{
    background: #009688;
    /* The Fallback */
    background: rgba(0, 150, 136, .8);
}

/* .card-outline-teal{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-teal
{
    color: #fff; 
    border-color: #00796b !important;
    background-color: #009688 !important;
}
.btn-teal:hover
{
    color: #fff !important; 
    border-color: #00897b !important;
    background-color: #26a69a !important;
}
.btn-teal:focus,
.btn-teal:active
{
    color: #fff !important; 
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
}

.btn-outline-teal
{
    color: #009688; 
    border-color: #009688;
    background-color: transparent;
}
.btn-outline-teal:hover
{
    color: #fff !important; 
    background-color: #009688;
}
.btn-outline-teal.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 150, 136, .6);
}

input[type='checkbox'].bg-teal + .custom-control-label:before,
input[type='radio'].bg-teal + .custom-control-label:before
{
    background-color: #009688 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #009688 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #009688 !important;
}

.border-teal
{
    border: 1px solid #009688 !important;
}

.border-top-teal
{
    border-top: 1px solid #009688;
}

.border-bottom-teal
{
    border-bottom: 1px solid #009688;
}

.border-left-teal
{
    border-left: 1px solid #009688;
}

.border-right-teal
{
    border-right: 1px solid #009688;
}

.teal.darken-1
{
    color: #00897b !important;
}

.bg-teal.bg-darken-1
{
    background-color: #00897b !important;
}
.bg-teal.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 137, 123, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 137, 123, .6);
}

.btn-teal.btn-darken-1
{
    border-color: #00796b !important;
    background-color: #00897b !important;
}
.btn-teal.btn-darken-1:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-1:focus,
.btn-teal.btn-darken-1:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-darken-1
{
    color: #00897b !important; 
    border-color: #00897b !important;
}
.btn-outline-teal.btn-outline-darken-1:hover
{
    background-color: #00897b !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00897b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00897b !important;
}

.border-teal.border-darken-1
{
    border: 1px solid #00897b !important;
}

.border-top-teal.border-top-darken-1
{
    border-top: 1px solid #00897b !important;
}

.border-bottom-teal.border-bottom-darken-1
{
    border-bottom: 1px solid #00897b !important;
}

.border-left-teal.border-left-darken-1
{
    border-left: 1px solid #00897b !important;
}

.border-right-teal.border-right-darken-1
{
    border-right: 1px solid #00897b !important;
}

.overlay-teal.overlay-darken-1
{
    background: #00897b;
    /* The Fallback */
    background: rgba(0, 137, 123, .8);
}

.teal.darken-2
{
    color: #00796b !important;
}

.bg-teal.bg-darken-2
{
    background-color: #00796b !important;
}
.bg-teal.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 121, 107, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 121, 107, .6);
}

.btn-teal.btn-darken-2
{
    border-color: #00796b !important;
    background-color: #00796b !important;
}
.btn-teal.btn-darken-2:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-2:focus,
.btn-teal.btn-darken-2:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-darken-2
{
    color: #00796b !important; 
    border-color: #00796b !important;
}
.btn-outline-teal.btn-outline-darken-2:hover
{
    background-color: #00796b !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00796b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00796b !important;
}

.border-teal.border-darken-2
{
    border: 1px solid #00796b !important;
}

.border-top-teal.border-top-darken-2
{
    border-top: 1px solid #00796b !important;
}

.border-bottom-teal.border-bottom-darken-2
{
    border-bottom: 1px solid #00796b !important;
}

.border-left-teal.border-left-darken-2
{
    border-left: 1px solid #00796b !important;
}

.border-right-teal.border-right-darken-2
{
    border-right: 1px solid #00796b !important;
}

.overlay-teal.overlay-darken-2
{
    background: #00796b;
    /* The Fallback */
    background: rgba(0, 121, 107, .8);
}

.teal.darken-3
{
    color: #00695c !important;
}

.bg-teal.bg-darken-3
{
    background-color: #00695c !important;
}
.bg-teal.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 105, 92, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 105, 92, .6);
}

.btn-teal.btn-darken-3
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-3:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-3:focus,
.btn-teal.btn-darken-3:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-darken-3
{
    color: #00695c !important; 
    border-color: #00695c !important;
}
.btn-outline-teal.btn-outline-darken-3:hover
{
    background-color: #00695c !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00695c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00695c !important;
}

.border-teal.border-darken-3
{
    border: 1px solid #00695c !important;
}

.border-top-teal.border-top-darken-3
{
    border-top: 1px solid #00695c !important;
}

.border-bottom-teal.border-bottom-darken-3
{
    border-bottom: 1px solid #00695c !important;
}

.border-left-teal.border-left-darken-3
{
    border-left: 1px solid #00695c !important;
}

.border-right-teal.border-right-darken-3
{
    border-right: 1px solid #00695c !important;
}

.overlay-teal.overlay-darken-3
{
    background: #00695c;
    /* The Fallback */
    background: rgba(0, 105, 92, .8);
}

.teal.darken-4
{
    color: #004d40 !important;
}

.bg-teal.bg-darken-4
{
    background-color: #004d40 !important;
}
.bg-teal.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 77, 64, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 77, 64, .6);
}

.btn-teal.btn-darken-4
{
    border-color: #00796b !important;
    background-color: #004d40 !important;
}
.btn-teal.btn-darken-4:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-4:focus,
.btn-teal.btn-darken-4:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-darken-4
{
    color: #004d40 !important; 
    border-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-darken-4:hover
{
    background-color: #004d40 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #004d40 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #004d40 !important;
}

.border-teal.border-darken-4
{
    border: 1px solid #004d40 !important;
}

.border-top-teal.border-top-darken-4
{
    border-top: 1px solid #004d40 !important;
}

.border-bottom-teal.border-bottom-darken-4
{
    border-bottom: 1px solid #004d40 !important;
}

.border-left-teal.border-left-darken-4
{
    border-left: 1px solid #004d40 !important;
}

.border-right-teal.border-right-darken-4
{
    border-right: 1px solid #004d40 !important;
}

.overlay-teal.overlay-darken-4
{
    background: #004d40;
    /* The Fallback */
    background: rgba(0, 77, 64, .8);
}

.teal.accent-1
{
    color: #a7ffeb !important;
}

.bg-teal.bg-accent-1
{
    background-color: #a7ffeb !important;
}
.bg-teal.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(167, 255, 235, .6);
            box-shadow: 0 1px 20px 1px rgba(167, 255, 235, .6);
}

.btn-teal.btn-accent-1
{
    border-color: #00796b !important;
    background-color: #a7ffeb !important;
}
.btn-teal.btn-accent-1:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-accent-1:focus,
.btn-teal.btn-accent-1:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-accent-1
{
    color: #a7ffeb !important; 
    border-color: #a7ffeb !important;
}
.btn-outline-teal.btn-outline-accent-1:hover
{
    background-color: #a7ffeb !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a7ffeb !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #a7ffeb !important;
}

.border-teal.border-accent-1
{
    border: 1px solid #a7ffeb !important;
}

.border-top-teal.border-top-accent-1
{
    border-top: 1px solid #a7ffeb !important;
}

.border-bottom-teal.border-bottom-accent-1
{
    border-bottom: 1px solid #a7ffeb !important;
}

.border-left-teal.border-left-accent-1
{
    border-left: 1px solid #a7ffeb !important;
}

.border-right-teal.border-right-accent-1
{
    border-right: 1px solid #a7ffeb !important;
}

.overlay-teal.overlay-accent-1
{
    background: #a7ffeb;
    /* The Fallback */
    background: rgba(167, 255, 235, .8);
}

.teal.accent-2
{
    color: #64ffda !important;
}

.bg-teal.bg-accent-2
{
    background-color: #64ffda !important;
}
.bg-teal.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(100, 255, 218, .6);
            box-shadow: 0 1px 20px 1px rgba(100, 255, 218, .6);
}

.btn-teal.btn-accent-2
{
    border-color: #00796b !important;
    background-color: #64ffda !important;
}
.btn-teal.btn-accent-2:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-accent-2:focus,
.btn-teal.btn-accent-2:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-accent-2
{
    color: #64ffda !important; 
    border-color: #64ffda !important;
}
.btn-outline-teal.btn-outline-accent-2:hover
{
    background-color: #64ffda !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64ffda !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #64ffda !important;
}

.border-teal.border-accent-2
{
    border: 1px solid #64ffda !important;
}

.border-top-teal.border-top-accent-2
{
    border-top: 1px solid #64ffda !important;
}

.border-bottom-teal.border-bottom-accent-2
{
    border-bottom: 1px solid #64ffda !important;
}

.border-left-teal.border-left-accent-2
{
    border-left: 1px solid #64ffda !important;
}

.border-right-teal.border-right-accent-2
{
    border-right: 1px solid #64ffda !important;
}

.overlay-teal.overlay-accent-2
{
    background: #64ffda;
    /* The Fallback */
    background: rgba(100, 255, 218, .8);
}

.teal.accent-3
{
    color: #1de9b6 !important;
}

.bg-teal.bg-accent-3
{
    background-color: #1de9b6 !important;
}
.bg-teal.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(29, 233, 182, .6);
            box-shadow: 0 1px 20px 1px rgba(29, 233, 182, .6);
}

.btn-teal.btn-accent-3
{
    border-color: #00796b !important;
    background-color: #1de9b6 !important;
}
.btn-teal.btn-accent-3:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-accent-3:focus,
.btn-teal.btn-accent-3:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-accent-3
{
    color: #1de9b6 !important; 
    border-color: #1de9b6 !important;
}
.btn-outline-teal.btn-outline-accent-3:hover
{
    background-color: #1de9b6 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1de9b6 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1de9b6 !important;
}

.border-teal.border-accent-3
{
    border: 1px solid #1de9b6 !important;
}

.border-top-teal.border-top-accent-3
{
    border-top: 1px solid #1de9b6 !important;
}

.border-bottom-teal.border-bottom-accent-3
{
    border-bottom: 1px solid #1de9b6 !important;
}

.border-left-teal.border-left-accent-3
{
    border-left: 1px solid #1de9b6 !important;
}

.border-right-teal.border-right-accent-3
{
    border-right: 1px solid #1de9b6 !important;
}

.overlay-teal.overlay-accent-3
{
    background: #1de9b6;
    /* The Fallback */
    background: rgba(29, 233, 182, .8);
}

.teal.accent-4
{
    color: #00bfa5 !important;
}

.bg-teal.bg-accent-4
{
    background-color: #00bfa5 !important;
}
.bg-teal.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 191, 165, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 191, 165, .6);
}

.btn-teal.btn-accent-4
{
    border-color: #00796b !important;
    background-color: #00bfa5 !important;
}
.btn-teal.btn-accent-4:hover
{
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-accent-4:focus,
.btn-teal.btn-accent-4:active
{
    border-color: #00695c !important;
    background-color: #004d40 !important;
}

.btn-outline-teal.btn-outline-accent-4
{
    color: #00bfa5 !important; 
    border-color: #00bfa5 !important;
}
.btn-outline-teal.btn-outline-accent-4:hover
{
    background-color: #00bfa5 !important;
}

input:focus ~ .bg-teal
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00bfa5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00bfa5 !important;
}

.border-teal.border-accent-4
{
    border: 1px solid #00bfa5 !important;
}

.border-top-teal.border-top-accent-4
{
    border-top: 1px solid #00bfa5 !important;
}

.border-bottom-teal.border-bottom-accent-4
{
    border-bottom: 1px solid #00bfa5 !important;
}

.border-left-teal.border-left-accent-4
{
    border-left: 1px solid #00bfa5 !important;
}

.border-right-teal.border-right-accent-4
{
    border-right: 1px solid #00bfa5 !important;
}

.overlay-teal.overlay-accent-4
{
    background: #00bfa5;
    /* The Fallback */
    background: rgba(0, 191, 165, .8);
}

.yellow.lighten-5
{
    color: #fffde7 !important;
}

.bg-yellow.bg-lighten-5
{
    background-color: #fffde7 !important;
}
.bg-yellow.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 253, 231, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 253, 231, .6);
}

.btn-yellow.btn-lighten-5
{
    border-color: #fbc02d !important;
    background-color: #fffde7 !important;
}
.btn-yellow.btn-lighten-5:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-5:focus,
.btn-yellow.btn-lighten-5:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-5
{
    color: #fffde7 !important; 
    border-color: #fffde7 !important;
}
.btn-outline-yellow.btn-outline-lighten-5:hover
{
    background-color: #fffde7 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fffde7 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fffde7 !important;
}

.border-yellow.border-lighten-5
{
    border: 1px solid #fffde7 !important;
}

.border-top-yellow.border-top-lighten-5
{
    border-top: 1px solid #fffde7 !important;
}

.border-bottom-yellow.border-bottom-lighten-5
{
    border-bottom: 1px solid #fffde7 !important;
}

.border-left-yellow.border-left-lighten-5
{
    border-left: 1px solid #fffde7 !important;
}

.border-right-yellow.border-right-lighten-5
{
    border-right: 1px solid #fffde7 !important;
}

.overlay-yellow.overlay-lighten-5
{
    background: #fffde7;
    /* The Fallback */
    background: rgba(255, 253, 231, .8);
}

.yellow.lighten-4
{
    color: #fff9c4 !important;
}

.bg-yellow.bg-lighten-4
{
    background-color: #fff9c4 !important;
}
.bg-yellow.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 249, 196, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 249, 196, .6);
}

.btn-yellow.btn-lighten-4
{
    border-color: #fbc02d !important;
    background-color: #fff9c4 !important;
}
.btn-yellow.btn-lighten-4:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-4:focus,
.btn-yellow.btn-lighten-4:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-4
{
    color: #fff9c4 !important; 
    border-color: #fff9c4 !important;
}
.btn-outline-yellow.btn-outline-lighten-4:hover
{
    background-color: #fff9c4 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff9c4 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff9c4 !important;
}

.border-yellow.border-lighten-4
{
    border: 1px solid #fff9c4 !important;
}

.border-top-yellow.border-top-lighten-4
{
    border-top: 1px solid #fff9c4 !important;
}

.border-bottom-yellow.border-bottom-lighten-4
{
    border-bottom: 1px solid #fff9c4 !important;
}

.border-left-yellow.border-left-lighten-4
{
    border-left: 1px solid #fff9c4 !important;
}

.border-right-yellow.border-right-lighten-4
{
    border-right: 1px solid #fff9c4 !important;
}

.overlay-yellow.overlay-lighten-4
{
    background: #fff9c4;
    /* The Fallback */
    background: rgba(255, 249, 196, .8);
}

.yellow.lighten-3
{
    color: #fff59d !important;
}

.bg-yellow.bg-lighten-3
{
    background-color: #fff59d !important;
}
.bg-yellow.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 245, 157, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 245, 157, .6);
}

.btn-yellow.btn-lighten-3
{
    border-color: #fbc02d !important;
    background-color: #fff59d !important;
}
.btn-yellow.btn-lighten-3:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-3:focus,
.btn-yellow.btn-lighten-3:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-3
{
    color: #fff59d !important; 
    border-color: #fff59d !important;
}
.btn-outline-yellow.btn-outline-lighten-3:hover
{
    background-color: #fff59d !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff59d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff59d !important;
}

.border-yellow.border-lighten-3
{
    border: 1px solid #fff59d !important;
}

.border-top-yellow.border-top-lighten-3
{
    border-top: 1px solid #fff59d !important;
}

.border-bottom-yellow.border-bottom-lighten-3
{
    border-bottom: 1px solid #fff59d !important;
}

.border-left-yellow.border-left-lighten-3
{
    border-left: 1px solid #fff59d !important;
}

.border-right-yellow.border-right-lighten-3
{
    border-right: 1px solid #fff59d !important;
}

.overlay-yellow.overlay-lighten-3
{
    background: #fff59d;
    /* The Fallback */
    background: rgba(255, 245, 157, .8);
}

.yellow.lighten-2
{
    color: #fff176 !important;
}

.bg-yellow.bg-lighten-2
{
    background-color: #fff176 !important;
}
.bg-yellow.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 241, 118, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 241, 118, .6);
}

.btn-yellow.btn-lighten-2
{
    border-color: #fbc02d !important;
    background-color: #fff176 !important;
}
.btn-yellow.btn-lighten-2:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-2:focus,
.btn-yellow.btn-lighten-2:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-2
{
    color: #fff176 !important; 
    border-color: #fff176 !important;
}
.btn-outline-yellow.btn-outline-lighten-2:hover
{
    background-color: #fff176 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff176 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff176 !important;
}

.border-yellow.border-lighten-2
{
    border: 1px solid #fff176 !important;
}

.border-top-yellow.border-top-lighten-2
{
    border-top: 1px solid #fff176 !important;
}

.border-bottom-yellow.border-bottom-lighten-2
{
    border-bottom: 1px solid #fff176 !important;
}

.border-left-yellow.border-left-lighten-2
{
    border-left: 1px solid #fff176 !important;
}

.border-right-yellow.border-right-lighten-2
{
    border-right: 1px solid #fff176 !important;
}

.overlay-yellow.overlay-lighten-2
{
    background: #fff176;
    /* The Fallback */
    background: rgba(255, 241, 118, .8);
}

.yellow.lighten-1
{
    color: #ffee58 !important;
}

.bg-yellow.bg-lighten-1
{
    background-color: #ffee58 !important;
}
.bg-yellow.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 238, 88, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 238, 88, .6);
}

.btn-yellow.btn-lighten-1
{
    border-color: #fbc02d !important;
    background-color: #ffee58 !important;
}
.btn-yellow.btn-lighten-1:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-1:focus,
.btn-yellow.btn-lighten-1:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-lighten-1
{
    color: #ffee58 !important; 
    border-color: #ffee58 !important;
}
.btn-outline-yellow.btn-outline-lighten-1:hover
{
    background-color: #ffee58 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffee58 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffee58 !important;
}

.border-yellow.border-lighten-1
{
    border: 1px solid #ffee58 !important;
}

.border-top-yellow.border-top-lighten-1
{
    border-top: 1px solid #ffee58 !important;
}

.border-bottom-yellow.border-bottom-lighten-1
{
    border-bottom: 1px solid #ffee58 !important;
}

.border-left-yellow.border-left-lighten-1
{
    border-left: 1px solid #ffee58 !important;
}

.border-right-yellow.border-right-lighten-1
{
    border-right: 1px solid #ffee58 !important;
}

.overlay-yellow.overlay-lighten-1
{
    background: #ffee58;
    /* The Fallback */
    background: rgba(255, 238, 88, .8);
}

.yellow
{
    color: #ffeb3b !important;
}

.bg-yellow
{
    background-color: #ffeb3b !important;
}
.bg-yellow .card-header,
.bg-yellow .card-footer
{
    background-color: transparent;
}
.bg-yellow.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
}

.selectboxit.selectboxit-btn.bg-yellow
{
    background-color: #ffeb3b !important;
}

.alert-yellow
{
    color: #887a00 !important; 
    border-color: #ffeb3b !important;
    background-color: #fff282 !important;
}
.alert-yellow .alert-link
{
    color: #5f5500 !important;
}

.border-yellow
{
    border-color: #ffeb3b;
}

.overlay-yellow
{
    background: #ffeb3b;
    /* The Fallback */
    background: rgba(255, 235, 59, .8);
}

/* .card-outline-yellow{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-yellow
{
    color: #fff; 
    border-color: #fbc02d !important;
    background-color: #ffeb3b !important;
}
.btn-yellow:hover
{
    color: #fff !important; 
    border-color: #fdd835 !important;
    background-color: #ffee58 !important;
}
.btn-yellow:focus,
.btn-yellow:active
{
    color: #fff !important; 
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
}

.btn-outline-yellow
{
    color: #ffeb3b; 
    border-color: #ffeb3b;
    background-color: transparent;
}
.btn-outline-yellow:hover
{
    color: #fff !important; 
    background-color: #ffeb3b;
}
.btn-outline-yellow.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 235, 59, .6);
}

input[type='checkbox'].bg-yellow + .custom-control-label:before,
input[type='radio'].bg-yellow + .custom-control-label:before
{
    background-color: #ffeb3b !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffeb3b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffeb3b !important;
}

.border-yellow
{
    border: 1px solid #ffeb3b !important;
}

.border-top-yellow
{
    border-top: 1px solid #ffeb3b;
}

.border-bottom-yellow
{
    border-bottom: 1px solid #ffeb3b;
}

.border-left-yellow
{
    border-left: 1px solid #ffeb3b;
}

.border-right-yellow
{
    border-right: 1px solid #ffeb3b;
}

.yellow.darken-1
{
    color: #fdd835 !important;
}

.bg-yellow.bg-darken-1
{
    background-color: #fdd835 !important;
}
.bg-yellow.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(253, 216, 53, .6);
            box-shadow: 0 1px 20px 1px rgba(253, 216, 53, .6);
}

.btn-yellow.btn-darken-1
{
    border-color: #fbc02d !important;
    background-color: #fdd835 !important;
}
.btn-yellow.btn-darken-1:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-1:focus,
.btn-yellow.btn-darken-1:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-darken-1
{
    color: #fdd835 !important; 
    border-color: #fdd835 !important;
}
.btn-outline-yellow.btn-outline-darken-1:hover
{
    background-color: #fdd835 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fdd835 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fdd835 !important;
}

.border-yellow.border-darken-1
{
    border: 1px solid #fdd835 !important;
}

.border-top-yellow.border-top-darken-1
{
    border-top: 1px solid #fdd835 !important;
}

.border-bottom-yellow.border-bottom-darken-1
{
    border-bottom: 1px solid #fdd835 !important;
}

.border-left-yellow.border-left-darken-1
{
    border-left: 1px solid #fdd835 !important;
}

.border-right-yellow.border-right-darken-1
{
    border-right: 1px solid #fdd835 !important;
}

.overlay-yellow.overlay-darken-1
{
    background: #fdd835;
    /* The Fallback */
    background: rgba(253, 216, 53, .8);
}

.yellow.darken-2
{
    color: #fbc02d !important;
}

.bg-yellow.bg-darken-2
{
    background-color: #fbc02d !important;
}
.bg-yellow.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(251, 192, 45, .6);
            box-shadow: 0 1px 20px 1px rgba(251, 192, 45, .6);
}

.btn-yellow.btn-darken-2
{
    border-color: #fbc02d !important;
    background-color: #fbc02d !important;
}
.btn-yellow.btn-darken-2:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-2:focus,
.btn-yellow.btn-darken-2:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-darken-2
{
    color: #fbc02d !important; 
    border-color: #fbc02d !important;
}
.btn-outline-yellow.btn-outline-darken-2:hover
{
    background-color: #fbc02d !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fbc02d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fbc02d !important;
}

.border-yellow.border-darken-2
{
    border: 1px solid #fbc02d !important;
}

.border-top-yellow.border-top-darken-2
{
    border-top: 1px solid #fbc02d !important;
}

.border-bottom-yellow.border-bottom-darken-2
{
    border-bottom: 1px solid #fbc02d !important;
}

.border-left-yellow.border-left-darken-2
{
    border-left: 1px solid #fbc02d !important;
}

.border-right-yellow.border-right-darken-2
{
    border-right: 1px solid #fbc02d !important;
}

.overlay-yellow.overlay-darken-2
{
    background: #fbc02d;
    /* The Fallback */
    background: rgba(251, 192, 45, .8);
}

.yellow.darken-3
{
    color: #f9a825 !important;
}

.bg-yellow.bg-darken-3
{
    background-color: #f9a825 !important;
}
.bg-yellow.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(249, 168, 37, .6);
            box-shadow: 0 1px 20px 1px rgba(249, 168, 37, .6);
}

.btn-yellow.btn-darken-3
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-3:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-3:focus,
.btn-yellow.btn-darken-3:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-darken-3
{
    color: #f9a825 !important; 
    border-color: #f9a825 !important;
}
.btn-outline-yellow.btn-outline-darken-3:hover
{
    background-color: #f9a825 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9a825 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f9a825 !important;
}

.border-yellow.border-darken-3
{
    border: 1px solid #f9a825 !important;
}

.border-top-yellow.border-top-darken-3
{
    border-top: 1px solid #f9a825 !important;
}

.border-bottom-yellow.border-bottom-darken-3
{
    border-bottom: 1px solid #f9a825 !important;
}

.border-left-yellow.border-left-darken-3
{
    border-left: 1px solid #f9a825 !important;
}

.border-right-yellow.border-right-darken-3
{
    border-right: 1px solid #f9a825 !important;
}

.overlay-yellow.overlay-darken-3
{
    background: #f9a825;
    /* The Fallback */
    background: rgba(249, 168, 37, .8);
}

.yellow.darken-4
{
    color: #f57f17 !important;
}

.bg-yellow.bg-darken-4
{
    background-color: #f57f17 !important;
}
.bg-yellow.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(245, 127, 23, .6);
            box-shadow: 0 1px 20px 1px rgba(245, 127, 23, .6);
}

.btn-yellow.btn-darken-4
{
    border-color: #fbc02d !important;
    background-color: #f57f17 !important;
}
.btn-yellow.btn-darken-4:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-4:focus,
.btn-yellow.btn-darken-4:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-darken-4
{
    color: #f57f17 !important; 
    border-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-darken-4:hover
{
    background-color: #f57f17 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f57f17 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f57f17 !important;
}

.border-yellow.border-darken-4
{
    border: 1px solid #f57f17 !important;
}

.border-top-yellow.border-top-darken-4
{
    border-top: 1px solid #f57f17 !important;
}

.border-bottom-yellow.border-bottom-darken-4
{
    border-bottom: 1px solid #f57f17 !important;
}

.border-left-yellow.border-left-darken-4
{
    border-left: 1px solid #f57f17 !important;
}

.border-right-yellow.border-right-darken-4
{
    border-right: 1px solid #f57f17 !important;
}

.overlay-yellow.overlay-darken-4
{
    background: #f57f17;
    /* The Fallback */
    background: rgba(245, 127, 23, .8);
}

.yellow.accent-1
{
    color: #ffff8d !important;
}

.bg-yellow.bg-accent-1
{
    background-color: #ffff8d !important;
}
.bg-yellow.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 141, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 141, .6);
}

.btn-yellow.btn-accent-1
{
    border-color: #fbc02d !important;
    background-color: #ffff8d !important;
}
.btn-yellow.btn-accent-1:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-1:focus,
.btn-yellow.btn-accent-1:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-accent-1
{
    color: #ffff8d !important; 
    border-color: #ffff8d !important;
}
.btn-outline-yellow.btn-outline-accent-1:hover
{
    background-color: #ffff8d !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffff8d !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffff8d !important;
}

.border-yellow.border-accent-1
{
    border: 1px solid #ffff8d !important;
}

.border-top-yellow.border-top-accent-1
{
    border-top: 1px solid #ffff8d !important;
}

.border-bottom-yellow.border-bottom-accent-1
{
    border-bottom: 1px solid #ffff8d !important;
}

.border-left-yellow.border-left-accent-1
{
    border-left: 1px solid #ffff8d !important;
}

.border-right-yellow.border-right-accent-1
{
    border-right: 1px solid #ffff8d !important;
}

.overlay-yellow.overlay-accent-1
{
    background: #ffff8d;
    /* The Fallback */
    background: rgba(255, 255, 141, .8);
}

.yellow.accent-2
{
    color: #ff0 !important;
}

.bg-yellow.bg-accent-2
{
    background-color: #ff0 !important;
}
.bg-yellow.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 0, .6);
}

.btn-yellow.btn-accent-2
{
    border-color: #fbc02d !important;
    background-color: #ff0 !important;
}
.btn-yellow.btn-accent-2:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-2:focus,
.btn-yellow.btn-accent-2:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-accent-2
{
    color: #ff0 !important; 
    border-color: #ff0 !important;
}
.btn-outline-yellow.btn-outline-accent-2:hover
{
    background-color: #ff0 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff0 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff0 !important;
}

.border-yellow.border-accent-2
{
    border: 1px solid #ff0 !important;
}

.border-top-yellow.border-top-accent-2
{
    border-top: 1px solid #ff0 !important;
}

.border-bottom-yellow.border-bottom-accent-2
{
    border-bottom: 1px solid #ff0 !important;
}

.border-left-yellow.border-left-accent-2
{
    border-left: 1px solid #ff0 !important;
}

.border-right-yellow.border-right-accent-2
{
    border-right: 1px solid #ff0 !important;
}

.overlay-yellow.overlay-accent-2
{
    background: #ff0;
    /* The Fallback */
    background: rgba(255, 255, 0, .8);
}

.yellow.accent-3
{
    color: #ffea00 !important;
}

.bg-yellow.bg-accent-3
{
    background-color: #ffea00 !important;
}
.bg-yellow.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 234, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 234, 0, .6);
}

.btn-yellow.btn-accent-3
{
    border-color: #fbc02d !important;
    background-color: #ffea00 !important;
}
.btn-yellow.btn-accent-3:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-3:focus,
.btn-yellow.btn-accent-3:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-accent-3
{
    color: #ffea00 !important; 
    border-color: #ffea00 !important;
}
.btn-outline-yellow.btn-outline-accent-3:hover
{
    background-color: #ffea00 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffea00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffea00 !important;
}

.border-yellow.border-accent-3
{
    border: 1px solid #ffea00 !important;
}

.border-top-yellow.border-top-accent-3
{
    border-top: 1px solid #ffea00 !important;
}

.border-bottom-yellow.border-bottom-accent-3
{
    border-bottom: 1px solid #ffea00 !important;
}

.border-left-yellow.border-left-accent-3
{
    border-left: 1px solid #ffea00 !important;
}

.border-right-yellow.border-right-accent-3
{
    border-right: 1px solid #ffea00 !important;
}

.overlay-yellow.overlay-accent-3
{
    background: #ffea00;
    /* The Fallback */
    background: rgba(255, 234, 0, .8);
}

.yellow.accent-4
{
    color: #ffd600 !important;
}

.bg-yellow.bg-accent-4
{
    background-color: #ffd600 !important;
}
.bg-yellow.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 214, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 214, 0, .6);
}

.btn-yellow.btn-accent-4
{
    border-color: #fbc02d !important;
    background-color: #ffd600 !important;
}
.btn-yellow.btn-accent-4:hover
{
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-4:focus,
.btn-yellow.btn-accent-4:active
{
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}

.btn-outline-yellow.btn-outline-accent-4
{
    color: #ffd600 !important; 
    border-color: #ffd600 !important;
}
.btn-outline-yellow.btn-outline-accent-4:hover
{
    background-color: #ffd600 !important;
}

input:focus ~ .bg-yellow
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd600 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd600 !important;
}

.border-yellow.border-accent-4
{
    border: 1px solid #ffd600 !important;
}

.border-top-yellow.border-top-accent-4
{
    border-top: 1px solid #ffd600 !important;
}

.border-bottom-yellow.border-bottom-accent-4
{
    border-bottom: 1px solid #ffd600 !important;
}

.border-left-yellow.border-left-accent-4
{
    border-left: 1px solid #ffd600 !important;
}

.border-right-yellow.border-right-accent-4
{
    border-right: 1px solid #ffd600 !important;
}

.overlay-yellow.overlay-accent-4
{
    background: #ffd600;
    /* The Fallback */
    background: rgba(255, 214, 0, .8);
}

.amber.lighten-5
{
    color: #fff8e1 !important;
}

.bg-amber.bg-lighten-5
{
    background-color: #fff8e1 !important;
}
.bg-amber.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 248, 225, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 248, 225, .6);
}

.btn-amber.btn-lighten-5
{
    border-color: #ffa000 !important;
    background-color: #fff8e1 !important;
}
.btn-amber.btn-lighten-5:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-5:focus,
.btn-amber.btn-lighten-5:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-5
{
    color: #fff8e1 !important; 
    border-color: #fff8e1 !important;
}
.btn-outline-amber.btn-outline-lighten-5:hover
{
    background-color: #fff8e1 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff8e1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff8e1 !important;
}

.border-amber.border-lighten-5
{
    border: 1px solid #fff8e1 !important;
}

.border-top-amber.border-top-lighten-5
{
    border-top: 1px solid #fff8e1 !important;
}

.border-bottom-amber.border-bottom-lighten-5
{
    border-bottom: 1px solid #fff8e1 !important;
}

.border-left-amber.border-left-lighten-5
{
    border-left: 1px solid #fff8e1 !important;
}

.border-right-amber.border-right-lighten-5
{
    border-right: 1px solid #fff8e1 !important;
}

.overlay-amber.overlay-lighten-5
{
    background: #fff8e1;
    /* The Fallback */
    background: rgba(255, 248, 225, .8);
}

.amber.lighten-4
{
    color: #ffecb3 !important;
}

.bg-amber.bg-lighten-4
{
    background-color: #ffecb3 !important;
}
.bg-amber.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 236, 179, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 236, 179, .6);
}

.btn-amber.btn-lighten-4
{
    border-color: #ffa000 !important;
    background-color: #ffecb3 !important;
}
.btn-amber.btn-lighten-4:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-4:focus,
.btn-amber.btn-lighten-4:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-4
{
    color: #ffecb3 !important; 
    border-color: #ffecb3 !important;
}
.btn-outline-amber.btn-outline-lighten-4:hover
{
    background-color: #ffecb3 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffecb3 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffecb3 !important;
}

.border-amber.border-lighten-4
{
    border: 1px solid #ffecb3 !important;
}

.border-top-amber.border-top-lighten-4
{
    border-top: 1px solid #ffecb3 !important;
}

.border-bottom-amber.border-bottom-lighten-4
{
    border-bottom: 1px solid #ffecb3 !important;
}

.border-left-amber.border-left-lighten-4
{
    border-left: 1px solid #ffecb3 !important;
}

.border-right-amber.border-right-lighten-4
{
    border-right: 1px solid #ffecb3 !important;
}

.overlay-amber.overlay-lighten-4
{
    background: #ffecb3;
    /* The Fallback */
    background: rgba(255, 236, 179, .8);
}

.amber.lighten-3
{
    color: #ffe082 !important;
}

.bg-amber.bg-lighten-3
{
    background-color: #ffe082 !important;
}
.bg-amber.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 224, 130, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 224, 130, .6);
}

.btn-amber.btn-lighten-3
{
    border-color: #ffa000 !important;
    background-color: #ffe082 !important;
}
.btn-amber.btn-lighten-3:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-3:focus,
.btn-amber.btn-lighten-3:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-3
{
    color: #ffe082 !important; 
    border-color: #ffe082 !important;
}
.btn-outline-amber.btn-outline-lighten-3:hover
{
    background-color: #ffe082 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe082 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe082 !important;
}

.border-amber.border-lighten-3
{
    border: 1px solid #ffe082 !important;
}

.border-top-amber.border-top-lighten-3
{
    border-top: 1px solid #ffe082 !important;
}

.border-bottom-amber.border-bottom-lighten-3
{
    border-bottom: 1px solid #ffe082 !important;
}

.border-left-amber.border-left-lighten-3
{
    border-left: 1px solid #ffe082 !important;
}

.border-right-amber.border-right-lighten-3
{
    border-right: 1px solid #ffe082 !important;
}

.overlay-amber.overlay-lighten-3
{
    background: #ffe082;
    /* The Fallback */
    background: rgba(255, 224, 130, .8);
}

.amber.lighten-2
{
    color: #ffd54f !important;
}

.bg-amber.bg-lighten-2
{
    background-color: #ffd54f !important;
}
.bg-amber.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 213, 79, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 213, 79, .6);
}

.btn-amber.btn-lighten-2
{
    border-color: #ffa000 !important;
    background-color: #ffd54f !important;
}
.btn-amber.btn-lighten-2:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-2:focus,
.btn-amber.btn-lighten-2:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-2
{
    color: #ffd54f !important; 
    border-color: #ffd54f !important;
}
.btn-outline-amber.btn-outline-lighten-2:hover
{
    background-color: #ffd54f !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd54f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd54f !important;
}

.border-amber.border-lighten-2
{
    border: 1px solid #ffd54f !important;
}

.border-top-amber.border-top-lighten-2
{
    border-top: 1px solid #ffd54f !important;
}

.border-bottom-amber.border-bottom-lighten-2
{
    border-bottom: 1px solid #ffd54f !important;
}

.border-left-amber.border-left-lighten-2
{
    border-left: 1px solid #ffd54f !important;
}

.border-right-amber.border-right-lighten-2
{
    border-right: 1px solid #ffd54f !important;
}

.overlay-amber.overlay-lighten-2
{
    background: #ffd54f;
    /* The Fallback */
    background: rgba(255, 213, 79, .8);
}

.amber.lighten-1
{
    color: #ffca28 !important;
}

.bg-amber.bg-lighten-1
{
    background-color: #ffca28 !important;
}
.bg-amber.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 202, 40, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 202, 40, .6);
}

.btn-amber.btn-lighten-1
{
    border-color: #ffa000 !important;
    background-color: #ffca28 !important;
}
.btn-amber.btn-lighten-1:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-1:focus,
.btn-amber.btn-lighten-1:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-lighten-1
{
    color: #ffca28 !important; 
    border-color: #ffca28 !important;
}
.btn-outline-amber.btn-outline-lighten-1:hover
{
    background-color: #ffca28 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffca28 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffca28 !important;
}

.border-amber.border-lighten-1
{
    border: 1px solid #ffca28 !important;
}

.border-top-amber.border-top-lighten-1
{
    border-top: 1px solid #ffca28 !important;
}

.border-bottom-amber.border-bottom-lighten-1
{
    border-bottom: 1px solid #ffca28 !important;
}

.border-left-amber.border-left-lighten-1
{
    border-left: 1px solid #ffca28 !important;
}

.border-right-amber.border-right-lighten-1
{
    border-right: 1px solid #ffca28 !important;
}

.overlay-amber.overlay-lighten-1
{
    background: #ffca28;
    /* The Fallback */
    background: rgba(255, 202, 40, .8);
}

.amber
{
    color: #ffc107 !important;
}

.bg-amber
{
    background-color: #ffc107 !important;
}
.bg-amber .card-header,
.bg-amber .card-footer
{
    background-color: transparent;
}
.bg-amber.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
}

.selectboxit.selectboxit-btn.bg-amber
{
    background-color: #ffc107 !important;
}

.alert-amber
{
    color: #543f00 !important; 
    border-color: #ffc107 !important;
    background-color: #ffd34e !important;
}
.alert-amber .alert-link
{
    color: #2b2000 !important;
}

.border-amber
{
    border-color: #ffc107;
}

.overlay-amber
{
    background: #ffc107;
    /* The Fallback */
    background: rgba(255, 193, 7, .8);
}

/* .card-outline-amber{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-amber
{
    color: #fff; 
    border-color: #ffa000 !important;
    background-color: #ffc107 !important;
}
.btn-amber:hover
{
    color: #fff !important; 
    border-color: #ffb300 !important;
    background-color: #ffca28 !important;
}
.btn-amber:focus,
.btn-amber:active
{
    color: #fff !important; 
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
}

.btn-outline-amber
{
    color: #ffc107; 
    border-color: #ffc107;
    background-color: transparent;
}
.btn-outline-amber:hover
{
    color: #fff !important; 
    background-color: #ffc107;
}
.btn-outline-amber.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 193, 7, .6);
}

input[type='checkbox'].bg-amber + .custom-control-label:before,
input[type='radio'].bg-amber + .custom-control-label:before
{
    background-color: #ffc107 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc107 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc107 !important;
}

.border-amber
{
    border: 1px solid #ffc107 !important;
}

.border-top-amber
{
    border-top: 1px solid #ffc107;
}

.border-bottom-amber
{
    border-bottom: 1px solid #ffc107;
}

.border-left-amber
{
    border-left: 1px solid #ffc107;
}

.border-right-amber
{
    border-right: 1px solid #ffc107;
}

.amber.darken-1
{
    color: #ffb300 !important;
}

.bg-amber.bg-darken-1
{
    background-color: #ffb300 !important;
}
.bg-amber.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 179, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 179, 0, .6);
}

.btn-amber.btn-darken-1
{
    border-color: #ffa000 !important;
    background-color: #ffb300 !important;
}
.btn-amber.btn-darken-1:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-1:focus,
.btn-amber.btn-darken-1:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-darken-1
{
    color: #ffb300 !important; 
    border-color: #ffb300 !important;
}
.btn-outline-amber.btn-outline-darken-1:hover
{
    background-color: #ffb300 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb300 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffb300 !important;
}

.border-amber.border-darken-1
{
    border: 1px solid #ffb300 !important;
}

.border-top-amber.border-top-darken-1
{
    border-top: 1px solid #ffb300 !important;
}

.border-bottom-amber.border-bottom-darken-1
{
    border-bottom: 1px solid #ffb300 !important;
}

.border-left-amber.border-left-darken-1
{
    border-left: 1px solid #ffb300 !important;
}

.border-right-amber.border-right-darken-1
{
    border-right: 1px solid #ffb300 !important;
}

.overlay-amber.overlay-darken-1
{
    background: #ffb300;
    /* The Fallback */
    background: rgba(255, 179, 0, .8);
}

.amber.darken-2
{
    color: #ffa000 !important;
}

.bg-amber.bg-darken-2
{
    background-color: #ffa000 !important;
}
.bg-amber.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 160, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 160, 0, .6);
}

.btn-amber.btn-darken-2
{
    border-color: #ffa000 !important;
    background-color: #ffa000 !important;
}
.btn-amber.btn-darken-2:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-2:focus,
.btn-amber.btn-darken-2:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-darken-2
{
    color: #ffa000 !important; 
    border-color: #ffa000 !important;
}
.btn-outline-amber.btn-outline-darken-2:hover
{
    background-color: #ffa000 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa000 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffa000 !important;
}

.border-amber.border-darken-2
{
    border: 1px solid #ffa000 !important;
}

.border-top-amber.border-top-darken-2
{
    border-top: 1px solid #ffa000 !important;
}

.border-bottom-amber.border-bottom-darken-2
{
    border-bottom: 1px solid #ffa000 !important;
}

.border-left-amber.border-left-darken-2
{
    border-left: 1px solid #ffa000 !important;
}

.border-right-amber.border-right-darken-2
{
    border-right: 1px solid #ffa000 !important;
}

.overlay-amber.overlay-darken-2
{
    background: #ffa000;
    /* The Fallback */
    background: rgba(255, 160, 0, .8);
}

.amber.darken-3
{
    color: #ff8f00 !important;
}

.bg-amber.bg-darken-3
{
    background-color: #ff8f00 !important;
}
.bg-amber.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 143, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 143, 0, .6);
}

.btn-amber.btn-darken-3
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-3:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-3:focus,
.btn-amber.btn-darken-3:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-darken-3
{
    color: #ff8f00 !important; 
    border-color: #ff8f00 !important;
}
.btn-outline-amber.btn-outline-darken-3:hover
{
    background-color: #ff8f00 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8f00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff8f00 !important;
}

.border-amber.border-darken-3
{
    border: 1px solid #ff8f00 !important;
}

.border-top-amber.border-top-darken-3
{
    border-top: 1px solid #ff8f00 !important;
}

.border-bottom-amber.border-bottom-darken-3
{
    border-bottom: 1px solid #ff8f00 !important;
}

.border-left-amber.border-left-darken-3
{
    border-left: 1px solid #ff8f00 !important;
}

.border-right-amber.border-right-darken-3
{
    border-right: 1px solid #ff8f00 !important;
}

.overlay-amber.overlay-darken-3
{
    background: #ff8f00;
    /* The Fallback */
    background: rgba(255, 143, 0, .8);
}

.amber.darken-4
{
    color: #ff6f00 !important;
}

.bg-amber.bg-darken-4
{
    background-color: #ff6f00 !important;
}
.bg-amber.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 111, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 111, 0, .6);
}

.btn-amber.btn-darken-4
{
    border-color: #ffa000 !important;
    background-color: #ff6f00 !important;
}
.btn-amber.btn-darken-4:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-4:focus,
.btn-amber.btn-darken-4:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-darken-4
{
    color: #ff6f00 !important; 
    border-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-darken-4:hover
{
    background-color: #ff6f00 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6f00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff6f00 !important;
}

.border-amber.border-darken-4
{
    border: 1px solid #ff6f00 !important;
}

.border-top-amber.border-top-darken-4
{
    border-top: 1px solid #ff6f00 !important;
}

.border-bottom-amber.border-bottom-darken-4
{
    border-bottom: 1px solid #ff6f00 !important;
}

.border-left-amber.border-left-darken-4
{
    border-left: 1px solid #ff6f00 !important;
}

.border-right-amber.border-right-darken-4
{
    border-right: 1px solid #ff6f00 !important;
}

.overlay-amber.overlay-darken-4
{
    background: #ff6f00;
    /* The Fallback */
    background: rgba(255, 111, 0, .8);
}

.amber.accent-1
{
    color: #ffe57f !important;
}

.bg-amber.bg-accent-1
{
    background-color: #ffe57f !important;
}
.bg-amber.bg-accent-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 229, 127, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 229, 127, .6);
}

.btn-amber.btn-accent-1
{
    border-color: #ffa000 !important;
    background-color: #ffe57f !important;
}
.btn-amber.btn-accent-1:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-1:focus,
.btn-amber.btn-accent-1:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-accent-1
{
    color: #ffe57f !important; 
    border-color: #ffe57f !important;
}
.btn-outline-amber.btn-outline-accent-1:hover
{
    background-color: #ffe57f !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe57f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffe57f !important;
}

.border-amber.border-accent-1
{
    border: 1px solid #ffe57f !important;
}

.border-top-amber.border-top-accent-1
{
    border-top: 1px solid #ffe57f !important;
}

.border-bottom-amber.border-bottom-accent-1
{
    border-bottom: 1px solid #ffe57f !important;
}

.border-left-amber.border-left-accent-1
{
    border-left: 1px solid #ffe57f !important;
}

.border-right-amber.border-right-accent-1
{
    border-right: 1px solid #ffe57f !important;
}

.overlay-amber.overlay-accent-1
{
    background: #ffe57f;
    /* The Fallback */
    background: rgba(255, 229, 127, .8);
}

.amber.accent-2
{
    color: #ffd740 !important;
}

.bg-amber.bg-accent-2
{
    background-color: #ffd740 !important;
}
.bg-amber.bg-accent-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 215, 64, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 215, 64, .6);
}

.btn-amber.btn-accent-2
{
    border-color: #ffa000 !important;
    background-color: #ffd740 !important;
}
.btn-amber.btn-accent-2:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-2:focus,
.btn-amber.btn-accent-2:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-accent-2
{
    color: #ffd740 !important; 
    border-color: #ffd740 !important;
}
.btn-outline-amber.btn-outline-accent-2:hover
{
    background-color: #ffd740 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd740 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffd740 !important;
}

.border-amber.border-accent-2
{
    border: 1px solid #ffd740 !important;
}

.border-top-amber.border-top-accent-2
{
    border-top: 1px solid #ffd740 !important;
}

.border-bottom-amber.border-bottom-accent-2
{
    border-bottom: 1px solid #ffd740 !important;
}

.border-left-amber.border-left-accent-2
{
    border-left: 1px solid #ffd740 !important;
}

.border-right-amber.border-right-accent-2
{
    border-right: 1px solid #ffd740 !important;
}

.overlay-amber.overlay-accent-2
{
    background: #ffd740;
    /* The Fallback */
    background: rgba(255, 215, 64, .8);
}

.amber.accent-3
{
    color: #ffc400 !important;
}

.bg-amber.bg-accent-3
{
    background-color: #ffc400 !important;
}
.bg-amber.bg-accent-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 196, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 196, 0, .6);
}

.btn-amber.btn-accent-3
{
    border-color: #ffa000 !important;
    background-color: #ffc400 !important;
}
.btn-amber.btn-accent-3:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-3:focus,
.btn-amber.btn-accent-3:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-accent-3
{
    color: #ffc400 !important; 
    border-color: #ffc400 !important;
}
.btn-outline-amber.btn-outline-accent-3:hover
{
    background-color: #ffc400 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc400 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffc400 !important;
}

.border-amber.border-accent-3
{
    border: 1px solid #ffc400 !important;
}

.border-top-amber.border-top-accent-3
{
    border-top: 1px solid #ffc400 !important;
}

.border-bottom-amber.border-bottom-accent-3
{
    border-bottom: 1px solid #ffc400 !important;
}

.border-left-amber.border-left-accent-3
{
    border-left: 1px solid #ffc400 !important;
}

.border-right-amber.border-right-accent-3
{
    border-right: 1px solid #ffc400 !important;
}

.overlay-amber.overlay-accent-3
{
    background: #ffc400;
    /* The Fallback */
    background: rgba(255, 196, 0, .8);
}

.amber.accent-4
{
    color: #ffab00 !important;
}

.bg-amber.bg-accent-4
{
    background-color: #ffab00 !important;
}
.bg-amber.bg-accent-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 171, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 171, 0, .6);
}

.btn-amber.btn-accent-4
{
    border-color: #ffa000 !important;
    background-color: #ffab00 !important;
}
.btn-amber.btn-accent-4:hover
{
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-4:focus,
.btn-amber.btn-accent-4:active
{
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}

.btn-outline-amber.btn-outline-accent-4
{
    color: #ffab00 !important; 
    border-color: #ffab00 !important;
}
.btn-outline-amber.btn-outline-accent-4:hover
{
    background-color: #ffab00 !important;
}

input:focus ~ .bg-amber
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffab00 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ffab00 !important;
}

.border-amber.border-accent-4
{
    border: 1px solid #ffab00 !important;
}

.border-top-amber.border-top-accent-4
{
    border-top: 1px solid #ffab00 !important;
}

.border-bottom-amber.border-bottom-accent-4
{
    border-bottom: 1px solid #ffab00 !important;
}

.border-left-amber.border-left-accent-4
{
    border-left: 1px solid #ffab00 !important;
}

.border-right-amber.border-right-accent-4
{
    border-right: 1px solid #ffab00 !important;
}

.overlay-amber.overlay-accent-4
{
    background: #ffab00;
    /* The Fallback */
    background: rgba(255, 171, 0, .8);
}

.blue-grey.lighten-5
{
    color: #eceff1 !important;
}

.bg-blue-grey.bg-lighten-5
{
    background-color: #eceff1 !important;
}
.bg-blue-grey.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(236, 239, 241, .6);
            box-shadow: 0 1px 20px 1px rgba(236, 239, 241, .6);
}

.btn-blue-grey.btn-lighten-5
{
    border-color: #455a64 !important;
    background-color: #eceff1 !important;
}
.btn-blue-grey.btn-lighten-5:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-5:focus,
.btn-blue-grey.btn-lighten-5:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-5
{
    color: #eceff1 !important; 
    border-color: #eceff1 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-5:hover
{
    background-color: #eceff1 !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eceff1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eceff1 !important;
}

.border-blue-grey.border-lighten-5
{
    border: 1px solid #eceff1 !important;
}

.border-top-blue-grey.border-top-lighten-5
{
    border-top: 1px solid #eceff1 !important;
}

.border-bottom-blue-grey.border-bottom-lighten-5
{
    border-bottom: 1px solid #eceff1 !important;
}

.border-left-blue-grey.border-left-lighten-5
{
    border-left: 1px solid #eceff1 !important;
}

.border-right-blue-grey.border-right-lighten-5
{
    border-right: 1px solid #eceff1 !important;
}

.overlay-blue-grey.overlay-lighten-5
{
    background: #eceff1;
    /* The Fallback */
    background: rgba(236, 239, 241, .8);
}

.blue-grey.lighten-4
{
    color: #cfd8dc !important;
}

.bg-blue-grey.bg-lighten-4
{
    background-color: #cfd8dc !important;
}
.bg-blue-grey.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(207, 216, 220, .6);
            box-shadow: 0 1px 20px 1px rgba(207, 216, 220, .6);
}

.btn-blue-grey.btn-lighten-4
{
    border-color: #455a64 !important;
    background-color: #cfd8dc !important;
}
.btn-blue-grey.btn-lighten-4:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-4:focus,
.btn-blue-grey.btn-lighten-4:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-4
{
    color: #cfd8dc !important; 
    border-color: #cfd8dc !important;
}
.btn-outline-blue-grey.btn-outline-lighten-4:hover
{
    background-color: #cfd8dc !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cfd8dc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cfd8dc !important;
}

.border-blue-grey.border-lighten-4
{
    border: 1px solid #cfd8dc !important;
}

.border-top-blue-grey.border-top-lighten-4
{
    border-top: 1px solid #cfd8dc !important;
}

.border-bottom-blue-grey.border-bottom-lighten-4
{
    border-bottom: 1px solid #cfd8dc !important;
}

.border-left-blue-grey.border-left-lighten-4
{
    border-left: 1px solid #cfd8dc !important;
}

.border-right-blue-grey.border-right-lighten-4
{
    border-right: 1px solid #cfd8dc !important;
}

.overlay-blue-grey.overlay-lighten-4
{
    background: #cfd8dc;
    /* The Fallback */
    background: rgba(207, 216, 220, .8);
}

.blue-grey.lighten-3
{
    color: #b0bec5 !important;
}

.bg-blue-grey.bg-lighten-3
{
    background-color: #b0bec5 !important;
}
.bg-blue-grey.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(176, 190, 197, .6);
            box-shadow: 0 1px 20px 1px rgba(176, 190, 197, .6);
}

.btn-blue-grey.btn-lighten-3
{
    border-color: #455a64 !important;
    background-color: #b0bec5 !important;
}
.btn-blue-grey.btn-lighten-3:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-3:focus,
.btn-blue-grey.btn-lighten-3:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-3
{
    color: #b0bec5 !important; 
    border-color: #b0bec5 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-3:hover
{
    background-color: #b0bec5 !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b0bec5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b0bec5 !important;
}

.border-blue-grey.border-lighten-3
{
    border: 1px solid #b0bec5 !important;
}

.border-top-blue-grey.border-top-lighten-3
{
    border-top: 1px solid #b0bec5 !important;
}

.border-bottom-blue-grey.border-bottom-lighten-3
{
    border-bottom: 1px solid #b0bec5 !important;
}

.border-left-blue-grey.border-left-lighten-3
{
    border-left: 1px solid #b0bec5 !important;
}

.border-right-blue-grey.border-right-lighten-3
{
    border-right: 1px solid #b0bec5 !important;
}

.overlay-blue-grey.overlay-lighten-3
{
    background: #b0bec5;
    /* The Fallback */
    background: rgba(176, 190, 197, .8);
}

.blue-grey.lighten-2
{
    color: #90a4ae !important;
}

.bg-blue-grey.bg-lighten-2
{
    background-color: #90a4ae !important;
}
.bg-blue-grey.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(144, 164, 174, .6);
            box-shadow: 0 1px 20px 1px rgba(144, 164, 174, .6);
}

.btn-blue-grey.btn-lighten-2
{
    border-color: #455a64 !important;
    background-color: #90a4ae !important;
}
.btn-blue-grey.btn-lighten-2:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-2:focus,
.btn-blue-grey.btn-lighten-2:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-2
{
    color: #90a4ae !important; 
    border-color: #90a4ae !important;
}
.btn-outline-blue-grey.btn-outline-lighten-2:hover
{
    background-color: #90a4ae !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #90a4ae !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #90a4ae !important;
}

.border-blue-grey.border-lighten-2
{
    border: 1px solid #90a4ae !important;
}

.border-top-blue-grey.border-top-lighten-2
{
    border-top: 1px solid #90a4ae !important;
}

.border-bottom-blue-grey.border-bottom-lighten-2
{
    border-bottom: 1px solid #90a4ae !important;
}

.border-left-blue-grey.border-left-lighten-2
{
    border-left: 1px solid #90a4ae !important;
}

.border-right-blue-grey.border-right-lighten-2
{
    border-right: 1px solid #90a4ae !important;
}

.overlay-blue-grey.overlay-lighten-2
{
    background: #90a4ae;
    /* The Fallback */
    background: rgba(144, 164, 174, .8);
}

.blue-grey.lighten-1
{
    color: #78909c !important;
}

.bg-blue-grey.bg-lighten-1
{
    background-color: #78909c !important;
}
.bg-blue-grey.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(120, 144, 156, .6);
            box-shadow: 0 1px 20px 1px rgba(120, 144, 156, .6);
}

.btn-blue-grey.btn-lighten-1
{
    border-color: #455a64 !important;
    background-color: #78909c !important;
}
.btn-blue-grey.btn-lighten-1:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-1:focus,
.btn-blue-grey.btn-lighten-1:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-lighten-1
{
    color: #78909c !important; 
    border-color: #78909c !important;
}
.btn-outline-blue-grey.btn-outline-lighten-1:hover
{
    background-color: #78909c !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #78909c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #78909c !important;
}

.border-blue-grey.border-lighten-1
{
    border: 1px solid #78909c !important;
}

.border-top-blue-grey.border-top-lighten-1
{
    border-top: 1px solid #78909c !important;
}

.border-bottom-blue-grey.border-bottom-lighten-1
{
    border-bottom: 1px solid #78909c !important;
}

.border-left-blue-grey.border-left-lighten-1
{
    border-left: 1px solid #78909c !important;
}

.border-right-blue-grey.border-right-lighten-1
{
    border-right: 1px solid #78909c !important;
}

.overlay-blue-grey.overlay-lighten-1
{
    background: #78909c;
    /* The Fallback */
    background: rgba(120, 144, 156, .8);
}

.blue-grey
{
    color: #607d8b !important;
}

.bg-blue-grey
{
    background-color: #607d8b !important;
}
.bg-blue-grey .card-header,
.bg-blue-grey .card-footer
{
    background-color: transparent;
}
.bg-blue-grey.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
            box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
}

.selectboxit.selectboxit-btn.bg-blue-grey
{
    background-color: #607d8b !important;
}

.alert-blue-grey
{
    color: #171e21 !important; 
    border-color: #607d8b !important;
    background-color: #87a0ac !important;
}
.alert-blue-grey .alert-link
{
    color: #060809 !important;
}

.border-blue-grey
{
    border-color: #607d8b;
}

.overlay-blue-grey
{
    background: #607d8b;
    /* The Fallback */
    background: rgba(96, 125, 139, .8);
}

/* .card-outline-blue-grey{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-blue-grey
{
    color: #fff; 
    border-color: #455a64 !important;
    background-color: #607d8b !important;
}
.btn-blue-grey:hover
{
    color: #fff !important; 
    border-color: #546e7a !important;
    background-color: #78909c !important;
}
.btn-blue-grey:focus,
.btn-blue-grey:active
{
    color: #fff !important; 
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
            box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
}

.btn-outline-blue-grey
{
    color: #607d8b; 
    border-color: #607d8b;
    background-color: transparent;
}
.btn-outline-blue-grey:hover
{
    color: #fff !important; 
    background-color: #607d8b;
}
.btn-outline-blue-grey.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
            box-shadow: 0 1px 20px 1px rgba(96, 125, 139, .6);
}

input[type='checkbox'].bg-blue-grey + .custom-control-label:before,
input[type='radio'].bg-blue-grey + .custom-control-label:before
{
    background-color: #607d8b !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #607d8b !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #607d8b !important;
}

.border-blue-grey
{
    border: 1px solid #607d8b !important;
}

.border-top-blue-grey
{
    border-top: 1px solid #607d8b;
}

.border-bottom-blue-grey
{
    border-bottom: 1px solid #607d8b;
}

.border-left-blue-grey
{
    border-left: 1px solid #607d8b;
}

.border-right-blue-grey
{
    border-right: 1px solid #607d8b;
}

.blue-grey.darken-1
{
    color: #546e7a !important;
}

.bg-blue-grey.bg-darken-1
{
    background-color: #546e7a !important;
}
.bg-blue-grey.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(84, 110, 122, .6);
            box-shadow: 0 1px 20px 1px rgba(84, 110, 122, .6);
}

.btn-blue-grey.btn-darken-1
{
    border-color: #455a64 !important;
    background-color: #546e7a !important;
}
.btn-blue-grey.btn-darken-1:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-1:focus,
.btn-blue-grey.btn-darken-1:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-darken-1
{
    color: #546e7a !important; 
    border-color: #546e7a !important;
}
.btn-outline-blue-grey.btn-outline-darken-1:hover
{
    background-color: #546e7a !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #546e7a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #546e7a !important;
}

.border-blue-grey.border-darken-1
{
    border: 1px solid #546e7a !important;
}

.border-top-blue-grey.border-top-darken-1
{
    border-top: 1px solid #546e7a !important;
}

.border-bottom-blue-grey.border-bottom-darken-1
{
    border-bottom: 1px solid #546e7a !important;
}

.border-left-blue-grey.border-left-darken-1
{
    border-left: 1px solid #546e7a !important;
}

.border-right-blue-grey.border-right-darken-1
{
    border-right: 1px solid #546e7a !important;
}

.overlay-blue-grey.overlay-darken-1
{
    background: #546e7a;
    /* The Fallback */
    background: rgba(84, 110, 122, .8);
}

.blue-grey.darken-2
{
    color: #455a64 !important;
}

.bg-blue-grey.bg-darken-2
{
    background-color: #455a64 !important;
}
.bg-blue-grey.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(69, 90, 100, .6);
            box-shadow: 0 1px 20px 1px rgba(69, 90, 100, .6);
}

.btn-blue-grey.btn-darken-2
{
    border-color: #455a64 !important;
    background-color: #455a64 !important;
}
.btn-blue-grey.btn-darken-2:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-2:focus,
.btn-blue-grey.btn-darken-2:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-darken-2
{
    color: #455a64 !important; 
    border-color: #455a64 !important;
}
.btn-outline-blue-grey.btn-outline-darken-2:hover
{
    background-color: #455a64 !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #455a64 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #455a64 !important;
}

.border-blue-grey.border-darken-2
{
    border: 1px solid #455a64 !important;
}

.border-top-blue-grey.border-top-darken-2
{
    border-top: 1px solid #455a64 !important;
}

.border-bottom-blue-grey.border-bottom-darken-2
{
    border-bottom: 1px solid #455a64 !important;
}

.border-left-blue-grey.border-left-darken-2
{
    border-left: 1px solid #455a64 !important;
}

.border-right-blue-grey.border-right-darken-2
{
    border-right: 1px solid #455a64 !important;
}

.overlay-blue-grey.overlay-darken-2
{
    background: #455a64;
    /* The Fallback */
    background: rgba(69, 90, 100, .8);
}

.blue-grey.darken-3
{
    color: #37474f !important;
}

.bg-blue-grey.bg-darken-3
{
    background-color: #37474f !important;
}
.bg-blue-grey.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(55, 71, 79, .6);
            box-shadow: 0 1px 20px 1px rgba(55, 71, 79, .6);
}

.btn-blue-grey.btn-darken-3
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-3:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-3:focus,
.btn-blue-grey.btn-darken-3:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-darken-3
{
    color: #37474f !important; 
    border-color: #37474f !important;
}
.btn-outline-blue-grey.btn-outline-darken-3:hover
{
    background-color: #37474f !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37474f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37474f !important;
}

.border-blue-grey.border-darken-3
{
    border: 1px solid #37474f !important;
}

.border-top-blue-grey.border-top-darken-3
{
    border-top: 1px solid #37474f !important;
}

.border-bottom-blue-grey.border-bottom-darken-3
{
    border-bottom: 1px solid #37474f !important;
}

.border-left-blue-grey.border-left-darken-3
{
    border-left: 1px solid #37474f !important;
}

.border-right-blue-grey.border-right-darken-3
{
    border-right: 1px solid #37474f !important;
}

.overlay-blue-grey.overlay-darken-3
{
    background: #37474f;
    /* The Fallback */
    background: rgba(55, 71, 79, .8);
}

.blue-grey.darken-4
{
    color: #263238 !important;
}

.bg-blue-grey.bg-darken-4
{
    background-color: #263238 !important;
}
.bg-blue-grey.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(38, 50, 56, .6);
            box-shadow: 0 1px 20px 1px rgba(38, 50, 56, .6);
}

.btn-blue-grey.btn-darken-4
{
    border-color: #455a64 !important;
    background-color: #263238 !important;
}
.btn-blue-grey.btn-darken-4:hover
{
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-4:focus,
.btn-blue-grey.btn-darken-4:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-blue-grey.btn-outline-darken-4
{
    color: #263238 !important; 
    border-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-darken-4:hover
{
    background-color: #263238 !important;
}

input:focus ~ .bg-blue-grey
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #263238 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #263238 !important;
}

.border-blue-grey.border-darken-4
{
    border: 1px solid #263238 !important;
}

.border-top-blue-grey.border-top-darken-4
{
    border-top: 1px solid #263238 !important;
}

.border-bottom-blue-grey.border-bottom-darken-4
{
    border-bottom: 1px solid #263238 !important;
}

.border-left-blue-grey.border-left-darken-4
{
    border-left: 1px solid #263238 !important;
}

.border-right-blue-grey.border-right-darken-4
{
    border-right: 1px solid #263238 !important;
}

.overlay-blue-grey.overlay-darken-4
{
    background: #263238;
    /* The Fallback */
    background: rgba(38, 50, 56, .8);
}

.grey-blue.lighten-5
{
    color: #eceff1 !important;
}

.bg-grey-blue.bg-lighten-5
{
    background-color: #eceff1 !important;
}
.bg-grey-blue.bg-lighten-5.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(236, 239, 241, .6);
            box-shadow: 0 1px 20px 1px rgba(236, 239, 241, .6);
}

.btn-grey-blue.btn-lighten-5
{
    border-color: #404e67 !important;
    background-color: #eceff1 !important;
}
.btn-grey-blue.btn-lighten-5:hover
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-5:focus,
.btn-grey-blue.btn-lighten-5:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-5
{
    color: #eceff1 !important; 
    border-color: #eceff1 !important;
}
.btn-outline-grey-blue.btn-outline-lighten-5:hover
{
    background-color: #eceff1 !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eceff1 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #eceff1 !important;
}

.border-grey-blue.border-lighten-5
{
    border: 1px solid #eceff1 !important;
}

.border-top-grey-blue.border-top-lighten-5
{
    border-top: 1px solid #eceff1 !important;
}

.border-bottom-grey-blue.border-bottom-lighten-5
{
    border-bottom: 1px solid #eceff1 !important;
}

.border-left-grey-blue.border-left-lighten-5
{
    border-left: 1px solid #eceff1 !important;
}

.border-right-grey-blue.border-right-lighten-5
{
    border-right: 1px solid #eceff1 !important;
}

.overlay-grey-blue.overlay-lighten-5
{
    background: #eceff1;
    /* The Fallback */
    background: rgba(236, 239, 241, .8);
}

.grey-blue.lighten-4
{
    color: #cfd8dc !important;
}

.bg-grey-blue.bg-lighten-4
{
    background-color: #cfd8dc !important;
}
.bg-grey-blue.bg-lighten-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(207, 216, 220, .6);
            box-shadow: 0 1px 20px 1px rgba(207, 216, 220, .6);
}

.btn-grey-blue.btn-lighten-4
{
    border-color: #404e67 !important;
    background-color: #cfd8dc !important;
}
.btn-grey-blue.btn-lighten-4:hover
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-4:focus,
.btn-grey-blue.btn-lighten-4:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-4
{
    color: #cfd8dc !important; 
    border-color: #cfd8dc !important;
}
.btn-outline-grey-blue.btn-outline-lighten-4:hover
{
    background-color: #cfd8dc !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cfd8dc !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #cfd8dc !important;
}

.border-grey-blue.border-lighten-4
{
    border: 1px solid #cfd8dc !important;
}

.border-top-grey-blue.border-top-lighten-4
{
    border-top: 1px solid #cfd8dc !important;
}

.border-bottom-grey-blue.border-bottom-lighten-4
{
    border-bottom: 1px solid #cfd8dc !important;
}

.border-left-grey-blue.border-left-lighten-4
{
    border-left: 1px solid #cfd8dc !important;
}

.border-right-grey-blue.border-right-lighten-4
{
    border-right: 1px solid #cfd8dc !important;
}

.overlay-grey-blue.overlay-lighten-4
{
    background: #cfd8dc;
    /* The Fallback */
    background: rgba(207, 216, 220, .8);
}

.grey-blue.lighten-3
{
    color: #b0bec5 !important;
}

.bg-grey-blue.bg-lighten-3
{
    background-color: #b0bec5 !important;
}
.bg-grey-blue.bg-lighten-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(176, 190, 197, .6);
            box-shadow: 0 1px 20px 1px rgba(176, 190, 197, .6);
}

.btn-grey-blue.btn-lighten-3
{
    border-color: #404e67 !important;
    background-color: #b0bec5 !important;
}
.btn-grey-blue.btn-lighten-3:hover
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-3:focus,
.btn-grey-blue.btn-lighten-3:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-3
{
    color: #b0bec5 !important; 
    border-color: #b0bec5 !important;
}
.btn-outline-grey-blue.btn-outline-lighten-3:hover
{
    background-color: #b0bec5 !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b0bec5 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #b0bec5 !important;
}

.border-grey-blue.border-lighten-3
{
    border: 1px solid #b0bec5 !important;
}

.border-top-grey-blue.border-top-lighten-3
{
    border-top: 1px solid #b0bec5 !important;
}

.border-bottom-grey-blue.border-bottom-lighten-3
{
    border-bottom: 1px solid #b0bec5 !important;
}

.border-left-grey-blue.border-left-lighten-3
{
    border-left: 1px solid #b0bec5 !important;
}

.border-right-grey-blue.border-right-lighten-3
{
    border-right: 1px solid #b0bec5 !important;
}

.overlay-grey-blue.overlay-lighten-3
{
    background: #b0bec5;
    /* The Fallback */
    background: rgba(176, 190, 197, .8);
}

.grey-blue.lighten-2
{
    color: #6f85ad !important;
}

.bg-grey-blue.bg-lighten-2
{
    background-color: #6f85ad !important;
}
.bg-grey-blue.bg-lighten-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(111, 133, 173, .6);
            box-shadow: 0 1px 20px 1px rgba(111, 133, 173, .6);
}

.btn-grey-blue.btn-lighten-2
{
    border-color: #404e67 !important;
    background-color: #6f85ad !important;
}
.btn-grey-blue.btn-lighten-2:hover
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-2:focus,
.btn-grey-blue.btn-lighten-2:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-2
{
    color: #6f85ad !important; 
    border-color: #6f85ad !important;
}
.btn-outline-grey-blue.btn-outline-lighten-2:hover
{
    background-color: #6f85ad !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6f85ad !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #6f85ad !important;
}

.border-grey-blue.border-lighten-2
{
    border: 1px solid #6f85ad !important;
}

.border-top-grey-blue.border-top-lighten-2
{
    border-top: 1px solid #6f85ad !important;
}

.border-bottom-grey-blue.border-bottom-lighten-2
{
    border-bottom: 1px solid #6f85ad !important;
}

.border-left-grey-blue.border-left-lighten-2
{
    border-left: 1px solid #6f85ad !important;
}

.border-right-grey-blue.border-right-lighten-2
{
    border-right: 1px solid #6f85ad !important;
}

.overlay-grey-blue.overlay-lighten-2
{
    background: #6f85ad;
    /* The Fallback */
    background: rgba(111, 133, 173, .8);
}

.grey-blue.lighten-1
{
    color: #78909c !important;
}

.bg-grey-blue.bg-lighten-1
{
    background-color: #78909c !important;
}
.bg-grey-blue.bg-lighten-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(120, 144, 156, .6);
            box-shadow: 0 1px 20px 1px rgba(120, 144, 156, .6);
}

.btn-grey-blue.btn-lighten-1
{
    border-color: #404e67 !important;
    background-color: #78909c !important;
}
.btn-grey-blue.btn-lighten-1:hover
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-lighten-1:focus,
.btn-grey-blue.btn-lighten-1:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-lighten-1
{
    color: #78909c !important; 
    border-color: #78909c !important;
}
.btn-outline-grey-blue.btn-outline-lighten-1:hover
{
    background-color: #78909c !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #78909c !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #78909c !important;
}

.border-grey-blue.border-lighten-1
{
    border: 1px solid #78909c !important;
}

.border-top-grey-blue.border-top-lighten-1
{
    border-top: 1px solid #78909c !important;
}

.border-bottom-grey-blue.border-bottom-lighten-1
{
    border-bottom: 1px solid #78909c !important;
}

.border-left-grey-blue.border-left-lighten-1
{
    border-left: 1px solid #78909c !important;
}

.border-right-grey-blue.border-right-lighten-1
{
    border-right: 1px solid #78909c !important;
}

.overlay-grey-blue.overlay-lighten-1
{
    background: #78909c;
    /* The Fallback */
    background: rgba(120, 144, 156, .8);
}

.grey-blue
{
    color: #1b2942 !important;
}

.bg-grey-blue
{
    background-color: #1b2942 !important;
}
.bg-grey-blue .card-header,
.bg-grey-blue .card-footer
{
    background-color: transparent;
}
.bg-grey-blue.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
}

.selectboxit.selectboxit-btn.bg-grey-blue
{
    background-color: #1b2942 !important;
}

.alert-grey-blue
{
    color: black !important; 
    border-color: #1b2942 !important;
    background-color: #304875 !important;
}
.alert-grey-blue .alert-link
{
    color: black !important;
}

.border-grey-blue
{
    border-color: #1b2942;
}

.overlay-grey-blue
{
    background: #1b2942;
    /* The Fallback */
    background: rgba(27, 41, 66, .8);
}

/* .card-outline-grey-blue{
        border-width: 1px;
        border-style: solid;
        border-color: $color_value;
        background-color: transparent;
        .card-header, .card-footer{
          background-color: transparent;
        }
      } */
.btn-grey-blue
{
    color: #fff; 
    border-color: #404e67 !important;
    background-color: #1b2942 !important;
}
.btn-grey-blue:hover
{
    color: #fff !important; 
    border-color: #546e7a !important;
    background-color: #78909c !important;
}
.btn-grey-blue:focus,
.btn-grey-blue:active
{
    color: #fff !important; 
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
}

.btn-outline-grey-blue
{
    color: #1b2942; 
    border-color: #1b2942;
    background-color: transparent;
}
.btn-outline-grey-blue:hover
{
    color: #fff !important; 
    background-color: #1b2942;
}
.btn-outline-grey-blue.btn-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
            box-shadow: 0 1px 20px 1px rgba(27, 41, 66, .6);
}

input[type='checkbox'].bg-grey-blue + .custom-control-label:before,
input[type='radio'].bg-grey-blue + .custom-control-label:before
{
    background-color: #1b2942 !important;
}

/* .custom-control-label{
        &:before{
          background-color: #ddd !important;
        }
      } */
input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1b2942 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #1b2942 !important;
}

.border-grey-blue
{
    border: 1px solid #1b2942 !important;
}

.border-top-grey-blue
{
    border-top: 1px solid #1b2942;
}

.border-bottom-grey-blue
{
    border-bottom: 1px solid #1b2942;
}

.border-left-grey-blue
{
    border-left: 1px solid #1b2942;
}

.border-right-grey-blue
{
    border-right: 1px solid #1b2942;
}

.grey-blue.darken-1
{
    color: #546e7a !important;
}

.bg-grey-blue.bg-darken-1
{
    background-color: #546e7a !important;
}
.bg-grey-blue.bg-darken-1.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(84, 110, 122, .6);
            box-shadow: 0 1px 20px 1px rgba(84, 110, 122, .6);
}

.btn-grey-blue.btn-darken-1
{
    border-color: #404e67 !important;
    background-color: #546e7a !important;
}
.btn-grey-blue.btn-darken-1:hover
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-1:focus,
.btn-grey-blue.btn-darken-1:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-darken-1
{
    color: #546e7a !important; 
    border-color: #546e7a !important;
}
.btn-outline-grey-blue.btn-outline-darken-1:hover
{
    background-color: #546e7a !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #546e7a !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #546e7a !important;
}

.border-grey-blue.border-darken-1
{
    border: 1px solid #546e7a !important;
}

.border-top-grey-blue.border-top-darken-1
{
    border-top: 1px solid #546e7a !important;
}

.border-bottom-grey-blue.border-bottom-darken-1
{
    border-bottom: 1px solid #546e7a !important;
}

.border-left-grey-blue.border-left-darken-1
{
    border-left: 1px solid #546e7a !important;
}

.border-right-grey-blue.border-right-darken-1
{
    border-right: 1px solid #546e7a !important;
}

.overlay-grey-blue.overlay-darken-1
{
    background: #546e7a;
    /* The Fallback */
    background: rgba(84, 110, 122, .8);
}

.grey-blue.darken-2
{
    color: #404e67 !important;
}

.bg-grey-blue.bg-darken-2
{
    background-color: #404e67 !important;
}
.bg-grey-blue.bg-darken-2.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(64, 78, 103, .6);
            box-shadow: 0 1px 20px 1px rgba(64, 78, 103, .6);
}

.btn-grey-blue.btn-darken-2
{
    border-color: #404e67 !important;
    background-color: #404e67 !important;
}
.btn-grey-blue.btn-darken-2:hover
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-2:focus,
.btn-grey-blue.btn-darken-2:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-darken-2
{
    color: #404e67 !important; 
    border-color: #404e67 !important;
}
.btn-outline-grey-blue.btn-outline-darken-2:hover
{
    background-color: #404e67 !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #404e67 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #404e67 !important;
}

.border-grey-blue.border-darken-2
{
    border: 1px solid #404e67 !important;
}

.border-top-grey-blue.border-top-darken-2
{
    border-top: 1px solid #404e67 !important;
}

.border-bottom-grey-blue.border-bottom-darken-2
{
    border-bottom: 1px solid #404e67 !important;
}

.border-left-grey-blue.border-left-darken-2
{
    border-left: 1px solid #404e67 !important;
}

.border-right-grey-blue.border-right-darken-2
{
    border-right: 1px solid #404e67 !important;
}

.overlay-grey-blue.overlay-darken-2
{
    background: #404e67;
    /* The Fallback */
    background: rgba(64, 78, 103, .8);
}

.grey-blue.darken-3
{
    color: #37474f !important;
}

.bg-grey-blue.bg-darken-3
{
    background-color: #37474f !important;
}
.bg-grey-blue.bg-darken-3.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(55, 71, 79, .6);
            box-shadow: 0 1px 20px 1px rgba(55, 71, 79, .6);
}

.btn-grey-blue.btn-darken-3
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-3:hover
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-3:focus,
.btn-grey-blue.btn-darken-3:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-darken-3
{
    color: #37474f !important; 
    border-color: #37474f !important;
}
.btn-outline-grey-blue.btn-outline-darken-3:hover
{
    background-color: #37474f !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37474f !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #37474f !important;
}

.border-grey-blue.border-darken-3
{
    border: 1px solid #37474f !important;
}

.border-top-grey-blue.border-top-darken-3
{
    border-top: 1px solid #37474f !important;
}

.border-bottom-grey-blue.border-bottom-darken-3
{
    border-bottom: 1px solid #37474f !important;
}

.border-left-grey-blue.border-left-darken-3
{
    border-left: 1px solid #37474f !important;
}

.border-right-grey-blue.border-right-darken-3
{
    border-right: 1px solid #37474f !important;
}

.overlay-grey-blue.overlay-darken-3
{
    background: #37474f;
    /* The Fallback */
    background: rgba(55, 71, 79, .8);
}

.grey-blue.darken-4
{
    color: #263238 !important;
}

.bg-grey-blue.bg-darken-4
{
    background-color: #263238 !important;
}
.bg-grey-blue.bg-darken-4.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(38, 50, 56, .6);
            box-shadow: 0 1px 20px 1px rgba(38, 50, 56, .6);
}

.btn-grey-blue.btn-darken-4
{
    border-color: #404e67 !important;
    background-color: #263238 !important;
}
.btn-grey-blue.btn-darken-4:hover
{
    border-color: #404e67 !important;
    background-color: #37474f !important;
}
.btn-grey-blue.btn-darken-4:focus,
.btn-grey-blue.btn-darken-4:active
{
    border-color: #37474f !important;
    background-color: #263238 !important;
}

.btn-outline-grey-blue.btn-outline-darken-4
{
    color: #263238 !important; 
    border-color: #263238 !important;
}
.btn-outline-grey-blue.btn-outline-darken-4:hover
{
    background-color: #263238 !important;
}

input:focus ~ .bg-grey-blue
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #263238 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #263238 !important;
}

.border-grey-blue.border-darken-4
{
    border: 1px solid #263238 !important;
}

.border-top-grey-blue.border-top-darken-4
{
    border-top: 1px solid #263238 !important;
}

.border-bottom-grey-blue.border-bottom-darken-4
{
    border-bottom: 1px solid #263238 !important;
}

.border-left-grey-blue.border-left-darken-4
{
    border-left: 1px solid #263238 !important;
}

.border-right-grey-blue.border-right-darken-4
{
    border-right: 1px solid #263238 !important;
}

.overlay-grey-blue.overlay-darken-4
{
    background: #263238;
    /* The Fallback */
    background: rgba(38, 50, 56, .8);
}

.shades.black
{
    color: #000 !important;
}

.bg-shades.bg-black
{
    background-color: #000 !important;
}
.bg-shades.bg-black.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

.btn-shades.btn-black
{
    border-color: !important;
    background-color: #000 !important;
}
.btn-shades.btn-black:hover
{
    border-color: !important;
    background-color: !important;
}
.btn-shades.btn-black:focus,
.btn-shades.btn-black:active
{
    border-color: !important;
    background-color: !important;
}

.btn-outline-shades.btn-outline-black
{
    color: #000 !important; 
    border-color: #000 !important;
}
.btn-outline-shades.btn-outline-black:hover
{
    background-color: #000 !important;
}

input:focus ~ .bg-shades
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000 !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000 !important;
}

.border-shades.border-black
{
    border: 1px solid #000 !important;
}

.border-top-shades.border-top-black
{
    border-top: 1px solid #000 !important;
}

.border-bottom-shades.border-bottom-black
{
    border-bottom: 1px solid #000 !important;
}

.border-left-shades.border-left-black
{
    border-left: 1px solid #000 !important;
}

.border-right-shades.border-right-black
{
    border-right: 1px solid #000 !important;
}

.overlay-shades.overlay-black
{
    background: #000;
    /* The Fallback */
    background: rgba(0, 0, 0, .8);
}

.shades.white
{
    color: #fff !important;
}

.bg-shades.bg-white
{
    background-color: #fff !important;
}
.bg-shades.bg-white.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

.btn-shades.btn-white
{
    border-color: !important;
    background-color: #fff !important;
}
.btn-shades.btn-white:hover
{
    border-color: !important;
    background-color: !important;
}
.btn-shades.btn-white:focus,
.btn-shades.btn-white:active
{
    border-color: !important;
    background-color: !important;
}

.btn-outline-shades.btn-outline-white
{
    color: #fff !important; 
    border-color: #fff !important;
}
.btn-outline-shades.btn-outline-white:hover
{
    background-color: #fff !important;
}

input:focus ~ .bg-shades
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff !important;
}

.border-shades.border-white
{
    border: 1px solid #fff !important;
}

.border-top-shades.border-top-white
{
    border-top: 1px solid #fff !important;
}

.border-bottom-shades.border-bottom-white
{
    border-bottom: 1px solid #fff !important;
}

.border-left-shades.border-left-white
{
    border-left: 1px solid #fff !important;
}

.border-right-shades.border-right-white
{
    border-right: 1px solid #fff !important;
}

.overlay-shades.overlay-white
{
    background: #fff;
    /* The Fallback */
    background: rgba(255, 255, 255, .8);
}

.shades.transparent
{
    color: transparent !important;
}

.bg-shades.bg-transparent
{
    background-color: transparent !important;
}
.bg-shades.bg-transparent.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

.btn-shades.btn-transparent
{
    border-color: !important;
    background-color: transparent !important;
}
.btn-shades.btn-transparent:hover
{
    border-color: !important;
    background-color: !important;
}
.btn-shades.btn-transparent:focus,
.btn-shades.btn-transparent:active
{
    border-color: !important;
    background-color: !important;
}

.btn-outline-shades.btn-outline-transparent
{
    color: transparent !important; 
    border-color: transparent !important;
}
.btn-outline-shades.btn-outline-transparent:hover
{
    background-color: transparent !important;
}

input:focus ~ .bg-shades
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem transparent !important;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem transparent !important;
}

.border-shades.border-transparent
{
    border: 1px solid transparent !important;
}

.border-top-shades.border-top-transparent
{
    border-top: 1px solid transparent !important;
}

.border-bottom-shades.border-bottom-transparent
{
    border-bottom: 1px solid transparent !important;
}

.border-left-shades.border-left-transparent
{
    border-left: 1px solid transparent !important;
}

.border-right-shades.border-right-transparent
{
    border-right: 1px solid transparent !important;
}

.overlay-shades.overlay-transparent
{
    background: transparent;
    /* The Fallback */
    background: rgba(0, 0, 0, .8);
}

.black
{
    color: #000;
}

.bg-black
{
    background-color: #000;
}
.bg-black.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

input:focus ~ .bg-black
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000;
}

.border-black
{
    border: 1px solid #000;
}

.border-top-black
{
    border-top: 1px solid #000;
}

.border-bottom-black
{
    border-bottom: 1px solid #000;
}

.border-left-black
{
    border-left: 1px solid #000;
}

.border-right-black
{
    border-right: 1px solid #000;
}

.white
{
    color: #fff;
}

.bg-white
{
    background-color: #fff;
}
.bg-white.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
            box-shadow: 0 1px 20px 1px rgba(255, 255, 255, .6);
}

input:focus ~ .bg-white
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff;
}

.border-white
{
    border: 1px solid #fff;
}

.border-top-white
{
    border-top: 1px solid #fff;
}

.border-bottom-white
{
    border-bottom: 1px solid #fff;
}

.border-left-white
{
    border-left: 1px solid #fff;
}

.border-right-white
{
    border-right: 1px solid #fff;
}

.transparent
{
    color: transparent;
}

.bg-transparent
{
    background-color: transparent;
}
.bg-transparent.bg-glow
{
    -webkit-box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
            box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .6);
}

input:focus ~ .bg-transparent
{
    -webkit-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem transparent;
            box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem transparent;
}

.border-transparent
{
    border: 1px solid transparent;
}

.border-top-transparent
{
    border-top: 1px solid transparent;
}

.border-bottom-transparent
{
    border-bottom: 1px solid transparent;
}

.border-left-transparent
{
    border-left: 1px solid transparent;
}

.border-right-transparent
{
    border-right: 1px solid transparent;
}
