.table-scroll {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: auto;
    height: 60vH;
}

.table-scroll table {
    width: 100%;
    min-width: 1280px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
}

.table-wrap {
    position: relative;
}

.table-scroll th,
.table-scroll td {
    padding: 5px 10px;
    background: #fff;
    vertical-align: top;
}

.table-scroll thead th {
    background: #f2f4f7;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

/* safari and ios need the tfoot itself to be position:sticky also */
.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #666;
    color: #fff;
    z-index: 4;
}